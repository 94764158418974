import { store } from "../../plugins/index";

class DesignerUserUtils {
    constructor(store) {
        this.store = store;
    }

    fill(designerUser) {
        this.designerUser = designerUser;
    }

    fetch() {
        return this.designerUser;
    }
    
    fillDesignerId(id) {
        this.designer_id = id;
    }

    fetchDesignerId() {
        return this.designer_id;
    }
}

const designerUserUtils = new DesignerUserUtils(store);

export { designerUserUtils };