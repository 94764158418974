import { store } from "../../plugins/index";

class CodingSubmissionUtils {
    constructor(store) {
        this.store = store;
    }

    fill(submission) {
        this.submission = submission;
    }

    fetch() {
        return this.submission;
    }
    
    fillId(id) {
        this.id = id;
    }

    fetchId() {
        return this.id;
    }
}

const codingSubmissionUtils = new CodingSubmissionUtils(store);

export { codingSubmissionUtils };