const userRoleEnum = Object.freeze({
    NONE: 0,
    PERSONAL: 1,
    BUSINESS: 2,
    DESIGNER: 3,
    ADMIN: 4,
});
const convertIntoToUserRole = function (i) {
    for (var p in userRoleEnum) {
        if (p === i || userRoleEnum[p] === i) {
            return p;
        }
    }
    return userRoleEnum.NONE
}
export { userRoleEnum, convertIntoToUserRole }