import { createApp } from 'vue'
import App from './App.vue'
import i18n from './i18n'
import 'bootstrap'
import directive from './utils/directive/directive'
// import '@/assets/styles/main.scss'; //main.scss is loaded in vue.config.js
import { store, router } from './plugins/index'
import toast from './plugins/toast'
import { navigatorMixin, userIdentityMixin, errorHanlderMixin, userAuthMixin } from './mixins/index'
import CustomPagination from '@/components/common/CustomPagination.vue'
const app = createApp(App)
app.component('CustomPagination',CustomPagination)
app.use(store)
app.use(router)
app.use(i18n)
app.use(directive)
app.use(toast)
app.mixin(userIdentityMixin)
app.mixin(navigatorMixin)
app.mixin(errorHanlderMixin)
app.mixin(userAuthMixin)
app.mount('#app')
app.config.warnHandler = (msg, instance, trace) => {
    // `trace` is the component hierarchy trace
    console.log('warn:', msg, instance, trace)
}
