import { TextValidator } from './textValidator'
const formatMessage = "less then 4096 characters";
const pattern = /^[ -~|\n]{8,4098}$/;
const minLength = 8;
const maxLength = 4098;

class ProblemScoringValidator extends TextValidator {
    constructor() {
        super(
            "Scoring",
            formatMessage,
            [
                {
                    regex: pattern,
                    message: " contains invalid charactors."
                }
            ],
            minLength,
            maxLength
        );
    }

}

export { ProblemScoringValidator }
