<template>
    <div>
        <transition name="modal-fade">
            <div class="modal-container" v-show="visible">
                <slot></slot>
            </div>
        </transition>
        <div class="mask" v-show="visible" @click="close"></div>
    </div>
</template>
<script>
export default {
    name: 'CustomModal',
    props: {
        visible: {
            type: Boolean,
            default: () => false
        }
    },
    methods: {
        close() {
            this.$emit('update:visible', false)
        }
    },
    // watch: {
    //     visible() {
    //         console.log('visible:', this.visible)
    //         if (this.visible == true) {
    //             document.querySelector('body').setAttribute('style', 'overflow:hidden !important;')
    //         } else {
    //             document.querySelector('body').removeAttribute('style')
    //         }
    //     }
    // }
}
</script>
<style lang="scss" scoped>
.modal-container {z-index: 999; background-color: #F6F6F6; min-width: 600px; min-height: 286px; display: flex; flex-direction: column; justify-content: space-between; border: none; border-radius: 10px; transition: 0.5s; position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%)}
.mask {z-index: 998; position: fixed; top: 0; right: 0; bottom: 0; left: 0; width: 100%; height: 100%; background-color: rgba(5, 5, 5, 0.8); backdrop-filter: blur(4px)}
.modal-fade-enter-active {transition: all 0.3s ease;}
.modal-fade-leave-active {transition: all 0.3s ease;}
.modal-fade-enter-from,.modal-fade-leave-to {transform: translateY(-400px); opacity: 0;}
</style>