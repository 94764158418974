<template>
  <div class="container-background">
      <div class="container-content">
          <div class="success-container">
              <img src="@/assets/success.png" alt="">
              <p class="success-title">Your interview question</p>
              <p class="success-subtitle">has been submitted</p>
              <button class="success-back" @click="backToHome">Back</button>
          </div>
      </div>
  </div>
</template>

<script>
export default {
  name: "DesignerProblemSubmitted",
  props: {
  },

  data() {
    return {};
  },

  components: {},

  created() {},
  mounted() {},
  methods: {
    backToHome() {
      this.mnx_navToDesignerHomePage();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.container-background {
  @include container-background;

  .container-content {
    @include container-content;
    padding: 120px 0;
  }
}
</style>
