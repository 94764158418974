<template>
    <div class="container-div">
        <div class="top-div">
            <div class="top-div-block">
                <p class="top-text-clickable" @click="gotoAboutMagicleaps()">About Magicleaps</p>
            </div>
            <div class="top-div-block">
                <p class="top-text-clickable" @click="gotoAboutFreeleaps()">About Freeleaps</p>
            </div>
            <div class="top-div-block">
                <p class="top-text-clickable" @click="gotoContactUs()">Contact</p>
            </div>
        </div>
        <div class="bottom-div">
            <p class="bottom-text">2022 Freeleaps, LLC, All rights reserved</p>
        </div>
    </div>
</template>
<script>
export default {
    name: "FooterAdmin",
    components: {},
    computed: {},
    methods: {
        gotoContactUs(){
            this.mnx_navToContactUs();
        },
        gotoAboutFreeleaps(){
            this.mnx_navToAboutFreeleapsCompany();
        },
        gotoAboutMagicleaps(){
            this.mnx_navToAboutMagicleapsPlatform();
        }
    },
};
</script>

<style scoped lang="scss">
.container-div {
    width: 100%;
    height: 100%;
}

.top-div {
    width: 100%;
    height: 70%;
    @extend .d-flex;
    @extend .justify-content-center;
}

.top-div-block {
    width: 30%;
}

.bottom-div {
    width: 100%;
    height: 30%;
}

.top-text {
    color: $callout;
}

.top-text-clickable {
    @extend .top-text;
    @extend .text-decoration-underline;
    cursor: pointer;
}

.bottom-text {
    color: $callout;
    @extend .fs-6;
}
</style>