<template>
  <div class="container-background">
      <div class="container-content">
        <div class="content-header-container">
          <p class="content-header-title">Problems</p>
          <div class="flex-space"></div>
          <div class="content-search">
            <input type="text" placeholder="search..." v-model="pagination.keyword">
            <img src="@/assets/search.png" alt="search" class="search-icon" @click="searchAction">
          </div>
          <button class="content-btn" @click="createQuestion">Add Problem</button>
        </div>
        <div class="list-container">
          <template v-if="problemList.length > 0">
            <div class="content-item-container content-item-flex" v-for="(problem, index) in problemList" :key="index">
              <p class="item-title">{{ problem.title }}&nbsp;&nbsp;{{ problem.desiredProgramingLanguage }}</p>
              <p class="item-content">{{ getDifficultyName(problem.difficultyLevel) }}</p>
              <p class="item-content">{{ getUpdateTime(problem) }}</p>
              <div class="item-operation-container">
                <span @click="editQuestion(problem)">Edit</span>
              </div>
            </div>
          </template>
          <div class="empty-container" v-if="problemList.length == 0">
            <img src="@/assets/logo-pic.png" alt="magic leaps logo">
            <p class="opps">Oops !</p>
            <p class="opps-sub">There is nothing here.</p>
          </div>
          <div class="spinner-container" v-if="loading">
            <div class="spinner-grow text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
        <CustomPagination :total="pagination.total" v-model:current="pagination.current" @pageChange="pageChange" />
      </div>
  </div>
</template>

<script>
import {DesignerApi, interviewProblemUtils, OIDUtils, DateUtils} from "../../utils/index";
export default {
  name: "DesignerHomePage",
  props: {},
  data() {
    return {
      problemList: [],
      pagination: {
        total: 0,
        current: 1,
        keyword: ''
      },
      loading: false
    };
  },
  components: {},
  created() {},
  mounted() {
    this.queryProblems();
  },
  methods: {
    getDifficultyName(difficulty){
      switch(difficulty){
        case 0: return "easy";
        case 1: return "medium";
        case 2: return "hard";
        case 3: return "champion";
      }
    },
    getUpdateTime(problem){
      if (problem.updateTime){
        return DateUtils.FromJsonToDateString(problem.updateTime);
      }
      if (problem.createTime){
        return DateUtils.FromJsonToDateString(problem.createTime);
      }

    },
    pageChange() {},
    createQuestion() {
      interviewProblemUtils.fillId(null);
      this.mnx_navToDesignerEditProblem();
    },
    editQuestion(problem) {
      interviewProblemUtils.fill(problem);
      interviewProblemUtils.fillId(OIDUtils.FromJson(problem));
      this.mnx_navToDesignerEditProblem();
    },
    queryProblems() {
      this.loading = true;
      DesignerApi.queryProblems(this.pagination.keyword).then((response) => {
        this.problemList = response.data;
        this.loading = false;
      }).catch((error) => {
        this.mnx_backendErrorHandler(error);
        this.loading = false;
      });
    },
    searchAction() {
      this.pagination.total = 0
      this.pagination.current = 1
      this.queryProblems()
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.container-background {
  @include container-background;

  .container-content {
    @include container-content;
    padding: 45px 0;
  }
}
.content-item-flex {display: flex; align-items: center;
  .item-title {flex: 1;}
  .item-content {margin: 0 6px;}
  .item-operation-container {color: #1946FC;}
}
</style>
