<template>
  <div class="story-board">
    <div class="focus-area">
      <p class="callout">
        Find a trustworthy partner, simplify the work, secure your return.
      </p>
      <div class="form-group">
        <form @submit.prevent="signinWithCode">
          <div class="input-group">
            <input
              class="form-control w-70 me-1"
              type="text"
              :placeholder="'Code sent to your email'"
              v-model="code"
            />
            <button type="submit" class="form-control btn btn-primary ms-1">
              Start
            </button>
          </div>
        </form>
        <p class="errorInput" v-if="message != null">{{ message }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { AdminApi, userProfileValidator } from "../../utils/index";
export default {
  name: "AdminSigninWithEmailAndCode",
  props: {
    email: {
      required: true,
      type: String,
    },
  },

  data() {
    return {
      code: null,
      message: null,
    };
  },

  components: {},

  created() {},
  mounted() {
    if (this.mnx_IsKeepUserSignedIn() && this.mnx_isUserAuthenticated()) {
      this.mnx_navAfterSignedin();
    }
  },
  methods: {
    signinWithCode() {
      this.message = null;
      if (this.email === null || this.email.length < 1) {
        this.message = "Please type in the code sent to your email";
        return;
      }

      this.message = userProfileValidator.authCodeValidator.validate(this.code);

      if (this.message != null) return;

      AdminApi.signinWithEmailAndCode(this.email, this.code)
        .then((response) => {
          this.mnx_userSignedin(response.data, this.keepSignedin);
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status == 401) this.message = "invalid code";
            if (error.response.status == 403) this.message = "invalid email";
            console.log(error);
          }
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
