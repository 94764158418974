<template>
  <div class="row-flow-container">
    <div class="row-flow-header">
      <p class="callout">Admin Home</p>
      <button class="btn btn-link" @click="gotoAdmin">
        Admin Manage
      </button>
      <button class="btn -link" @click="gotoBusiness">
        Business Manage
      </button>
      <button class="btn btn-link" @click="gotoPersonal">
        Personal Manage
      </button>
      <button class="btn btn-link" @click="gotoEveluation">
        Eveluation Manage
      </button>
      <button class="btn btn-link" @click="gotoDesigner">
        Designer Manage
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminHomePage",
  props: {},

  data() {
    return {};
  },

  components: {},

  created() {},
  mounted() {},
  methods: {
    gotoAdmin() {
      this.mnx_navToAdminManage();
    },
    gotoBusiness() {
      this.mnx_navToBusinessManage();
    },
    gotoPersonal() {
      this.mnx_navToPersonalManage();
    },
    gotoDesigner() {
      this.mnx_navToDesignerManage();
    },
    gotoEveluation() {
      this.mnx_navToEveluationManage();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
