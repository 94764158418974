<template>
  <div class="container-background">
    <div class="container-content">
      <FullCalendar :options='calendarOptions' ref="fullCalendar">
        <template v-slot:eventContent='arg'>
          <span>{{ arg.event.title }}</span>
        </template>
      </FullCalendar>
      <div class="legend-container">
        <div class="legend-item">
          <div class="legend-icon" style="background-color: #1946FC" /><span>created</span>
        </div>
        <div class="legend-item">
          <div class="legend-icon" style="background-color: #13B46D" /><span>In progress</span>
        </div>
        <div class="legend-item">
          <div class="legend-icon" style="background-color: #4E96FF" /><span>Completed</span>
        </div>
        <div class="legend-item">
          <div class="legend-icon" style="background-color: #7A858F" /><span>Cancelled</span>
        </div>
      </div>
    </div>
    <div v-show="!!poperInterview" id="full-popover" ref="full-popover" class="full-popover" v-click-outside="popperDestroy">
      <p class="fp-title">{{poperInterview?.applicantFullname}}</p>
      <p class="fp-subtitle">{{poperInterview?.jobTitle}}</p>
      <p class="fp-time">{{poperInterview?.due}}</p>
      <div>
        <button v-if="poperInterview?.status == 1" class="fp-issue" @click="editInterview">Modify</button>
        <button v-if="poperInterview?.status == 1" class="fp-cancel" @click="cancelInterview">Cancel</button>
        <button v-if="poperInterview?.status == 3" class="fp-report" @click="viewReport(poperInterview)">View Report</button>
      </div>
    </div>
  </div>
</template>

<script>
import {BusinessUserApi, codingInterviewUtils, OIDUtils} from "../../utils/index";
import { createPopper } from '@popperjs/core';
// import { interviewStatusStatusEnum } from "../../types/index";
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import moment from 'moment';

export default {
  name: "BusinessCodingInterviewHome",
  props: {},

  data() {
    return {
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
        ],
        headerToolbar: {
          left: 'dayGridMonth,timeGridDay',
          center: '',
          right: 'prev,next title'
        },
        timeZone: 'none',
        initialView: 'dayGridMonth',
        events: [],
        editable: false,
        selectable: false,
        selectMirror: false,
        dayMaxEvents: true,
        weekends: true,
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
        eventDidMount: this.eventDidMount
      },
      interviews: [],
      currentEvents: [],
      poperInterview: null,
      popperEl: null,
      popper: null
    };
  },

  components: {FullCalendar},

  created() {},
  mounted() {
    this.popperEl = this.$refs['full-popover']
    this.queryInterview();

    // hook FullCalendar
    const fc_toolbar_title = document.body.getElementsByClassName('fc-toolbar-title')?.[0]
    const fc_header_title = document.createElement('span')
    const fc_toolbar_mouth = document.createElement('span')
    const fc_toolbar_year = document.createElement('span')
    fc_header_title.innerText = 'Scheduled interviews'
    fc_header_title.setAttribute('style', 'font-size: 28px; color: #050505; font-weight: 700; line-height: 1; margin-right: 25px')
    fc_toolbar_mouth.setAttribute('style', 'font-size: 28px; color: #050505; font-weight: 700; line-height: 1')
    fc_toolbar_year.setAttribute('style', 'font-size: 28px; color: #050505; font-weight: 700; line-height: 1; vertical-align: middle')
    const timeChange = (time) => {
      let times = ['', '']
      if (time.indexOf(',') === -1) {
        times = time.split(' ')
      } else {
        times = time.split(',')
      }
      fc_toolbar_mouth.innerText = times[0]
      fc_toolbar_year.innerText = times[1]
    }
    document.body.getElementsByClassName('fc-header-toolbar')[0].insertBefore(fc_header_title, document.body.getElementsByClassName('fc-toolbar-chunk')[0])
    document.body.getElementsByClassName('fc-button-group')[1].insertBefore(fc_toolbar_mouth, document.body.getElementsByClassName('fc-next-button')[0])
    document.body.getElementsByClassName('fc-button-group')[1].parentNode.insertBefore(fc_toolbar_year, fc_toolbar_title)
    document.body.getElementsByClassName('fc-toolbar-chunk')[1].style.flex = 1
    fc_toolbar_title?.addEventListener('DOMSubtreeModified', e => {
      // console.log('fc-toolbar-title', e.target.data)
      timeChange(e.target.data)
    });
    fc_toolbar_title.style.display = 'none'
    timeChange(fc_toolbar_title.innerText)
  },
  methods: {
    createInterview() {
      this.mnx_navToBusinessEditCodingInterview();
    },
    findInterviewIndexBy(id) {
      let index = -1
      for (let i = 0; i < this.interviews.length; i ++) {
        if (this.interviews[i]._id.$oid == id) {
          index = i
          return index
        }
      }
      return index
    },
    editInterview() {
      if (!this.poperInterview) return
      codingInterviewUtils.fill(this.poperInterview);
      codingInterviewUtils.fillId(OIDUtils.FromJson(this.poperInterview));
      this.mnx_navToBusinessEditCodingInterview();
    },
    cancelInterview() {
      if (!this.poperInterview) return
      BusinessUserApi.CancelCodingInterview(OIDUtils.FromJson(this.poperInterview))
        .then((response) => {
          response;
          this.queryInterview();
        })
        .catch((error) => {
          this.mnx_backendErrorHandler(error);
        });
    },
    viewReport(interivew) {
      codingInterviewUtils.fill(interivew);
      codingInterviewUtils.fillId(OIDUtils.FromJson(interivew));
      this.mnx_navToBusinessCodingInterviewReport(OIDUtils.FromJson(interivew));
    },
    // 1: #1946FC
    // 2: #13B46D
    // 3: #4E96FF
    // 4: #7A858F
    queryInterview() {
      BusinessUserApi.QueryCodingInterviews([1,2,3,4]).then((response) => {
        this.interviews = response.data || []
        this.calendarOptions.events = (response.data || []).map(interview => {
          let endData = interview.deadline.$date
          console.log('moment(endData).hour():', moment(endData).utc().hours())
          if (moment(endData).utc().hours() == 23) {
            endData = moment(endData).utc().format('yyyy-MM-DDT23:59:59Z')
          }
          return {
            id: interview._id.$oid,
            title: [interview.desiredProgramingLanguage, interview.jobTitle, interview.applicantFullname].join(' '),
            start: interview.deadline.$date,
            end: endData,
            status: interview.status,
            color: this.getStatusColor(interview.status)
          }
        });
        console.log('QueryCodingInterviews', this.calendarOptions.events)
      }).catch((error) => {
        this.mnx_backendErrorHandler(error);
      });
    },
    getStatusColor(status) {
      const map = {
        1: '#1946FC',
        2: '#13B46D',
        3: '#4E96FF',
        4: '#7A858F',
      }
      return map[status] || '#7A858F'
    },
    handleWeekendsToggle() {
      console.log('handleWeekendsToggle')
      this.calendarOptions.weekends = !this.calendarOptions.weekends // update a property
    },
    handleDateSelect(selectInfo) {
      // let title = prompt('Please enter a new title for your event')
      let calendarApi = selectInfo.view.calendar

      calendarApi.unselect() // clear date selection

      // if (title) {
      //   calendarApi.addEvent({
      //     id: createEventId(),
      //     title,
      //     start: selectInfo.startStr,
      //     end: selectInfo.endStr,
      //     allDay: selectInfo.allDay
      //   })
      // }
    },
    handleEventClick(e) {
      console.log('handleEventClick', e)
      e.jsEvent.preventDefault()
      e.jsEvent.stopPropagation()
      this.popperDestroy()
      const index = this.findInterviewIndexBy(e.event._def.publicId)
      this.poperInterview = this.interviews[index]
      const date = this.poperInterview.deadline.$date
      const date1 = moment(date).utc().format('HH:mm')
      const date2 = moment(date).utc().add(this.poperInterview.allowedDuration || 60, 'm').format('HH:mm')
      this.poperInterview.due = `${date1} - ${date2}`
      const api = this.$refs.fullCalendar.getApi()
      let placement = 'right-start'
      if (api.view.type == "timeGridDay") {
        placement = 'bottom'
      }
      // console.log('this.refs.fullCalendar.view:', this.$refs.fullCalendar.getApi().view.type)
      // this.popper = createPopper(e.el, this.popperEl, {placement: 'right-start'});
      this.popper = createPopper(e.el, this.popperEl, {placement});
    },
    handleEvents(events) {
      this.currentEvents = events
    },
    eventDidMount(event) {
      event.el.style.backgroundColor = event.backgroundColor
      event.el.style.borderColor = event.borderColor
      event.el.style.borderRadius = 0
      if (event?.event?._def?.extendedProps?.status == 2 || event?.event?._def?.extendedProps?.status == 4) {
        event.el.parentNode.style.pointerEvents = 'none'
      }
      // contextmenu
      // e.el.addEventListener('contextmenu', jsEvent => {
      //   jsEvent.preventDefault()
      //   jsEvent.stopPropagation()
      //   this.popperDestroy()
      //   const index = this.findInterviewIndexBy(e.event.id)
      //   this.poperInterview = this.interviews[index]
      //   const date = this.poperInterview.deadline.$date
      //   const date1 = moment(date).format('HH:mm')
      //   const date2 = moment(date).add(this.poperInterview.allowedDuration || 60, 'm').format('HH:mm')
      //   this.poperInterview.due = `${date1} - ${date2}`
      //   const api = this.$refs.fullCalendar.getApi()
      //   let placement = 'right-start'
      //   if (api.view.type == "timeGridDay") {
      //     placement = 'bottom'
      //   }
      //   // console.log('this.refs.fullCalendar.view:', this.$refs.fullCalendar.getApi().view.type)
      //   // this.popper = createPopper(e.el, this.popperEl, {placement: 'right-start'});
      //   this.popper = createPopper(e.el, this.popperEl, {placement});
      // })
    },
    popperDestroy() {
      this.poperInterview = null
      if (this.popper) {
        this.popper.destroy()
        this.popper = null
      }
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.container-background {
  @include container-background;

  .container-content {
    @include container-content;
    padding: 35px 0 52px 0;
    position: relative;

    .legend-container {position: absolute; top: 40px; left: 430px; display: flex;
      .legend-item {display: flex; align-items: center; margin-right: 20px;
        .legend-icon {width: 40px; height: 13px; margin-right: 5px;}
        span {font-size: 12px; color: black; font-weight: 700;}
      }
      @media screen and (max-width: 1200px) {
        & {top: 12px;}
        // .legend-item {
        //   span {}
        // }
      }
    }
  }
}
.full-popover {border: 1px solid black; border-radius: 5px; box-shadow: 4px 4px 4px rgba(0,0,0,.25); padding: 20px; color: black; background: white; z-index: 999; text-align: left; width: 250px;
  .fp-title {font-size: 20px; font-weight: 700; margin: 0; line-height: 1; word-break: break-all;}
  .fp-subtitle {font-size: 16px; font-weight: 400; margin: 0; line-height: 1; margin-bottom: 10px; word-break: break-all;}
  .fp-time {font-size: 24px; font-weight: 700; margin: 0; line-height: 1; margin-bottom: 15px;}
  .fp-issue {font-size: 12px; color: white; background-color: #1946FC; border-radius: 10px; padding: 2px 10px; border: 0; box-shadow: none;}
  .fp-cancel {font-size: 12px; color: #666; border: 0; box-shadow: none; background: transparent; margin-left: 15px;}
  .fp-report {font-size: 12px; color: white; background-color: #4E96FF; border-radius: 10px; padding: 2px 10px; border: 0; box-shadow: none;}
}
</style>
