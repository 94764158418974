<template>
  <div class="container-background">
    <div class="container-content">
      <div class="content-header-container">
        <p class="content-header-title">Co-worker Account Management</p>
      </div>
      <form @submit.prevent="submitForm">
        <div class="lf-body-container">
          <div class="lf-body-content">
            <div class="lf-body-item" v-for="(account, index) in accountList" :key="index">
              <div class="lf-body-item-file">
                <p style="margin:0">
                  {{ account.email }} <span v-if="account.firstname">({{ account.firstname }} {{ account.lastname }} )</span>
                </p>
                <!-- <button class="if-cancel" type="submit">remove</button> -->
              </div>
            </div>

            <div class="lf-body-item">
              <h5 class="lf-body-item-label" for="email">Email to add</h5>
              <div class="lf-body-item-file">
                <input type="text" class="lf-body-item-text-input me-5" aria-label="email" aria-describedby="email-input" id="email" placeholder="Enter email to add" v-model.trim="email" />
                <button class="if-cancel" type="submit" style="background-color: #E52D6F; border: none">Submit</button>
              </div>
            </div>
            <p v-show="errorMessage" class="if-errorInput">{{ errorMessage }}</p>
          </div>
        </div>
      </form>
    </div>
  </div>
  <!-- <div class="row-flow-container">
    <div class="row-flow-header">
      <p class="callout">Co-worker Account Management</p>
    </div>
    <div class="row-flow-item-container" v-for="(account, index) in accountList" :key="index">
      <div class="row-flow-item-subject-area">
        <p class="row-flow-item-subject-text">
          {{ account.email }} <span v-if="account.firstname">({{ account.firstname }} {{ account.lastname }} )</span>
        </p>
      </div>
    </div>
    <form @submit.prevent="submitForm" class="row-flow-item-container">
      <div class="input-group">
        <input type="text" class="form-control w-70 me-1" id="email" placeholder="email to add" v-model.trim="email" />
        <button class="form-control btn btn-primary ms-1" type="submit">Submit</button>
      </div>
      <p v-show="hasError()" class="if-errorInput">{{ errorMessage }}</p>
    </form>
  </div> -->
</template>

<script>
import { BusinessUserApi, userProfileValidator } from "../../utils/index";

export default {
  name: "BusinessAccountManage",
  props: {},

  data() {
    return {
      accountList: null,
      errorMessage: null,
      email: null,
    };
  },

  components: {},

  created() { },
  mounted() {
    this.queryAccounts();
  },
  methods: {
    hasError() {
      return (this.errorMessage);
    },

    async submitForm() {
      //this.errorMessage = userProfileValidator.emailValidator.validate(this.email);
      (userProfileValidator);
      if (this.hasError()) {
        return;
      }

      BusinessUserApi.AddAccountByEmail(this.email)
        .then((response) => {
          response;
          this.queryAccounts();
        })
        .catch((error) => {
          if (error.response.status == 406) {
            this.errorMessage = error.response.data.text;
          } else {
            this.mnx_backendErrorHandler(error);
            console.log(error);
          }
        });
    },

    queryAccounts() {
      BusinessUserApi.QueryAccounts()
        .then((response) => {
          this.accountList = response.data;
        })
        .catch((error) => {
          this.mnx_backendErrorHandler(error);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.container-background {
  @include container-background;

  .container-content {
    @include container-content;
    padding: 45px 0;
  }
}

</style>
