import { backendAxios } from './axios'
import { userUtils } from '../store/index'

class BusinessUserApi {
    static SignUp(username, password) {
        const request = backendAxios.post(
            '/api/business/signup',
            {
                username: username,
                password: password,
            },
            {

            }
        );
        return request;
    }
    static SignUpOrInByEmail(email) {
        const request = backendAxios.post(
            '/api/business/signup-or-signin-by-email',
            {
                email: email,
            },
            {

            }
        );
        return request;
    }
    static SignIn(username, password) {
        const request = backendAxios.post(
            '/api/business/signin',
            {
                username: username,
                password: password,
            },
            {

            }
        );
        return request;
    }
    static signinWithEmailAndCode(email,code){
        const request = backendAxios.post(
            '/api/business/signin-by-email-code',
            {
                email: email,
                code: code,
            },
            {

            }
        );
        return request;

    }
    static SignOut() {
        let jwt = userUtils.getJwtToken();
        const request = backendAxios.post(
            '/api/business/signout',
            {
            },
            {
                headers: { Authorization: `Bearer ${jwt}` }
            }
        );
        return request;
    }

    static CheckUsernameAvailability(username) {
        const request = backendAxios.post(
            '/api/business/check-username-availability',
            {
                username: username,
            },
            {

            }
        );
        return request;
    }

    static FetchUserProfile() {
        let jwt = userUtils.getJwtToken();
        const request = backendAxios.post(
            '/api/business/fetch-profile',
            {

            },
            {
                headers: { Authorization: `Bearer ${jwt}` }

            }
        );
        return request;
    }

    static UpdateUsername(username) {
        let jwt = userUtils.getJwtToken();
        const request = backendAxios.post(
            '/api/business/update-username',
            {
                username: username
            },
            {
                headers: { Authorization: `Bearer ${jwt}` }

            }
        );
        return request;
    }

    static UpdatePassword(password) {
        let jwt = userUtils.getJwtToken();
        const request = backendAxios.post(
            '/api/business/update-password',
            {
                password: password
            },
            {
                headers: { Authorization: `Bearer ${jwt}` }

            }
        );
        return request;
    }

    static UpdateEmail(email, code) {
        let jwt = userUtils.getJwtToken();
        const request = backendAxios.post(
            '/api/business/update-email',
            {
                email: email,
                code: code,
            },
            {
                headers: { Authorization: `Bearer ${jwt}` }

            }
        );
        return request;
    }

    static UpdateFullname(firstname, lastname) {
        let jwt = userUtils.getJwtToken();
        const request = backendAxios.post(
            '/api/business/update-fullname',
            {
                firstname: firstname,
                lastname: lastname
            },
            {
                headers: { Authorization: `Bearer ${jwt}` }

            }
        );
        return request;
    }

    static FetchCodingInterview(interviewId) {
        let jwt = userUtils.getJwtToken();
        const request = backendAxios.post(
            '/api/business/fetch-coding-interview',
            {
                interviewId: interviewId
            },
            {
                headers: { Authorization: `Bearer ${jwt}` }
            }
        );
        return request;
    }

    static FetchSolutionSubmission(submissionId) {
        let jwt = userUtils.getJwtToken();
        const request = backendAxios.post(
            '/api/business/fetch-solution-submission',
            {
                submissionId: submissionId
            },
            {
                headers: { Authorization: `Bearer ${jwt}` }
            }
        );
        return request;
    }

    static QueryCodingInterviews(status, keyword = '') {
        let jwt = userUtils.getJwtToken();
        const request = backendAxios.post(
            '/api/business/query-coding-interviews',
            {
                status: status,
                keyword
            },
            {
                headers: { Authorization: `Bearer ${jwt}` }
            }
        );
        return request;
    }

    static ScheduleCodingInterview(interview) {
        let jwt = userUtils.getJwtToken();
        const request = backendAxios.post(
            '/api/business/schedule-coding-interview',
            {
                codingInterview: interview

            },
            {
                headers: { Authorization: `Bearer ${jwt}` }
            }
        );
        return request;
    }

    static UpdateCodingInterview(interviewId, interview) {
        let jwt = userUtils.getJwtToken();
        const request = backendAxios.post(
            '/api/business/update-coding-interview',
            {
                interviewId: interviewId,
                codingInterview: interview

            },
            {
                headers: { Authorization: `Bearer ${jwt}` }
            }
        );
        return request;
    }

    static CancelCodingInterview(interviewId) {
        let jwt = userUtils.getJwtToken();
        const request = backendAxios.post(
            '/api/business/cancel-coding-interview',
            {
                interviewId: interviewId

            },
            {
                headers: { Authorization: `Bearer ${jwt}` }
            }
        );
        return request;
    }

    static QueryAccounts() {
        let jwt = userUtils.getJwtToken();
        const request = backendAxios.post(
            '/api/business/query-accounts',
            {
            },
            {
                headers: { Authorization: `Bearer ${jwt}` }
            }
        );
        return request;
    }

    static AddAccountByEmail(email) {
        let jwt = userUtils.getJwtToken();
        const request = backendAxios.post(
            '/api/business/add-account-by-email',
            {
                email: email
            },
            {
                headers: { Authorization: `Bearer ${jwt}` }
            }
        );
        return request;
    }

    static FetchBusinessProfile() {
        let jwt = userUtils.getJwtToken();
        const request = backendAxios.post(
            '/api/business/fetch-business-profile',
            {
            },
            {
                headers: { Authorization: `Bearer ${jwt}` }
            }
        );
        return request;
    }

    static UpdateBusinessProfile(profile) {
        let jwt = userUtils.getJwtToken();
        const request = backendAxios.post(
            '/api/business/update-business-profile',
            {
                profile: profile
            },
            {
                headers: { Authorization: `Bearer ${jwt}` }
            }
        );
        return request;
    }

    static QueryJobs(status, keyword='') {
        let jwt = userUtils.getJwtToken();
        const request = backendAxios.post(
            '/api/business/query-jobs',
            {
                status: status,
                keyword
            },
            {
                headers: { Authorization: `Bearer ${jwt}` }
            }
        );
        return request;
    }

    static FetchJob(id) {
        let jwt = userUtils.getJwtToken();
        const request = backendAxios.post(
            '/api/business/fetch-job',
            {
                id: id
            },
            {
                headers: { Authorization: `Bearer ${jwt}` }
            }
        );
        return request;
    }

    static AddJob(job) {
        let jwt = userUtils.getJwtToken();
        const request = backendAxios.post(
            '/api/business/add-job',
            {
                job: job

            },
            {
                headers: { Authorization: `Bearer ${jwt}` }
            }
        );
        return request;
    }

    static UpdateJob(id, job) {
        let jwt = userUtils.getJwtToken();
        const request = backendAxios.post(
            '/api/business/update-job',
            {
                id: id,
                job: job

            },
            {
                headers: { Authorization: `Bearer ${jwt}` }
            }
        );
        return request;
    }

    static QueryApplicants(status, keyword = '') {
        let jwt = userUtils.getJwtToken();
        const request = backendAxios.post(
            '/api/business/query-applicants',
            {
                status: status,
                keyword
            },
            {
                headers: { Authorization: `Bearer ${jwt}` }
            }
        );
        return request;
    }

    static FetchApplicant(id) {
        let jwt = userUtils.getJwtToken();
        const request = backendAxios.post(
            '/api/business/fetch-applicant',
            {
                id: id
            },
            {
                headers: { Authorization: `Bearer ${jwt}` }
            }
        );
        return request;
    }

    static AddApplicant(applicant) {
        let jwt = userUtils.getJwtToken();
        const request = backendAxios.post(
            '/api/business/add-applicant',
            {
                applicant: applicant

            },
            {
                headers: { Authorization: `Bearer ${jwt}` }
            }
        );
        return request;
    }

    static UpdateApplicant(id, applicant) {
        let jwt = userUtils.getJwtToken();
        const request = backendAxios.post(
            '/api/business/update-applicant',
            {
                id: id,
                applicant: applicant

            },
            {
                headers: { Authorization: `Bearer ${jwt}` }
            }
        );
        return request;
    }

    static FetchQuestion(id) {
        let jwt = userUtils.getJwtToken();
        const request = backendAxios.post(
            '/api/business/fetch-question',
            {
                id: id
            },
            {
                headers: { Authorization: `Bearer ${jwt}` }
            }
        );
        return request;
    }


}

export { BusinessUserApi }