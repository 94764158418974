<template>
    <div class="card-container">
        <div v-for="item in cards" :class="sel == item.id ? 'card-item active' : 'card-item'" :key="item.id" @click="selAt(item)">
            <p class="card-title">{{item.title}}</p>
            <p class="card-subtitle">{{item.content}}</p>
        </div>
        <div class="card-add" @click="addAction">
            <img class="card-add-icon" src="@/assets/add.png" alt="magic leaps add button">
            <span class="card-add-text">add</span>
        </div>
        <div style="clear:both" />
    </div>
</template>
<script>
export default {
    name: 'CustomModal',
    props: {
        cards: {
            type: Array,
            default: () => []
        },
        sel: {
            type: String,
            default: () => ''
        }
    },
    methods: {
        selAt(item) {
            this.$emit('update:sel', item.id)
        },
        addAction() {
            this.$emit('add')
        }
    }
}
</script>
<style lang="scss" scoped>
.card-container {margin-bottom: -18px;
    .card-item {width: 200px; height: 108px; border-radius: 10px; background-color: white; padding: 14px 18px; color: #282828; overflow: auto; float: left; margin-right: 18px; margin-bottom: 18px; cursor: pointer; text-align: left;
        .card-title {font-size: 16px; font-weight: 700; line-height: 21px; color: inherit; margin-bottom: 3px;}
        .card-subtitle {font-size: 12px; line-height: 16px; color: inherit; margin-bottom: 0;}
        &:hover {opacity: .78;}
        &.active {background-color: #1946FC; color: white;}
    }
    .card-add {width: 137px; height: 108px; border-radius: 10px; background-color: white; display: flex; justify-content: center; align-items: center; flex-direction: column; cursor: pointer; float: left;
        .card-add-icon {width: 25px; height: 25px; margin-bottom: 13px;}
        .card-add-text {font-size: 12px; font-weight: 500; color: #676767;}
        &:hover {opacity: .78;}
    }
}
</style>