import { JobTitleValidator } from "./jobTitleValidator"
import {JobDescriptionValidator} from "./jobDescription"

class JobPositionValidator {
    constructor() {
        this.jobTitleValidator = new JobTitleValidator();
        this.jobDescriptionValidator = new JobDescriptionValidator();
    }
}

const jobPositionValidator = new JobPositionValidator();

export { jobPositionValidator }
