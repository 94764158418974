import { CodingInterviewJobPositionValidator } from "./codingInterviewJobPositionValidator"
import { CodingInterviewNoteToCandidateValidator } from "./codingInterviewNoteToCandidateValidator"

class CodingInterviewValidator {
    constructor() {
        this.codingInterviewJobPositionValidator = new CodingInterviewJobPositionValidator();
        this.codingInterviewNoteToCandidateValidator = new CodingInterviewNoteToCandidateValidator();
    }
}

const codingInterviewValidator = new CodingInterviewValidator();

export { codingInterviewValidator }
