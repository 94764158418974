
const interviewQuestionStruct = {
    title: null,
    description: null,
    inputFormat: null,
    outputFormat: null,
    sampleInput: null,
    sampleOutput: null,
    scoring: null,
    difficultyLevel: 0,
    testCases: null,
    testCaseFilename: null,
    testCaseFilelength: 0,
    notes: null,
}

export { interviewQuestionStruct }