import { store } from "../../plugins/index";

class InterviewProblemUtils {
    constructor(store) {
        this.store = store;
    }

    fill(problem) {
        this.problem = problem;
    }

    fetch() {
        return this.problem;
    }
    fillId(id) {
        this.id = id;
    }

    fetchId() {
        return this.id;
    }
}

const interviewProblemUtils = new InterviewProblemUtils(store);

export { interviewProblemUtils };