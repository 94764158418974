<template>
  <div class="container-background">
    <div class="container-content">
      <div class="content-header-container">
        <p class="content-header-title">Job position</p>
      </div>
      <form @submit.prevent="submitForm">
        <div class="lf-body-container">
          <div class="lf-body-content">
            <div class="lf-body-item">
              <h5 class="lf-body-item-label" for="jobPosition">Job title:</h5>
              <p class="lf-body-item-label-note">
                A phrase or sentence about the job. (4~256 words)
              </p>
              <input type="text" class="lf-body-item-text-input max700" id="jobPosition" placeholder="Job title" v-model.trim="jobPosition.title" />
            </div>
            <div class="lf-body-item">
              <h5 class="lf-body-item-label" for="description">Description:</h5>
              <p class="lf-body-item-label-note">The job's description. (128~8192 characters)</p>
              <textarea class="lf-body-item-rich-text" id="description" placeholder="Job description" v-model.trim="jobPosition.description" @keyup="textAreaAdjust($event)" />
            </div>
          </div>
        </div>
        <p v-show="hasError()" class="if-errorInput">{{ errorMessage }}</p>
        <div class="lf-submit-container">
          <button class="if-cancel" type="button" @click="goBack">Cancel</button>
          <button class="if-submit" type="submit" v-bind:disabled="submitting">
            <span v-if="submitting" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span>{{submitting?'&nbsp;&nbsp;&nbsp;&nbsp;Submitting':'Submit'}}</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {BusinessUserApi, jobPositionUtils, jobPositionValidator, textAreaAujuster} from "../../utils/index";
import { jobPositionStruct } from "../../types/index";
export default {
  name: "BusinessNewJobPosition",
  props: {},
  data() {
    return {
      jobPosition: jobPositionUtils.fetch() ? jobPositionUtils.fetch() : jobPositionStruct,
      jobId: jobPositionUtils.fetchJobPositionId() || null,
      errorMessage: null,
      submitting: false
    };
  },
  components: {},
  created() { },
  mounted() { },
  methods: {
    goBack() {
      this.mnx_goBack();
    },
    hasError() {
      return this.errorMessage;
    },
    textAreaAdjust(event) {
      let element = event.target;
      textAreaAujuster.adjustHight(element);
    },
    async submitForm() {
      this.errorMessage = jobPositionValidator.jobTitleValidator.validate(
        this.jobPosition.title
      );
      if (this.hasError()) return;
      this.errorMessage = jobPositionValidator.jobDescriptionValidator.validate(
        this.jobPosition.description
      );
      if (this.hasError()) return;

      this.submitting = true;

      if (this.jobId) {
        BusinessUserApi.UpdateJob(this.jobId, this.jobPosition)
          .then((response) => {
            this.jobPosition = response.data;
            this.mnx_navToBusinessJobPositionAdded();
            this.submitting = false;
          })
          .catch((error) => {
            this.submitting = false;
            this.mnx_backendErrorHandler(error);
          });
      } else {
        BusinessUserApi.AddJob(this.jobPosition)
          .then((response) => {
            this.jobPosition = response.data;
            this.mnx_navToBusinessJobPositionAdded();
            this.submitting = false;
          })
          .catch((error) => {
            this.submitting = false;
            this.mnx_backendErrorHandler(error);
          });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.container-background {
  @include container-background;

  .container-content {
    @include container-content;
    padding: 45px 0;
  }
}
::v-deep .if-submit:disabled {
  background-color: #E52D6F;
  border-color: #E52D6F
}
::v-deep .if-submit.disabled {
  background-color: #E52D6F;
  border-color: #E52D6F
}
</style>
