import { TextValidator } from './textValidator'
const websiteFormatMessage = "need a valid website address";

const websitePattern =  /^([0-9a-z-]{1,}.)?[0-9a-z-]{2,}.([0-9a-z-]{2,}.)?[a-z]{2,}$/i;
const websiteMinLength = 3;
const websiteMaxLength = 256;

class WebsiteValidator extends TextValidator {
    constructor() {
        super(
            "Website",
            websiteFormatMessage,
            [
                {
                    regex: websitePattern,
                    message: " is not a valid website address."
                }
            ],
            websiteMinLength,
            websiteMaxLength
        );
    }

}

export { WebsiteValidator }

