
class TextAreaAujuster {
    adjustHight(element) {
        if (element) {
          /*first check if it already scrolls to the bottom */
          if (Math.abs(element.scrollHeight - element.clientHeight - element.scrollTop) >= 1) {
            if (!element.scrollHeight)
              element.style.height = "1px";
            element.style.height = (5 + element.scrollHeight) + "px";
          }
        }
      }
    }

    
const textAreaAujuster = new TextAreaAujuster();

export { textAreaAujuster };