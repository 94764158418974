import { createRouter, createWebHistory } from 'vue-router';
import { store, userRoleEnum } from '../store/index';

import FrontDoor from "../../pages/FrontDoor";

import AdminFrontDoor from "../../pages/admin/FrontDoor";
import AdminSigninWithEmailAndCode from "../../pages/admin/SigninWithCode";
import AdminHomePage from "../../pages/admin/HomePage";
import AdminManageAdmin from "../../pages/admin/AdminManage"
import AdminManageDesigner from "../../pages/admin/DesignerManage"
import AdminManageBusiness from "../../pages/admin/BusinessManage"
import AdminManagePersonal from "../../pages/admin/PersonalManage"
import AdminManageEveluation from "../../pages/admin/EveluationManage"

import BusinessFrontDoor from "../../pages/business/FrontDoor";
import BusinessSigninWithEmailCode from "../../pages/business/SigninWithEmailCode"
import BusinessHomePage from "../../pages/business/HomePage"
import BusinessEditCodingInterview from "../../pages/business/EditCodingInterview"
import CodingInterviewScheduled from "../../pages/business/CodingInterviewScheduled"
import BusinessCompletedCodingInterview from "../../pages/business/CompletedCodingInterview"
import BusinessCancelledCodingInterview from "../../pages/business/CancelledCodingInterview"
import BusinessOngoingCodingInterview from "../../pages/business/OngoingCodingInterview"
import BusinessAccountManage from "../../pages/business/AccountManage"
import BusinessBusinessProfile from "../../pages/business/BusinessProfile"
import BusinessSubscription from "../../pages/business/Subscriptions"
import BusinessPersonalProfile from "../../pages/business/BusinessPersonalProfile"
import BusinessPersonalProfileUpdated from "../../pages/business/BusinessPersonalProfileUpdated"
import BusinessPersonalUpdateEmail from "../../pages/business/UpdateEmail"
import BusinessPersonalEmailUpdateRequireCode from "../../pages/business/EmailUpdateRequireCode"
import BusinessPersonalEmailUpdated from "../../pages/business/EmailUpdated"
import BusinessPersonalUpdateFullname from "../../pages/business/UpdateFullname"
import BusinessPersonalFullnameUpdated from "../../pages/business/FullnameUpdated"
import BusinessPersonalUpdatePassword from "../../pages/business/UpdatePassword"
import BusinessPersonalPasswordUpdated from "../../pages/business/PasswordUpdated"
import BusinessPersonalUpdateUsername from "../../pages/business/UpdateUsername"
import BusinessPersonalUsernameUpdated from "../../pages/business/UsernameUpdated"
import BusinessCodingInterviewHome from "../../pages/business/CodingInterviewHome"
import BusinessCodingInterviewReport from "../../pages/business/ViewCodingInterviewReport"
import BusinessViewCodingProblem from "../../pages/business/ViewCodingProblem"
import BusinessViewSubmissionScoreDetails from "../../pages/business/ViewSubmissionScoreDetails"
import BusinessApplicants from "../../pages/business/Applicants"
import BusinessNewApplicant from "../../pages/business/NewApplicant"
import BusinessApplicantAdded from "../../pages/business/ApplicantAdded"
import BusinessJobPositions from "../../pages/business/JobPositions"
import BusinessNewJobPosition from "../../pages/business/NewJobPosition"
import BusinessJobPositionAdded from "../../pages/business/JobPositionAdded"
import BusinessResources from "../../pages/business/Resources"
import BusinessProfileUpdated from "../../pages/business/BusinessProfileUpdated"

import DesignerFrontDoor from "../../pages/designer/FrontDoor";
// import DesignerSigninWithEmailAndCode from "../../pages/designer/SigninWithCode";
import DesignerHomePage from "../../pages/designer/HomePage";
import DesignerEditProblem from "../../pages/designer/EditProblem"
import DesignerProblemSubmitted from "../../pages/designer/ProblemSubmitted"
import DesignerProfile from "../../pages/designer/DesignerProfile"
import DesignerProfileUpdated from "../../pages/designer/DesignerProfileUpdated"
// import DesignerUpdateFullname from "../../pages/designer/UpdateFullname"
// import DesignerFullnameUpdated from "../../pages/designer/FullnameUpdated"
// import DesignerUpdateEmail from "../../pages/designer/UpdateEmail"
// import DesignerUpdateEmailWithCode from "../../pages/designer/UpdateEmailWithCode"
// import DesignerEmailUpdated from "../../pages/designer/EmailUpdated"


import PersonalFrontDoor from "../../pages/personal/FrontDoor";
import PersonalSigninWithEmailAndCode from "../../pages/personal/SigninWithCode";
import PersonalCompletedInterviews from "../../pages/personal/CompletedInterviews";
import PersonalViewCodingInterviewReport from "../../pages/personal/ViewCodingInterviewReport";
import PersonalHomePage from "../../pages/personal/HomePage";
import PersonalResolveProblem from "../../pages/personal/ResolveProblem"
import PersonalSolutionSubmitted from "../../pages/personal/SolutionSubmitted"
import PersonalProfile from "../../pages/personal/Profile";
import PersonalProfileUpdated from "../../pages/personal/ProfileUpdated"
import PersonalViewSubmissionScoreDetails from "../../pages/personal/ViewSubmissionScoreDetails"
import PersonalViewPosition from "../../pages/personal/ViewPosition"
import PersonalViewBusinessProfile from "../../pages/personal/ViewBusinessProfile"
import PersonalStartInterview from "../../pages/personal/StartInterview"

import HeaderComponent from "../../headers/HeaderComponent.vue";
import HeaderGuest from "../../headers/HeaderGuest.vue";
import HeaderDesigner from "../../headers/HeaderDesigner.vue";
import HeaderBusiness from "../../headers/HeaderBusiness.vue";
import HeaderPersonal from "../../headers/HeaderPersonal.vue";

import FooterComponent from "../../footers/FooterComponent.vue"
import FooterGuest from "../../footers/FooterGuest"
import FooterAdmin from "../../footers/FooterAdmin"
// import FooterBusiness from "../../footers/FooterBusiness"
// import FooterPersonal from "../../footers/FooterPersonal"
// import FooterDesigner from "../../footers/FooterDesigner"

import ContactUs from "../../pages/guest/ContactUs"
import AboutMagicleapsPlatform from "../../pages/guest/AboutMagicleapsPlatform"
import AboutFreeleapsCompany from "../../pages/guest/AboutFreeleapsCompany"

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', redirect: '/front-door' },    /*
    {
      path: '/users',
      components: {
        default: UsersList,
        footer: UsersFooter
      },
      beforeEnter(to, from, next) {
        console.log('users beforeEnter');
        console.log(to, from);
        next();
      }
    },
    { path: '/:notFound(.*)', component: NotFound }
    */
   // guest
    {
      name: 'front-door',
      path: '/front-door',
      meta: { requiredRoles: [userRoleEnum.NONE] },
      components: { default: FrontDoor, footer: FooterComponent , header: HeaderComponent },
      /*children: [
        {
          name: 'team-members',
          path: ':teamId',
          component: TeamMembers,
          props: true
        } // /teams/t1
      ]*/
    },
    {
      name: 'contact-us',
      path: '/contact-us',
      meta: { requiredRoles: [userRoleEnum.NONE] },
      components: { default: ContactUs, footer: FooterComponent, header: HeaderComponent },
    },
    {
      name: 'about-magicleaps-platform',
      path: '/about-magicleaps-platform',
      meta: { requiredRoles: [userRoleEnum.NONE] },
      components: { default: AboutMagicleapsPlatform, footer: FooterComponent, header: HeaderComponent },
    },
    {
      name: 'about-freeleaps-company',
      path: '/about-freeleaps-company',
      meta: { requiredRoles: [userRoleEnum.NONE] },
      components: { default: AboutFreeleapsCompany, footer: FooterComponent, header: HeaderComponent },
    },

    // business
    {
      name: 'business-front-door',
      path: '/business-front-door',
      meta: { requiredRoles: [userRoleEnum.NONE] },
      components: { default: BusinessFrontDoor, footer: FooterComponent , header: HeaderComponent }
    },
    {
      name: 'business-signin-with-email-code',
      path: '/business-signin-with-email-code/:email',
      meta: { requiredRoles: [userRoleEnum.NONE] },
      components: { default: BusinessSigninWithEmailCode, footer: FooterComponent, header: HeaderGuest },
      props: true,
    },
    {
      name: 'business-home-page',
      path: '/business-home-page',
      meta: { requiredRoles: [userRoleEnum.BUSINESS] },
      components: { default: BusinessHomePage, footer: FooterComponent, header: HeaderBusiness },
    },
    {
      name: 'business-ongoing-coding-interview',
      path: '/business-ongoing-coding-interview',
      meta: { requiredRoles: [userRoleEnum.BUSINESS] },
      components: { default: BusinessOngoingCodingInterview, footer: FooterComponent, header: HeaderBusiness },
    },
    {
      name: 'business-completed-coding-interview',
      path: '/business-completed-coding-interview',
      meta: { requiredRoles: [userRoleEnum.BUSINESS] },
      components: { default: BusinessCompletedCodingInterview, footer: FooterComponent, header: HeaderBusiness },
    },

    {
      name: 'business-cancelled-coding-interview',
      path: '/business-cancelled-coding-interview',
      meta: { requiredRoles: [userRoleEnum.BUSINESS] },
      components: { default: BusinessCancelledCodingInterview, footer: FooterComponent, header: HeaderBusiness },
    },

    {
      name: 'business-edit-coding-interview',
      path: '/business-edit-coding-interview',
      meta: { requiredRoles: [userRoleEnum.BUSINESS] },
      components: { default: BusinessEditCodingInterview, footer: FooterComponent, header: HeaderBusiness },
    },

    {
      name: 'business-view-submission-score-details',
      path: '/business-view-submission-score-details',
      meta: { requiredRoles: [userRoleEnum.BUSINESS] },
      components: { default: BusinessViewSubmissionScoreDetails, footer: FooterComponent, header: HeaderBusiness },
    },

    {
      name: 'business-coding-interview-scheduled',
      path: '/business-coding-interview-scheduled',
      meta: { requiredRoles: [userRoleEnum.BUSINESS] },
      components: { default: CodingInterviewScheduled, footer: FooterComponent, header: HeaderBusiness },
    },
    {
      name: 'business-subscription',
      path: '/business-subscription',
      meta: { requiredRoles: [userRoleEnum.BUSINESS] },
      components: { default: BusinessSubscription, footer: FooterComponent, header: HeaderBusiness },
    },
    {
      name: 'business-profile',
      path: '/business-profile',
      meta: { requiredRoles: [userRoleEnum.BUSINESS] },
      components: { default: BusinessBusinessProfile, footer: FooterComponent, header: HeaderBusiness },
    },

    {
      name: 'business-profile-updated',
      path: '/business-profile-updated',
      meta: { requiredRoles: [userRoleEnum.BUSINESS] },
      components: { default: BusinessProfileUpdated, footer: FooterComponent, header: HeaderBusiness },
    },


    {
      name: 'business-account-manage',
      path: '/business-account-manage',
      meta: { requiredRoles: [userRoleEnum.BUSINESS] },
      components: { default: BusinessAccountManage, footer: FooterComponent, header: HeaderBusiness },
    },

    {
      name: 'business-personal-profile',
      path: '/business-personal-profile',
      meta: { requiredRoles: [userRoleEnum.BUSINESS] },
      components: { default: BusinessPersonalProfile, footer: FooterComponent, header: HeaderBusiness },
    },
    {
      name: 'business-personal-profile-updated',
      path: '/business-personal-profile-updated',
      meta: { requiredRoles: [userRoleEnum.BUSINESS] },
      components: { default: BusinessPersonalProfileUpdated, footer: FooterComponent, header: HeaderBusiness },
    },
    {
      name: 'business-personal-update-email',
      path: '/business-personal-update-email',
      meta: { requiredRoles: [userRoleEnum.BUSINESS] },
      components: { default: BusinessPersonalUpdateEmail, footer: FooterComponent, header: HeaderBusiness },
    },
    {
      name: 'business-personal-email-updated',
      path: '/business-personal-email-updated/:email',
      meta: { requiredRoles: [userRoleEnum.BUSINESS] },
      components: { default: BusinessPersonalEmailUpdated, footer: FooterComponent, header: HeaderBusiness },
      props: true,
    },
    {
      name: 'business-personal-email-update-require-code',
      path: '/business-personal-email-update-require-code/:email',
      meta: { requiredRoles: [userRoleEnum.BUSINESS] },
      components: { default: BusinessPersonalEmailUpdateRequireCode, footer: FooterComponent, header: HeaderBusiness },
      props: true,
    },

    {
      name: 'business-personal-update-fullname',
      path: '/business-personal-update-fullname',
      meta: { requiredRoles: [userRoleEnum.BUSINESS] },
      components: { default: BusinessPersonalUpdateFullname, footer: FooterComponent, header: HeaderBusiness },
    },
    {
      name: 'business-personal-fullname-updated',
      path: '/business-personal-fullname-updated',
      meta: { requiredRoles: [userRoleEnum.BUSINESS] },
      components: { default: BusinessPersonalFullnameUpdated, footer: FooterComponent, header: HeaderBusiness },
    },
    {
      name: 'business-personal-update-password',
      path: '/business-personal-update-password',
      meta: { requiredRoles: [userRoleEnum.BUSINESS] },
      components: { default: BusinessPersonalUpdatePassword, footer: FooterComponent, header: HeaderBusiness },
    },
    {
      name: 'business-personal-password-updated',
      path: '/business-personal-password-updated',
      meta: { requiredRoles: [userRoleEnum.BUSINESS] },
      components: { default: BusinessPersonalPasswordUpdated, footer: FooterComponent, header: HeaderBusiness },
    },
    {
      name: 'business-personal-update-username',
      path: '/business-personal-update-username',
      meta: { requiredRoles: [userRoleEnum.BUSINESS] },
      components: { default: BusinessPersonalUpdateUsername, footer: FooterComponent, header: HeaderBusiness },
      props: false,
    },
    {
      name: 'business-personal-username-updated',
      path: '/business-personal-username-updated/:username',
      meta: { requiredRoles: [userRoleEnum.BUSINESS] },
      components: { default: BusinessPersonalUsernameUpdated, footer: FooterComponent, header: HeaderBusiness },
      props: true,
    },

    {
      name: 'business-coding-interview-home',
      path: '/business-coding-interview-home',
      meta: { requiredRoles: [userRoleEnum.BUSINESS] },
      components: { default: BusinessCodingInterviewHome, footer: FooterComponent, header: HeaderBusiness },
    },
    {
      name: 'business-coding-interview-report',
      path: '/business-coding-interview-report/:id',
      meta: { requiredRoles: [userRoleEnum.BUSINESS] },
      props: true,
      components: { default: BusinessCodingInterviewReport, footer: FooterComponent, header: HeaderBusiness },
    },

    {
      name: 'business-view-coding-problem',
      path: '/business-view-coding-problem/:problemId',
      meta: { requiredRoles: [userRoleEnum.BUSINESS] },
      props: true,
      components: { default: BusinessViewCodingProblem, footer: FooterComponent, header: HeaderBusiness },
    },
    {
      name: 'business-applicants',
      path: '/business-applicants',
      meta: { requiredRoles: [userRoleEnum.BUSINESS] },
      components: { default: BusinessApplicants, footer: FooterComponent, header: HeaderBusiness },
    },
    {
      name: 'business-edit-applicant',
      path: '/business-edit-applicant',
      meta: { requiredRoles: [userRoleEnum.BUSINESS] },
      components: { default: BusinessNewApplicant, footer: FooterComponent, header: HeaderBusiness },
    },

    {
      name: 'business-applicant-added',
      path: '/business-applicant-added',
      meta: { requiredRoles: [userRoleEnum.BUSINESS] },
      components: { default: BusinessApplicantAdded, footer: FooterComponent, header: HeaderBusiness },
    },

    {
      name: 'business-job-positions',
      path: '/business-job-positions',
      meta: { requiredRoles: [userRoleEnum.BUSINESS] },
      components: { default: BusinessJobPositions, footer: FooterComponent, header: HeaderBusiness },
    },
    {
      name: 'business-edit-job-position',
      path: '/business-edit-job-position',
      meta: { requiredRoles: [userRoleEnum.BUSINESS] },
      components: { default: BusinessNewJobPosition, footer: FooterComponent, header: HeaderBusiness },
    },
    {
      name: 'business-job-position-added',
      path: '/business-job-position-added',
      meta: { requiredRoles: [userRoleEnum.BUSINESS] },
      components: { default: BusinessJobPositionAdded, footer: FooterComponent, header: HeaderBusiness },
    },
    {
      name: 'business-resources',
      path: '/business-resources',
      meta: { requiredRoles: [userRoleEnum.BUSINESS] },
      components: { default: BusinessResources, footer: FooterComponent, header: HeaderBusiness },
    },


    //personal
    {
      name: 'personal-front-door',
      path: '/personal-front-door',
      meta: { requiredRoles: [userRoleEnum.NONE] },
      components: { default: PersonalFrontDoor, footer: FooterComponent, header: HeaderComponent },
      props: false
      /*children: [
        {
          name: 'team-members',
          path: ':teamId',
          component: TeamMembers,
          props: true
        } // /teams/t1
      ]*/
    },

    {
      name: 'personal-signin-by-email-and-code',
      path: '/personal-signin-by-email-and-code/:email',
      meta: { requiredRoles: [userRoleEnum.NONE] },
      components: { default: PersonalSigninWithEmailAndCode, footer: FooterGuest, header: HeaderGuest },
      props: true,
    },

    {
      name: 'personal-home',
      path: '/personal-home',
      meta: { requiredRoles: [userRoleEnum.PERSONAL] },
      components: { default: PersonalHomePage, footer: FooterComponent, header: HeaderPersonal },
      props: false,
    },

    {
      name: 'personal-completed-interviews',
      path: '/personal-completed-interviews',
      meta: { requiredRoles: [userRoleEnum.PERSONAL] },
      components: { default: PersonalCompletedInterviews, footer: FooterComponent, header: HeaderPersonal },
      props: false,
    },
    {
      name: 'personal-view-coding-interview-report',
      path: '/personal-view-coding-interview-report/:id',
      meta: { requiredRoles: [userRoleEnum.PERSONAL] },
      components: { default: PersonalViewCodingInterviewReport, footer: FooterComponent, header: HeaderPersonal },
      props: true,
    },

    {
      name: 'personal-resolve-problem',
      path: '/personal-resolve-problem/:interviewId',
      meta: { requiredRoles: [userRoleEnum.PERSONAL] },
      components: { default: PersonalResolveProblem, footer: FooterComponent, header: HeaderPersonal },
      props: true,
    },

    {
      name: 'personal-solution-submitted',
      path: '/personal-solution-submitted/:submissionId',
      meta: { requiredRoles: [userRoleEnum.PERSONAL] },
      components: { default: PersonalSolutionSubmitted, footer: FooterComponent, header: HeaderPersonal },
      props: true,
    },
    {
      name: 'personal-profile',
      path: '/personal-profile',
      meta: { requiredRoles: [userRoleEnum.PERSONAL] },
      components: { default: PersonalProfile, footer: FooterComponent, header: HeaderPersonal },
      props: false,
    },
    {
      name: 'personal-profile-updated',
      path: '/personal-profile-updated',
      meta: { requiredRoles: [userRoleEnum.PERSONAL] },
      components: { default: PersonalProfileUpdated, footer: FooterComponent, header: HeaderPersonal },
      props: false,
    },
    {
      name: 'personal-view-submission-score-details',
      path: '/personal-view-submission-score-details',
      meta: { requiredRoles: [userRoleEnum.PERSONAL] },
      components: { default: PersonalViewSubmissionScoreDetails, footer: FooterComponent, header: HeaderPersonal },
      props: false,
    },

    {
      name: 'personal-view-position',
      path: '/personal-view-position/:jobId',
      meta: { requiredRoles: [userRoleEnum.PERSONAL] },
      components: { default: PersonalViewPosition, footer: FooterComponent, header: HeaderPersonal },
      props: true,
    },
    {
      name: 'personal-view-business',
      path: '/personal-view-business/:businessId',
      meta: { requiredRoles: [userRoleEnum.PERSONAL] },
      components: { default: PersonalViewBusinessProfile, footer: FooterComponent, header: HeaderPersonal },
      props: true,
    },
    {
      name: 'personal-start-interview',
      path: '/personal-start-interview/:interviewId',
      meta: { requiredRoles: [userRoleEnum.PERSONAL] },
      components: { default: PersonalStartInterview, footer: FooterComponent, header: HeaderPersonal },
      props: true,
    },

    // designer
    {
      name: 'designer-front-door',
      path: '/designer-front-door',
      meta: { requiredRoles: [userRoleEnum.NONE] },
      components: { default: DesignerFrontDoor, footer: FooterComponent, header: HeaderComponent },
    },

    {
      name: 'designer-home',
      path: '/designer-home',
      meta: { requiredRoles: [userRoleEnum.DESIGNER] },
      components: { default: DesignerHomePage, footer: FooterComponent, header: HeaderDesigner },
      props: false,
    },

    {
      name: 'designer-edit-problem',
      path: '/designer-edit-problem',
      meta: { requiredRoles: [userRoleEnum.DESIGNER] },
      components: { default: DesignerEditProblem, footer: FooterComponent, header: HeaderDesigner },
      props: false,
    },

    {
      name: 'designer-problem-submitted',
      path: '/designer-problem-submitted',
      meta: { requiredRoles: [userRoleEnum.DESIGNER] },
      components: { default: DesignerProblemSubmitted, footer: FooterComponent, header: HeaderDesigner },
      props: false,
    },

    {
      name: 'designer-profile',
      path: '/designer-profile',
      meta: { requiredRoles: [userRoleEnum.DESIGNER] },
      components: { default: DesignerProfile, footer: FooterComponent, header: HeaderDesigner },
      props: false,
    },


    {
      name: 'designer-profile-updated',
      path: '/designer-profile-updated',
      meta: { requiredRoles: [userRoleEnum.DESIGNER] },
      components: { default: DesignerProfileUpdated, footer: FooterComponent, header: HeaderDesigner },
      props: false,
    },

    // admin
    {
      name: 'admin-front-door',
      path: '/admin-front-door',
      meta: { requiredRoles: [userRoleEnum.NONE] },
      components: { default: AdminFrontDoor, footer: FooterGuest, header: HeaderGuest },
    },

    {
      name: 'admin-signin-by-email-and-code',
      path: '/admin-signin-by-email-and-code/:email',
      meta: { requiredRoles: [userRoleEnum.NONE] },
      components: { default: AdminSigninWithEmailAndCode, footer: FooterGuest, header: HeaderGuest },
      props: true,
    },

    {
      name: 'admin-home-page',
      path: '/admin-home-page',
      meta: { requiredRoles: [userRoleEnum.ADMIN] },
      components: { default: AdminHomePage, footer: FooterAdmin, header: HeaderGuest },
      props: false,
    },

    {
      name: 'admin-manage-designer',
      path: '/admin-manage-designer',
      meta: { requiredRoles: [userRoleEnum.ADMIN] },
      components: { default: AdminManageDesigner, footer: FooterAdmin, header: HeaderGuest },
      props: false,
    },

    {
      name: 'admin-manage-admin',
      path: '/admin-manage-admin',
      meta: { requiredRoles: [userRoleEnum.ADMIN] },
      components: { default: AdminManageAdmin, footer: FooterAdmin, header: HeaderGuest },
      props: false,
    },
    {
      name: 'admin-manage-personal',
      path: '/admin-manage-personal',
      meta: { requiredRoles: [userRoleEnum.ADMIN] },
      components: { default: AdminManagePersonal, footer: FooterAdmin, header: HeaderGuest },
      props: false,
    },
    {
      name: 'admin-manage-business',
      path: '/admin-manage-business',
      meta: { requiredRoles: [userRoleEnum.ADMIN] },
      components: { default: AdminManageBusiness, footer: FooterAdmin, header: HeaderGuest },
      props: false,
    },
    {
      name: 'admin-manage-eveluation',
      path: '/admin-manage-eveluation',
      meta: { requiredRoles: [userRoleEnum.ADMIN] },
      components: { default: AdminManageEveluation, footer: FooterAdmin, header: HeaderGuest },
      props: false,
    },
  ],

  linkActiveClass: 'active',
  /*    scrollBehavior(_, _2, savedPosition) {
        if (savedPosition) {
          return savedPosition;
        }
        return { left: 0, top: 0 };
      }
  */
});



router.beforeEach(function (to, from, next) {
  if (to.name == 'front-door') {
    next();
  }
  else if (to.meta.requiredRoles.includes(userRoleEnum.NONE)) {
    next();
  }
  else if (to.meta.requiredRoles.includes(store.getters["userProfile/userRole"])) {
    next();
  } else {
    next('/front-door');
  }
});


// router.beforeEach(function(to, from, next) {
//   console.log('Global beforeEach');
//   console.log(to, from);
//   if (to.meta.needsAuth) {
//     console.log('Needs auth!');
//     next();
//   } else {
//     next();
//   }
//   // if (to.name === 'team-members') {
//   //   next();
//   // } else {
//   //   next({ name: 'team-members', params: { teamId: 't2' } });
//   // }
//   // next();
// });
 
// router.afterEach(function(to, from) {
//   // sending analytics data
//   console.log('Global afterEach');
//   console.log(to, from);
// });


export { router };