<template>
  <div class="navigator-container-background isBusiness">
    <div class="navigator-container-content">
      <img class="logo" alt="freeleaps logo" src="@/assets/logo_for_white.png" @click="gotoFrontDoor" />
      <div class="flex-space"></div>
      <!-- <SwitchLang /> -->
      <button v-if="!authed" type="button" class="btn btn-link text-bold sign-in" @click="signinAction">{{$t('Take Interview')}}</button>
    </div>
    <CustomModal v-model:visible="signinModalVisible">
      <div class="signin-container" v-if="!hadSendCode">
        <p class="signin-title">Sign in by Email</p>
        <form @submit.prevent="sendCodeToEmail">
          <input class="signin-input big" type="text" placeholder="Enter your Email" v-model="email">
          <p class="signin-error" v-if="emailErrors">{{emailErrors}}</p>
          <br>
          <button class="signin-btn" type="submit" ref="sendCodeButton" v-bind:disabled="sending">
            <span v-if="sending" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span>{{sending?'&nbsp;&nbsp;&nbsp;&nbsp;Sending':'Send Code'}}</span>
          </button>
        </form>
        <!-- <p class="signin-tips">Find a trustworthy partner<br>simplify the work<br>secure your return</p> -->
      </div>
      <div class="signin-container" v-else>
        <p class="signin-title">Enter your Security Code</p>
        <p class="signin-subTitle">The security code will be sent to your Email</p>
        <CodeInput class="signin-code-input" :code="code" @complete="codeComplete" />
        <p class="signin-error code-error" v-if="codeErrors">{{codeErrors}}</p>
        <button class="signin-btn" type="submit" @click="submitAction">Submit</button>
        <br>
        <button class="signin-resend">Resend security code</button>
      </div>
    </CustomModal>
  </div>
</template>
<script>
import CustomModal from '../components/common/CustomModal.vue'
import CodeInput from '../components/common/CodeInput.vue'
// import SwitchLang from '../components/common/SwitchLang.vue'
import {PersonalUserApi, userProfileValidator} from "../utils/index";
export default {
  name: "HeaderComponent",
  data() {
    return {
      signinModalVisible: false,
      email: null,
      emailErrors: null,
      hadSendCode: false,
      code: '',
      codeErrors: null,
      sending: false,
      authed: false
    }
  },
  components: {CustomModal, CodeInput},
  mounted() {
    // this.identity =  this.$router.currentRoute.value.name === 'designer-front-door' ? 'Designer' : 'Candidate'
    // if (this.$route.query.id) {
    //   this.identity = 'Candidate'
    // }
    // const identity = this.mnx_isUserAuthenticated()
    this.authed = this.mnx_isUserAuthenticated()
  },
  computed: {},
  methods: {
    gotoFrontDoor() {
      if (this.authed) {
        this.mnx_navAfterSignedin()
      } else {
        this.mnx_navToFrontDoor()
      }
    },
    signinAction() {
      this.email = null
      this.emailErrors = null
      this.hadSendCode = false
      this.code = ''
      this.codeErrors = null
      this.signinModalVisible = true
    },
    codeComplete (e) {
      if (e) {
        this.code = e?.join('')
      }
    },
    sendCodeToEmail() {
      this.emailErrors = null;
      if (this.email === null || this.email.length < 1) {
        this.emailErrors = "Please type in your email";
        return;
      }

      this.emailErrors = userProfileValidator.emailValidator.validate(this.email);

      if (this.emailErrors != null) return;

      this.sending = true;

      PersonalUserApi.sendCodeToEmail(this.email).then((response) => {
        response;
        this.sending = false;
        this.hadSendCode = true
      }).catch((error) => {
        this.sending = false;
        if (error.response)
          this.emailErrors = error.response.data.text;
        else this.emailErrors = "Failed to proceed";
      })
    },
    submitAction() {
      this.codeErrors = null
      if (!this.code) {
        this.codeErrors = "Please type in your code";
        return;
      }
      this.codeErrors = userProfileValidator.authCodeValidator.validate(this.code);
      if (this.codeErrors != null) return;

      PersonalUserApi.signinWithEmailAndCode(this.email, this.code).then((response) => {
        this.mnx_userSignedin(response.data, true);
      }).catch((error) => {
        if (error.response && error.response.status == 401) {
          this.codeErrors = "invalid code";
        } else {
          console.log(error);
          this.codeErrors = "something is wrong";
        }
      })
      
    }
  },
};
</script>

<style scoped lang="scss">
.navigator-container-background {
    @include container-background;
    background-color: #0032FD;

    .navigator-container-content {
        @include container-content;
        display: flex;
        padding: 15px 0;
        align-items: center;
        color: white;
        height: 77px;
        border-bottom: 0;

        .logo { height: 32px }
        .btn {margin-left: 32px;}
        .btn-space {width: 1px; height: 32px; border-left: 1px solid #AFAFAF; margin-left: 32px; display: none;}
        .btn-link {color: white;}
        .btn-primary {background-color: white; color: black; border-color: white;}
    }

    &.isBusiness { background-color: white;
      .navigator-container-content { color: black;
        .btn-space {display: block;}
        .btn-link {color: black;}
        .sign-in {color: #0032FD;}
        .btn-primary {background-color: #0032FD; color: white; border-color: #0032FD;}
      }
    }
}
.signin-container {padding: 57px 20px 20px 20px; text-align: center;
  .signin-title {font-size: 32px; line-height: 41px; margin-bottom: 30px; font-weight: 500; color: black;}
  .signin-input {background-color: white; border: none; box-shadow: 0px 4px 4px rgba(208, 208, 208, 0.25); border-radius: 5px; height: 37px; color: black; font-size: 16px; margin-bottom: 22px; padding: 9px 12px;
    &::placeholder {color: #585858;}
    &.big {width: 380px;}
  }
  .signin-btn {color: white; background-color: #1946FC; border: none; padding: 9px 48px; font-size: 14px; cursor: pointer; box-shadow: none; border-radius: 8px;
    &:hover {opacity: .78;}
    &:disabled {opacity: .78; cursor: not-allowed;}
  }
  .signin-resend {color: #1946FC; background-color: transparent; border: none; padding: 0; box-shadow: none; font-size: 14px; cursor: pointer; margin-top: 13px;
    &:hover {opacity: .78;}
  }
  .signin-subTitle {color: #000; font-size: 12px; margin-top: -20px 0 24px 0; line-height: 15px;}
  .signin-tips {color: #585858; font-size: 12px; margin-top: 59px; line-height: 15px;}
  .signin-error {color: #E52D6F; font-size: 12px; margin: 0; line-height: 1;
    &.code-error {margin: -20px 0 29px 0;}
  }
  .signin-code-input {margin-bottom: 29px;}
}
.radio-container {display: flex; align-items: center; padding-left: 88px;
  .form-check {margin-right: 20px; font-weight: 700; margin-bottom: 30px;}
}
</style>