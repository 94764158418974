<template>
  <div class="container-background">
    <div class="container-content">
      <div class="content-header-container">
        <p class="content-header-title">Coding questions</p>
        <!-- - Please fill all fields before submit <br />- Double check to make sure clear discription -->
      </div>
      <form @submit.prevent="submitForm">
        <div class="lf-body-container">
          <div class="lf-body-content">
            <div class="lf-body-item">
              <h5 class="lf-body-item-label" for="title">TITLE:</h5>
              <p class="lf-body-item-label-note">A phrase or sentence about the problem is. (16~256 words)</p>
              <input type="text" class="lf-body-item-text-input" id="title" placeholder="Examples: Binary Search." v-model.trim="interviewQuestion.title" />
            </div>
            <div class="lf-body-item">
              <h5 class="lf-body-item-label" for="description">DESCRIPTION:</h5>
              <p class="lf-body-item-label-note">The description of this interview question. (128~4096 characters)</p>
              <textarea class="lf-body-item-rich-text full-width min186" id="description" :placeholder="placeholderDescription" v-model.trim="interviewQuestion.description" @keyup="textAreaAdjust($event)" />
            </div>
            <div class="lf-body-item">
              <h5 class="lf-body-item-label" for="inputFormat">INPUT FORMAT(input arrives from the terminal / stdin):</h5>
              <p class="lf-body-item-label-note">Input format description. (16~4096 words)</p>
              <textarea class="lf-body-item-rich-text full-width min186" id="inputFormat" placeholder="Provide input format." v-model.trim="interviewQuestion.inputFormat" @keyup="textAreaAdjust($event)" />
            </div>
            <div class="lf-body-item">
              <h5 class="lf-body-item-label" for="outputFormat">OUTPUT FORMAT(print output to the terminal / stdout):</h5>
              <p class="lf-body-item-label-note">Output format description. (16~4096 words)</p>
              <textarea class="lf-body-item-rich-text full-width min186" id="outputFormat" placeholder="Provide output format." v-model.trim="interviewQuestion.outputFormat" @keyup="textAreaAdjust($event)" />
            </div>
            <div class="lf-body-item">
              <h5 class="lf-body-item-label" for="sampleInput">SAMPLE INPUT:</h5>
              <p class="lf-body-item-label-note">Sample Input description. (16~4096 words)</p>
              <textarea class="lf-body-item-rich-text full-width min186" id="sampleInput" placeholder="Provide sample input." v-model.trim="interviewQuestion.sampleInput" @keyup="textAreaAdjust($event)" />
            </div>
            <div class="lf-body-item">
              <h5 class="lf-body-item-label" for="sampleOutput">SAMPLE OUTPUT:</h5>
              <p class="lf-body-item-label-note">Output description. (16~4096 words)</p>
              <textarea class="lf-body-item-rich-text full-width min186" id="sampleOutput" placeholder="Provide output sample." v-model.trim="interviewQuestion.sampleOutput" @keyup="textAreaAdjust($event)" />
            </div>
            <div class="lf-body-item">
              <h5 class="lf-body-item-label" for="scoring">SCORING:</h5>
              <p class="lf-body-item-label-note">How to score. (16~4096 words)</p>
              <textarea class="lf-body-item-rich-text full-width min186" id="scoring" placeholder="Provide scoring philosophy." v-model.trim="interviewQuestion.scoring" @keyup="textAreaAdjust($event.target)" />
            </div>
            <div class="lf-body-item">
              <h5 class="lf-body-item-label" for="notes">NOTES:</h5>
              <p class="lf-body-item-label-note">Notes to programmer. (16~4096 words)</p>
              <textarea class="lf-body-item-rich-text full-width min186" id="notes" placeholder="Notes to the programmer." v-model.trim="interviewQuestion.notes" @keyup="textAreaAdjust($event.target)" />
            </div>
            <div class="lf-body-item">
              <h5 class="lf-body-item-label" for="difficulty">DIFFICULTY:</h5>
              <p class="lf-body-item-label-note">The difficulty level of the problem. (0-3)</p>
              <select class="lf-body-item-select-input full-width" name="difficulty" id="difficulty" v-model="interviewQuestion.difficultyLevel">
                <option value="0">Easy</option>
                <option value="1">Medium</option>
                <option value="2">Hard</option>
                <option value="3">Champion</option>
              </select>
            </div>
          </div>
        </div>
        <div class="lf-body-container">
          <div class="lf-body-item">
            <h5 class="lf-body-item-label" for="scoring">TEST CASES:</h5>
            <p class="lf-body-item-label-note">Upload a {{acceptableSolutionFileType}} file containing test cases</p>
            <div class="lf-body-item-file full-width">
              <p>{{ getTestcaseFilename() }}</p>
              <button class="if-cancel" type="button" aria-label="testCasesFile" aria-describedby="test-cases-file" @click="selectTestCasesFile">Select</button>
              <input ref="fileInput" type="file" :accept="acceptableSolutionFileType" style="display: none" @change="onChange($event)" />
            </div>
          </div>
        </div>
        <p v-show="hasError()" class="if-errorInput">{{ errorMessage }}</p>
        <div class="lf-submit-container">
          <button class="if-cancel" ref="cancelButton" type="button" @click="goBack">Cancel</button>
          <button class="if-submit" ref="submitButton" type="submit">Submit</button>
        </div>
      </form>
    </div>
  </div>
  <!-- <div class="comp-body">
    <form @submit.prevent="submitForm">
      <div class="lf-head-container">
        <div class="lf-head-left">
          <h3 class="lf-head-title">Coding Problem</h3>
          <h6 class="lf-head-subtitle">Edit a coding problem</h6>
        </div>
        <div class="lf-head-right">
          <p class="lf-head-notes">
            - Please fill all fields before submit
            <br />- Double check to make sure clear discription
          </p>
        </div>
      </div>
      <div class="lf-body-container">
        <div class="lf-body-content">
          <div class="lf-body-item">
            <h5 class="lf-body-item-label" for="difficulty">DIFFICULTY:</h5>
            <p class="lf-body-item-label-note">
              The difficulty level of the problem. (0-3)
            </p>
            <select name="difficulty" id="difficulty" v-model="interviewQuestion.difficultyLevel">
              <option value="0">Easy</option>
              <option value="1">Medium</option>
              <option value="2">Hard</option>
              <option value="3">Champion</option>
            </select>
          </div>
          <div class="lf-body-item">
            <h5 class="lf-body-item-label" for="testCases">TEST CASES:</h5>
            <p class="lf-body-item-label-note">
              Upload a zip file containing test cases
            </p>
            <p class="lf-body-item-block-text-clickable text-center" aria-label="testCasesFile"
              aria-describedby="test-cases-file" @click="selectTestCasesFile">{{ getTestcaseFilename() }}</p>
            <input ref="fileInput" type="file" accept=".zip" style="display: none" @change="onChange($event)" />
          </div>
        </div>
      </div>
      <p v-show="hasError()" class="if-errorInput">{{ errorMessage }}</p>
      <div class="lf-submit-container">
        <button class="if-cancel" ref="cancelButton" type="button" @click="goBack">Cancel</button>
        <button class="if-submit" ref="submitButton" type="submit">Submit</button>
      </div>
    </form>
  </div> -->
</template>

<script>
import {
  DesignerApi,
  interviewProblemUtils,
  problemValidator,
  textAreaAujuster,
  uploadFileLengthValidator,
} from "../../utils/index";
import { interviewQuestionStruct } from "../../types/index";
export default {
  name: "DesignerEditProblem",
  props: {},

  data() {
    return {
      interviewQuestion: interviewProblemUtils.fetch() ? interviewProblemUtils.fetch() : interviewQuestionStruct,
      errorMessage: null,
      placeholderDescription: "",
      interviewQuestionId: interviewProblemUtils.fetchId() ? interviewProblemUtils.fetchId() : null,
      acceptableSolutionFileType: '.CPP,.JAVA,.PY'
    };
  },

  created() { },
  components: {},
  mounted() { },
  updated() {
    // this.$el.querySelectorAll('textarea').forEach(element => {
    //   this.textAreaAdjust(element)
    // });
  },

  methods: {
    textAreaAdjust(element) {
      textAreaAujuster.adjustHight(element);
    },
    getTestcaseFilename() {
      return (this.interviewQuestion != null && this.interviewQuestion.testCaseFilename != null)
        ?
        this.interviewQuestion.testCaseFilename
        :
        "Select a zip file";
    },
    goBack() {
      this.mnx_goBack();
    },
    hasError() {
      return this.errorMessage;
    },

    selectTestCasesFile() {
      this.$refs.fileInput.click();
    },

    onChange(event) {
      let file = event.target.files[0];
      let reader = new FileReader();
      reader.addEventListener(
        "load",
        function () {
          this.interviewQuestion.testCases = reader.result;
          this.interviewQuestion.testCaseFilename = file.name;
          this.interviewQuestion.testCaseFilelength = file.size;
        }.bind(this),
        false
      );
      reader.readAsDataURL(file);
    },

    async submitForm() {
      console.log('submitForm')
      this.errorMessage = problemValidator.problemTitleValidator.validate(this.interviewQuestion.title);
      console.log('errorMessage', this.errorMessage)
      if (this.hasError()) {
        return;
      }

      this.errorMessage = problemValidator.problemDescriptionValidator.validate(
        this.interviewQuestion.description
      );
      if (this.hasError()) {
        return;
      }

      this.errorMessage = problemValidator.problemInputFormatValidator.validate(
        this.interviewQuestion.inputFormat
      );
      if (this.hasError()) {
        return;
      }
      this.errorMessage =
        problemValidator.problemOutputFormatValidator.validate(
          this.interviewQuestion.outputFormat
        );
      if (this.hasError()) {
        return;
      }
      this.errorMessage = problemValidator.problemSampleInputValidator.validate(
        this.interviewQuestion.sampleInput
      );
      if (this.hasError()) {
        return;
      }
      this.errorMessage =
        problemValidator.problemSampleOutputValidator.validate(
          this.interviewQuestion.sampleOutput
        );
      if (this.hasError()) {
        return;
      }
      this.errorMessage = problemValidator.problemScoringValidator.validate(
        this.interviewQuestion.scoring
      );
      if (this.hasError()) {
        return;
      }
      this.errorMessage = uploadFileLengthValidator.validate(this.interviewQuestion.testCaseFilelength);
      if (this.hasError()) {
        return;
      }

      interviewProblemUtils.fill(this.interviewQuestion);
      this.$refs.cancelButton.disabled = true;
      this.$refs.submitButton.disabled = true;
      DesignerApi.submitProblem(
        this.interviewQuestionId,
        this.interviewQuestion
      )
        .then((response) => {
          this.interviewQuestionId = response.data.problemId;
          interviewProblemUtils.fillId(this.interviewQuestionId);

          this.$refs.cancelButton.disabled = false;
          this.$refs.submitButton.disabled = false;
          this.mnx_navToDesignerProblemSubmitted();
        })
        .catch((error) => {

          this.$refs.cancelButton.disabled = false;
          this.$refs.submitButton.disabled = false;
          this.mnx_backendErrorHandler(error);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.container-background {
  @include container-background;

  .container-content {
    @include container-content;
    padding: 45px 0;
  }
}
.full-width {max-width: 100% !important;}
.min186 {min-height: 186px !important;}
</style>
