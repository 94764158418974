<template>
  <div class="main-body">
    <div class="story-board">
      <div class="focus-area">
        <p class="callout">Sign in with the code sent to your email.</p>
        <div class="form-group">
          <form @submit.prevent="submitForm">
            <input class="form-group-item" type="text" v-model.trim="code" />
            <button class="form-group-item btn btn-primary" type="submit">
              Submit
            </button>
            <p class="form-group-item errorInput border-0" v-if="hasInvalidInput()">
              {{ error }}
            </p>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BusinessUserApi, userProfileValidator } from "../../utils/index";
export default {
  name: "BusinessSigninWithEmailCode",
  props: {
    email: {
      required: true,
      type: String,
    },
  },
  components: {},

  data() {
    return {
      code: null,
      error: null,
    };
  },

  created() { },
  mounted() { },
  methods: {
    hasInvalidInput() {
      return this.error != null;
    },

    async submitForm() {
      this.message = null;
      if (this.email === null || this.email.length < 1) {
        this.message = "Something is wrong. Please refresh the page and try again.";
        return;
      }

      this.message = userProfileValidator.authCodeValidator.validate(this.code);

      if (this.message != null) return;

      BusinessUserApi.signinWithEmailAndCode(this.email, this.code)
        .then((response) => {
          this.mnx_userSignedin(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.status == 401) {
            this.message = "invalid code";
          } else {
            console.log(error);
            this.message = "something is wrong";
          }
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
form {
  margin: 1rem;
  padding: 1rem;
}
</style>
