const interviewStatusStatusEnum = Object.freeze({
    DISABLE: 0,
    OPEN: 1,
    ONGOING: 2,
    COMPLETED: 3,
    CANCELLED: 4,
});
const noteToInterviewee ="\
You will need coding environment to finish the interview. Please make sure you have set it up.\r\n\
You need submit a single file containing the code.\r\n\
You can submit for unlimited times before the time is up, and we will take the last one to score.\r\n\
The timer will kick off once you click the start button.\r\n\
";
const codingInterviewStruct = {
    status: 0,
    createdTime: null,
    deadline: null,
    allowedDuration: null,
    jobTitle: null,
    jobPosition: null,
    noteToInterviewee: noteToInterviewee,
    startTime: null,
    completeTime: null,
    applicant: null,
    applicantFullname: null,
    interviewQuestion: null,
    desiredProgramingLanguage: 'ANY',
    difficultyLevel: 0,
    createdBy: null,
    business: null,
    businessName: null,
    solutionSubmission: null,
}

class CodingInterviewStructUtils {
    getStatusText(status) {
        switch (status) {
            case 0:
                return "DISABLED";
            case 1:
                return "OPEN";
            case 2:
                return "ONGOING";
            case 3:
                return "COMPLETE";
            default:
                return "UNKNOWN";
        }
    }
}

const codingInterviewStructUtils = new CodingInterviewStructUtils();

export { codingInterviewStruct, interviewStatusStatusEnum, codingInterviewStructUtils }