import { store } from "../../plugins/index";

class BusinessUserUtils {
    constructor(store) {
        this.store = store;
    }

    fill(businessUser) {
        this.businessUser = businessUser;
    }

    fetch() {
        return this.businessUser;
    }
    
    fillBusinessId(id) {
        this.business_id = id;
    }

    fetchBusinessId() {
        return this.business_id;
    }
}

const businessUserUtils = new BusinessUserUtils(store);

export { businessUserUtils };