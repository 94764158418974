import { FilenameValidator } from "./filenameValidator"
import {TextFileValidator} from "./textFileValidator"

class SolutionSubmissionValidator {
    constructor() {
        this.filenameValidator = new FilenameValidator();
        this.textFileValidator = new TextFileValidator();
    }
}

const solutionSubmissionValidator = new SolutionSubmissionValidator();

export { solutionSubmissionValidator }
