import { createApp } from 'vue'
import Toast from './index.vue'
let toastInstance = null

const createToast = (options) => {
  if (toastInstance) {
    toastInstance.message = options.message;
    toastInstance.duration = options.duration || 1500;
    toastInstance.visible = true;
  } else {
    const app = createApp(Toast)
    const mount = document.createElement('div')
    toastInstance = app.mount(mount)
    toastInstance.message = options.message;
    toastInstance.duration = options.duration || 1500;
    toastInstance.visible = true;
    document.body.appendChild(mount)
  }
}

export default {
  install(app) {
    app.config.globalProperties.$toast = {
      show: (message, duration) => createToast({message, duration}),
    }
  }
}