<template>
    <div class="report-board-container">
        <div class="report-board-header">
            <p class="report-board-title">Job Position</p>
        </div>
        <job-position-viewer v-model="job"></job-position-viewer>
        <button @click="goBack()">Back</button>
    </div>
</template>

<script>
import JobPositionViewer from "../../components/viewers/JobPositionViewer"
import {
    PersonalUserApi,
} from "../../utils/index";
export default {
    name: "PersonalViewPosition",
    props: {
        jobId: {
            required: true,
            type: String,
        },
    },

    data() {
        return {
            job: null,
        };
    },

    components: { JobPositionViewer },

    created() { },
    mounted() {
        this.fetchJob();
    },
    methods: {
        goBack() {
            this.mnx_goBack();
        },
        fetchJob() {
            PersonalUserApi.fetchJob(this.jobId)
                .then((response) => {
                    this.job = response.data.job;
                })
                .catch((error) => {
                    this.mnx_backendErrorHandler(error);
                });
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
