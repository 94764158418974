<template>
  <div class="container-background">
    <div class="container-content">
      <div class="intro-container">
        <p class="intro-title text-bold">The Leading platform to <br><span>arrange and conduct</span><br> coding interviews.</p>
        <p class="intro-subtitle">Minimize business cost and optimize the developer recruiting process.</p>
        <!-- <button type="button" class="btn btn-primary text-bold intro-btn" @click="gotoCodingInterviewHome">Schedule Interview For Free</button> -->
      </div>
      <div class="card-container">
        <div class="row row-cols-md-3 row-cols-3 row-cols-sm-1 g-6">
          <div class="col">
            <div class="card-item-container">
              <img class="logo" alt="freeleaps EasyArrangement" src="@/assets/EasyArrangement.png" />
              <p class="card-title text-bold">Easy Arrangement</p>
              <p class="card-subtitle">All you need is a a few clicks.</p>
            </div>
          </div>
          <div class="col">
            <div class="card-item-container">
              <img class="logo" alt="freeleaps EffectiveEvaluation" src="@/assets/EffectiveEvaluation.png" />
              <p class="card-title text-bold">Effective Evaluation</p>
              <p class="card-subtitle">The report is easy to read and reflects well candidate's coding skills.</p>
            </div>
          </div>
          <div class="col">
            <div class="card-item-container">
              <img class="logo" alt="freeleaps SimpleandReliable" src="@/assets/SimpleandReliable.png" />
              <p class="card-title text-bold">Unique Questions</p>
              <p class="card-subtitle">It is a true test of candidate's coding capabilities.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BusinessHomePage",
  props: {},
  data() {
    return { }
  },
  components: {},
  created() {},
  mounted() { },
  methods: {
    gotoCodingInterviewHome() {
      this.mnx_navToBusinessCodingInterviewHome();
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.container-background {
  @include container-background;
  background-color: white;

  .container-content {
    @include container-content;

    .intro-container {padding: 108px 0;
      .intro-title {font-size: 48px; color: black; line-height: 56px; margin-bottom: 16px;
        span {color: #0032FD;}
      }
      .intro-subtitle {font-size: 20px; line-height: 26px; color: #777; margin-bottom: 40px;}
      .intro-btn {background-color: #E52D6F; border-color: #E52D6F; font-size: 24px;}
    }
    .card-container {padding: 58px 0;
      .card-item-container {background-color: #F1F4FE; border-radius: 8px; padding: 54px 66px; text-align: center; min-height: 270px;
        img {width: 48px; margin-bottom: 32px;}
        .card-title {font-size: 20px; color: black; margin-bottom: 8px}
        .card-subtitle {font-size: 16px; color: #777; margin-bottom: 0;}
      }
    }
  }
}
</style>
