import { backendAxios } from './axios'
import { userRoleEnum } from "../../types/index";
import { userUtils } from '../store/index'
class AdminApi {
    static sendCodeToEmail(email) {
        const request = backendAxios.post(
            '/api/common/send-code-to-email',
            {
                email: email,
                role: userRoleEnum.ADMIN,
            },
            {
            }
        );
        return request;
    }

    static signinWithEmailAndCode(email, code) {
        const request = backendAxios.post(
            '/api/admin/signin-by-email-code',
            {
                code: code,
                email: email,
                role: userRoleEnum.ADMIN,
            },
            {
            }
        );
        return request;
    }

    static queryAdmins() {
        let jwt = userUtils.getJwtToken();
        const request = backendAxios.post(
            '/api/admin/query-admins',
            {
                role: userRoleEnum.ADMIN,
            },
            {
                headers: { Authorization: `Bearer ${jwt}` }
            }
        );
        return request;
    }

    static queryBusinesses() {
        let jwt = userUtils.getJwtToken();
        const request = backendAxios.post(
            '/api/admin/query-businesses',
            {
                role: userRoleEnum.ADMIN,
            },
            {
                headers: { Authorization: `Bearer ${jwt}` }
            }
        );
        return request;
    }
    static queryDesigners() {
        let jwt = userUtils.getJwtToken();
        const request = backendAxios.post(
            '/api/admin/query-designers',
            {
                role: userRoleEnum.ADMIN,
            },
            {
                headers: { Authorization: `Bearer ${jwt}` }
            }
        );
        return request;
    }
    static queryPersonals() {
        let jwt = userUtils.getJwtToken();
        const request = backendAxios.post(
            '/api/admin/query-personals',
            {
                role: userRoleEnum.ADMIN,
            },
            {
                headers: { Authorization: `Bearer ${jwt}` }
            }
        );
        return request;
    }

    static queryEveluationTasks() {
        let jwt = userUtils.getJwtToken();
        const request = backendAxios.post(
            '/api/admin/query-eveluation-tasks',
            {
                role: userRoleEnum.ADMIN,
            },
            {
                headers: { Authorization: `Bearer ${jwt}` }
            }
        );
        return request;
    }

}

export {AdminApi}