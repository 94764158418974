<template>
  <div class="container-background">
    <div class="container-content">
      <div class="content-header-container">
        <p class="content-header-title">In progress interviews</p>
        <div class="flex-space"></div>
        <div class="content-search">
          <input type="text" placeholder="search..." v-model="pagination.keyword">
          <img src="@/assets/search.png" alt="search" class="search-icon" @click="searchAction">
        </div>
        <button class="content-btn" @click="gotoNewCodingInterview">Creat Interview</button>
      </div>
      <div class="list-container">
        <template v-if="interviewList.length > 0">
          <div class="content-item-container" v-for="(interview, index) in interviewList" :key="index">
            <p class="item-title item-bottom">{{ interview.jobTitle }}</p>
            <p class="item-content item-bottom">{{ interview.applicantFullname }}</p>
            <p class="item-light">{{ getCompleteTime(interview) }}</p>
            <!-- <div class="item-operation-container item-top">
              <span @click="viewReport(interview)">View Report</span>
            </div> -->
          </div>
        </template>
        <div class="empty-container" v-if="interviewList.length == 0">
          <img src="@/assets/logo-pic.png" alt="magic leaps logo">
          <p class="opps">Oops !</p>
          <p class="opps-sub">There is nothing here.</p>
        </div>
        <div class="spinner-container" v-if="loading">
          <div class="spinner-grow text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
      <CustomPagination :total="pagination.total" v-model:current="pagination.current" @pageChange="pageChange" />
    </div>
  </div>
</template>

<script>
import { BusinessUserApi, DateUtils, codingInterviewUtils } from "../../utils/index";
import { interviewStatusStatusEnum } from "../../types/index";
export default {
  name: "BusinessOngoingCodingInterview",
  props: {},
  data() {
    return {
      pagination: {
        total: 0,
        current: 1,
        keyword: ''
      },
      interviewList: [],
      loading: false
    };
  },
  components: {},
  created() {},
  mounted() {
    this.queryInterview();
  },
  methods: {
    queryInterview() {
      BusinessUserApi.QueryCodingInterviews([interviewStatusStatusEnum.OPEN, interviewStatusStatusEnum.ONGOING], this.pagination.keyword).then((response) => {
        this.interviewList = response.data;
        this.interviewList.forEach((interivew) => {
          interivew.deadline = DateUtils.FromJsonToDatetimeString(
            interivew.deadline
          );
        });
      }).catch((error) => {
        this.mnx_backendErrorHandler(error);
      });
    },
    searchAction() {
      this.pagination.total = 0
      this.pagination.current = 1
      this.queryInterview()
    },
    getCompleteTime(interview) {
      return DateUtils.FromJsonToDateString(interview.completeTime);
    },
    gotoNewCodingInterview() {
      codingInterviewUtils.fill(null);
      codingInterviewUtils.fillId(null);
      this.mnx_navToBusinessEditCodingInterview();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.container-background {
  @include container-background;

  .container-content {
    @include container-content;
    padding: 45px 0;
  }
}
</style>
