import { TextValidator } from './textValidator'
const formatMessage = "less then 8192 characters";
const pattern = /\p{L}/u;
const minLength = 128;
const maxLength = 8192;

class JobDescriptionValidator extends TextValidator {
    constructor() {
        super(
            "Description",
            formatMessage,
            [
                {
                    regex: pattern,
                    message: " contains invalid charactors."
                }
            ],
            minLength,
            maxLength
        );
    }

}

export { JobDescriptionValidator }
