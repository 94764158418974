<template>
  <div class="container-background">
    <div class="container-content">
      <div class="content-header-container">
        <p class="content-header-title">My Profile</p>
      </div>
      <form @submit.prevent="submitForm">
        <div class="lf-body-container">
          <div class="lf-body-content">
            <div class="lf-body-item">
              <h5 class="lf-body-item-label" for="Firstname-input">First name</h5>
              <input type="text" class="lf-body-item-text-input max700" aria-label="Firstname" aria-describedby="business-Firstname-input" id="Firstname" placeholder="Enter your firstname" v-model.trim="designerUser.firstname" />
            </div>
            <div class="lf-body-item">
              <h5 class="lf-body-item-label" for="Lastname-input">Last name</h5>
              <input type="text" class="lf-body-item-text-input max700" aria-label="Lastname" aria-describedby="business-Lastname-input" id="Lastname" placeholder="Enter your lastname" v-model.trim="designerUser.lastname" />
            </div>
            <div class="lf-body-item">
              <h5 class="lf-body-item-label" for="Email-input">Email</h5>
              <input type="text" class="lf-body-item-text-input max700" aria-label="Email" aria-describedby="business-Email-input" id="Email" placeholder="Enter your email" v-model.trim="designerUser.email" />
            </div>
            <div class="lf-body-container" v-if="oldEmail !== designerUser.email">
              <div class="lf-body-item">
                <h5 class="lf-body-item-label" for="Code">Code</h5>
                <div class="lf-body-item-file">
                  <input type="text" class="lf-body-item-text-input me-5" aria-label="code" aria-describedby="business-code-input" id="code" placeholder="Enter your email code" v-model.trim="code" />
                  <button class="if-cancel" type="button" @click="sendCode">Send code</button>
                </div>
              </div>
            </div>
            <p v-show="errorMessage" class="if-errorInput">{{ errorMessage }}</p>
            <div class="lf-submit-container">
              <button class="if-cancel" type="button" @click="goBack">Cancel</button>
              <button class="if-submit" type="submit">Submit</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { DesignerApi, UserAuthApi, designerUserUtils, userProfileValidator } from "../../utils/index";
import { designerUserStruct } from "../../types/index";
export default {
  name: "DesignerProfile",
  props: {},

  data() {
    return {
      designerUser: designerUserStruct,
      errorMessage: null,
      oldEmail: null,
      code: ''
    };
  },

  components: {},

  created() { },
  mounted() {
    this.fetchDesignerUser();
  },
  methods: {
    sendCode() {
      this.errorMessage = userProfileValidator.emailValidator.validate(this.designerUser.email);
      if (this.errorMessage) return;
      UserAuthApi.sendAuthCodeToEmail(this.designerUser.email).then(() => {}).catch((error) => this.mnx_backendErrorHandler(error))
    },

    submitForm() {
      this.errorMessage = null;
      this.errorMessage = userProfileValidator.nameValidator.validate(this.designerUser.firstname);
      if (this.errorMessage) return;
      this.errorMessage = userProfileValidator.nameValidator.validate(this.designerUser.lastname);
      if (this.errorMessage) return;

      if (this.oldEmail !== this.designerUser.email) {
        this.errorMessage = userProfileValidator.emailValidator.validate(this.designerUser.email);
        if (this.errorMessage) return;
        this.errorMessage = userProfileValidator.authCodeValidator.validate(this.code);
        if (this.errorMessage) return;
      }

      DesignerApi.updateFullname(this.designerUser.firstname, this.designerUser.lastname).then(() => {
        if (this.oldEmail !== this.designerUser.email) {
          DesignerApi.updateEmail(this.designerUser.email, this.code).then(() => {
            this.mnx_navToDesignerProfileUpdated()
          }).catch((error) => {
            this.mnx_backendErrorHandler(error);
          });
        } else {
          this.mnx_navToDesignerProfileUpdated()
        }
      }).catch((error) => {
        this.mnx_backendErrorHandler(error);
      });
    },

    fetchDesignerUser() {
      DesignerApi.fetchProfile().then((response) => {
        this.designerUser = response.data;
        designerUserUtils.fill(this.designerUser);
        this.oldEmail = this.designerUser.email;
      }).catch((error) => {
        this.mnx_backendErrorHandler(error);
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.container-background {
  @include container-background;

  .container-content {
    @include container-content;
    padding: 45px 0;
  }
}
</style>
