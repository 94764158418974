<template>
  <div class="container-background">
    <div class="container-content">
      <div class="resource-top-container">
        <div class="resource-left-container">
          <div class="resource-left-box">
            <div class="resource-left-title">Resources</div>
            <div class="resource-left-subtitle">From magicleaps</div>
          </div>
        </div>
        <img src="@/assets/resources.jpeg" alt="From magicleaps resources">
      </div>
      <div class="resource-item-container">
        <ul class="resource-item">
          <li><p>Links</p></li>
          <li><a href="#">Topic or links</a></li>
          <li><a href="#">Topic or links</a></li>
          <li><a href="#">Topic or links</a></li>
          <li><a href="#">Topic or links</a></li>
          <li><a href="#">Topic or links</a></li>
        </ul>
        <ul class="resource-item">
          <li><p>Tools</p></li>
          <li><a href="#">Topic or links</a></li>
          <li><a href="#">Topic or links</a></li>
          <li><a href="#">Topic or links</a></li>
          <li><a href="#">Topic or links</a></li>
          <li><a href="#">Topic or links</a></li>
        </ul>
        <ul class="resource-item">
          <li><p>Guidelines</p></li>
          <li><a href="#">Topic or links</a></li>
          <li><a href="#">Topic or links</a></li>
          <li><a href="#">Topic or links</a></li>
          <li><a href="#">Topic or links</a></li>
          <li><a href="#">Topic or links</a></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BusinessResources",
  props: {},
  data() {
    return {};
  },
  components: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.container-background {
  @include container-background;
  background-color: white;

  .container-content {
    @include container-content;
    padding: 140px 100px;
    
    .resource-top-container {margin-bottom: 80px; display: flex;
      img {width: 544px; height: 362px;}
      .resource-left-container {padding: 20px; display: flex; flex: 1; justify-content: center; align-items: center;
        .resource-left-box {width: fit-content; height: fit-content;
          .resource-left-title {font-size: 64px; line-height: 83px; font-weight: 700; color: black; margin-bottom: 0;}
          .resource-left-subtitle {font-size: 36px; line-height: 48px; font-weight: 700; color: #585858; margin-bottom: 0;}
        }
      }
    }
    .resource-item-container {display: flex; justify-content: space-around;
      .resource-item {width: 253px; list-style: none; text-align: left;
        p {font-size: 28px; line-height: 36px; font-weight: 700; color: black; padding-bottom: 8px; border-bottom: 1px solid #585858; margin-bottom: 12px;}
        a {font-size: 16px; line-height: 21px; margin-bottom: 12px; text-decoration: none; color: #585858;
          &:hover {opacity: .78;}
        }
      }
    }
  }
}
</style>
