<template>
    <!-- <div class="story-board">
        <h1 class="headText1">Contact Us</h1>
        <div class="focus-area">
            <p class="contact-text">Email: admin@freeleaps.com</p>
            <p class="contact-text">Address: 23286 NE 16TH PL SAMMAMISH WA 98074</p>
        </div>
        <button class="form-cancel" type="button" @click="goBack">Back</button>
    </div> -->
  <div class="container-background">
    <div class="container-content">
        <div class="content-container">
            <div class="left-container">
                <p class="left-title">Contact us</p>
                <p class="left-subTitle">
                    Email: support@freeleaps.com
                    <!-- <br>
                    Address: 23286 NE 16TH PL SAMMAMISH WA 98074 -->
                </p>
            </div>
            <img class="right-container" alt="freeleaps" src="@/assets/contact.jpeg" />
        </div>
    </div>
  </div>
</template>
<script>
export default {
    name: "ContactUs",
    components: {},
    computed: {},
    methods: {}
};
</script>

<style scoped lang="scss">
.container-background {
  @include container-background;

  .container-content {
    @include container-content;

    .content-container {padding: 140px 90px 170px 90px; display: flex; // align-items: center;
        .right-container {width: 374px; height: 337px}
        .left-container {padding-left: 40px; flex: 1; text-align: left; height: fit-content;
            .left-title {font-size: 96px; line-height: 125px; font-weight: 500; margin: 0;}
            .left-subTitle {font-size: 20px; line-height: 26px; margin: 0;}
        }
    }
  }
}
</style>