<template>
  <div class="container-background">
    <div class="container-content">
      <div class="viewer-container">
        <p class="viewer-title viewer-main-title">Starting Interview</p>
        <p class="viewer-title">Job</p>
        <div class="viewer-job-container row">
          <div class="col viewer-content-container">
            <span class="viewer-content viewer-content-title">Company</span>
            <span class="viewer-content" @click="viewBusiness()">{{codingInterview?.businessName}}</span>
          </div>
          <div class="col viewer-content-container">
            <span class="viewer-content viewer-content-title">Position</span>
            <span class="viewer-content" @click="viewPosition()">{{codingInterview?.jobTitle}}</span>
          </div>
        </div>
        <p class="viewer-title">Interview</p>
        <div class="row">
            <div class="col">
                <div class="viewer-interview-container row">
                    <div class="col viewer-content-container column">
                        <span class="viewer-content">Due time</span>
                        <span class="viewer-title">{{FormlizeDatetime(codingInterview?.deadline?.$date)?.[1]}}</span>
                        <span class="viewer-content viewer-content-title">{{FormlizeDatetime(codingInterview?.startTime?.$date)?.[0]}}</span>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="viewer-interview-container row">
                    <div class="col viewer-content-container column">
                        <span class="viewer-content">Duration</span>
                        <span class="viewer-title">{{codingInterview?.allowedDuration}}</span>
                        <span class="viewer-content viewer-content-title">Minutes</span>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="viewer-interview-container row">
                    <div class="col viewer-content-container column">
                        <span class="viewer-content">Language</span>
                        <span class="viewer-title">{{codingInterview?.desiredProgramingLanguage}}</span>
                    </div>
                </div>
            </div>
        </div>
        <p class="viewer-title">Note</p>
        <div class="viewer-report-container row">
          <span>{{codingInterview?.noteToInterviewee}}</span>
        </div>
        <div class="lf-submit-container column-submit">
                <button class="if-submit" type="submit" @click="startInterview">Start</button>
                <button class="if-cancel" type="button" @click="goBack">Back</button>
            </div>
      </div>
    </div>
  </div>
    <!-- <div class="report-board-container">
        <div class="report-board-header">
            <p class="report-board-title">Starting Interview</p>
        </div>
        <div class="report-board-body">
            <div class="report-card-container">
                <p class="report-card-title">Job</p>
                <div class="report-item-container">
                    <span class="report-item-name">Company:</span>
                    <span v-if="codingInterview" class="report-item-value_clickable" @click="viewBusiness()">{{
                            codingInterview.businessName
                    }}</span>
                </div>
                <div class="report-item-container">
                    <span class="report-item-name">Position:</span>
                    <span v-if="codingInterview" class="report-item-value_clickable" @click="viewPosition()">{{
                            codingInterview.jobTitle
                    }}</span>
                </div>
            </div>
            <div class="report-card-container">
                <p class="report-card-title">Interview</p>
                <div class="report-item-container">
                    <span class="report-item-name">Deadline:</span>
                    <span v-if="codingInterview" class="report-item-value">{{
                            FormlizeDateTime(codingInterview.deadline)
                    }}</span>
                </div>
                <div class="report-item-container">
                    <span class="report-item-name">Duration:</span>
                    <span v-if="codingInterview"  class="report-item-value">{{
                            codingInterview.allowedDuration
                    }} minutes</span>
                </div>
                <div class="report-item-container">
                    <span class="report-item-name">Language:</span>
                    <span v-if="codingInterview" class="report-item-value">{{
                            codingInterview.desiredProgramingLanguage
                    }}</span>
                </div>
            </div>
            <div class="report-card-container">
                <p class="report-card-title">Note</p>
                <div class="report-item-container">
                    <textarea v-if="codingInterview" class="report-item-display-text" v-model.trim="codingInterview.noteToInterviewee"
                        readonly></textarea>
                </div>
            </div>
        </div>
        <div class="lf-submit-container">
            <button class="if-cancel" type="button" @click="goBack">Back</button>
            <button class="if-submit" type="submit" @click="startInterview">Start</button>
        </div>
    </div> -->
</template>

<script>
import {PersonalUserApi} from "../../utils/index";
import {codingInterviewStruct} from "../../types/index";
import moment from 'moment'
export default {
    name: "PersonalStartInterview",
    props: {
        interviewId: {
            required: true,
            type: String,
        },
    },

    data() {
        return {
            codingInterview: codingInterviewStruct,
        };
    },

    created() { },
    components: {},
    mounted() {
        // this.codingInterview = codingInterviewUtils.fetch();
        this.fetchInterview();
    },

    updated() {
        // this.$el.querySelectorAll('textarea').forEach(element => {
        //     this.textAreaAdjust(element)
        // });
    },

    methods: {
        textAreaAdjust(element) {
            if (element) {
                element.style.height = "1px";
                element.style.height = (25 + element.scrollHeight) + "px";
            }
        },

        goBack() {
            this.mnx_goBack();
        },
        viewBusiness() {
            this.mnx_navToPersonalViewBusinessProfile(this.codingInterview.business);
        },
        viewPosition() {
            this.mnx_navToPersonalViewPosition(this.codingInterview.jobPosition);
        },
        FormlizeDatetime(datetime) {
            if (!datetime) return [];
            return moment(datetime).utc().format('YYYY-MM-DD HH:mm').split(' ');
            // return DateUtils.FromJsonToDateStrings(datetime);
        },
        startInterview(){
            this.mnx_navToPersonalResolveProblem(this.interviewId);
        },
        fetchInterview() {
            PersonalUserApi.fetchCodingInterview(this.interviewId).then((response) => {
                this.codingInterview = response.data.interview;
                // this.solutionSubmission = response.data.submission;
                // codingSubmissionUtils.fill(this.solutionSubmission);
                // codingSubmissionUtils.fillId(OIDUtils.FromJson(this.solutionSubmission));
            }).catch((error) => {
                this.mnx_backendErrorHandler(error);
            });
        },
    },
};
</script>
<style scoped lang="scss">
.container-background {
  @include container-background;

  .container-content {
    @include container-content;
    padding: 50px 0;
    display: flex;
    justify-content: center;
  }
}
.viewer-container {max-width: 922px; width: 100%;
  .viewer-main-title {margin-bottom: 35px;}
  .viewer-title {font-size: 28px; line-height: 36px; font-weight: 700; margin-bottom: 26px; color: #050505; text-align: center;}
  .viewer-content-container {display: flex; align-items: center; justify-content: center;
    .viewer-content {font-size: 16px; line-height: 21px; color: #585858;}
    .viewer-content-title {font-weight: 700; color: #050505; margin-right: 20px;}
    &.column {flex-direction: column;
      .viewer-content {margin-bottom: 18px;}
      .viewer-title {margin-bottom: 5px;}
      .viewer-content-title {margin-bottom: 0; margin-right: 0;}
    }
  }
  .viewer-job-container {min-height: 70px; padding: 10px; margin-bottom: 35px; background-color: white;}
  .viewer-interview-container {min-height: 150px; padding: 10px; margin-bottom: 35px; background-color: white;}
  .viewer-report-container {min-height: 112px; padding: 10px; margin-bottom: 35px; background-color: white;}
}
.column-submit {flex-direction: column; align-items: center;
    button {width: 380px;}
    .if-cancel {margin: 15px 0}
}
</style>
