<template>
  <div class="container-background">
    <div class="container-content">
      <div class="content-header-container">
        <p class="content-header-title">Questions</p>
      </div>
      <form @submit.prevent="submitForm">
        <div class="lf-body-container">
          <div class="lf-body-content">
            <coding-interview-question-viewer v-model="interviewQuestion"></coding-interview-question-viewer>
            <div class="lf-body-item">
              <h5 class="lf-body-item-label" for="programming-language">Programming Language:</h5>
              <select class="lf-body-item-select-input" name="programming-language" id="programming-language" v-model="programingLanguage">
                <option value="CPP">C++</option>
                <option value="JAVA">Java</option>
                <option value="PY">Python</option>
                <option value="ANY">ANY</option>
              </select>
            </div>
            <div class="lf-body-item">
              <h5 class="lf-body-item-label" for="scoring">Solution File:</h5>
              <p class="lf-body-item-label-note">Upload a {{acceptableSolutionFileType}} file containing test cases</p>
              <div class="lf-body-item-file full-width">
                <p>{{ solutionFilename }}</p>
                <button class="if-submit" type="button" aria-label="solution" aria-describedby="solution-file" @click="selectSolutionFile">
                  Select solution file
                </button>
                <input ref="fileInput" type="file" :accept="acceptableSolutionFileType" style="display: none" @change="onChange($event)" />
              </div>
            </div>
          </div>
        </div>
        <p v-show="hasError()" class="if-errorInput">{{ errorMessage }}</p>
        <div class="lf-submit-container">
          <button ref="submitBtn" class="if-cancel" type="button" @click="goBack">Cancel</button>
          <button ref="submitBtn" class="if-submit" type="submit">Submit ({{ remainingMinutes }})</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import CodingInterviewQuestionViewer from "../../components/viewers/CodingInterviewQuestionViewer";
import { PersonalUserApi, OIDUtils, DateUtils, solutionSubmissionValidator } from "../../utils/index";
import {
  interviewQuestionStruct,
  codingInterviewStruct,
} from "../../types/index";

export default {
  name: "PersonalResolveProblem",
  props: {
    interviewId: {
      required: true,
      type: String,
    },
  },

  components: {
    CodingInterviewQuestionViewer,
  },
  data() {
    return {
      interviewQuestion: interviewQuestionStruct,
      codingInterview: codingInterviewStruct,
      interviewStartTime: null,
      interviewCompleteTime: null,
      interviewAllowedTime: null,
      remainingMinutes: null,
      errorMessage: null,
      placeholderDescription: "",
      interviewQuestionId: null,
      solutionFile: null,
      solutionFilename: null,
      programingLanguage: "CPP",
      acceptableSolutionFileType: ".CPP",
      submissionId: null,
      submissionTestResults: null,
      submissionTestScore: null
    };
  },
  watch: {
    programingLanguage: function (val) {
      if (val != 'ANY') {
        this.acceptableSolutionFileType = "." + val;
      } else {
        this.acceptableSolutionFileType = ".CPP,.JAVA,.PY";
      }
    },
  },
  created() { },
  mounted() {
    this.fetchCodingProblemAndStartInterview(this.interviewId);
  },
  methods: {
    fetchCodingProblemAndStartInterview(interviewId) {
      PersonalUserApi.fetchCodingProblemAndStartInterview(interviewId)
        .then((response) => {
          this.interviewQuestion = response.data.question;
          this.interviewQuestionId = OIDUtils.FromJson(this.interviewQuestion);
          this.codingInterview = response.data.interview;
          this.interviewStartTime = DateUtils.FromJson(
            this.codingInterview.startTime
          );
          this.interviewCompleteTime = DateUtils.FromJson(
            this.codingInterview.completeTime
          );
          this.interviewAllowedTime = this.codingInterview.allowedDuration;
          this.refreshInterivewTimer();
        })
        .catch((error) => {
          this.mnx_backendErrorHandler(error);
        });
    },
    goBack() {
      this.mnx_goBack();
    },
    hasError() {
      return this.errorMessage;
    },

    selectSolutionFile() {
      this.$refs.fileInput.click();
    },

    onChange(event) {
      let file = event.target.files[0];
      let reader = new FileReader();
      reader.addEventListener(
        "load",
        function () {
          this.solutionFile = reader.result;
          this.solutionFilename = file.name;
        }.bind(this),
        false
      );
      reader.readAsDataURL(file);
    },
    async submitForm() {
      this.errorMessage = solutionSubmissionValidator.filenameValidator.validate(this.solutionFilename);
      if(this.hasError()) return;
      this.errorMessage = solutionSubmissionValidator.textFileValidator.validate(this.solutionFile);
      if(this.hasError()) return;
      PersonalUserApi.submitCodingSolution(
        this.interviewId,
        this.interviewQuestionId,
        this.solutionFile,
        this.solutionFilename,
        this.programingLanguage
      )
        .then((response) => {
          this.submissionId = response.data.submissionId;
          this.mnx_navToPersonalSolutionSubmitted(this.submissionId);
        })
        .catch((error) => {
          this.mnx_backendErrorHandler(error);
        });
    },
    refreshInterivewTimer() {
      let now = new Date();
      this.remainingMinutes =
        Math.floor((this.interviewCompleteTime.getTime() - now.getTime()) / 1000 / 60);
      if (this.remainingMinutes < 0) {
        //disable the submit button
        this.$refs.submitBtn.disabled = true;
      } else {
        setTimeout(this.refreshInterivewTimer, 1000 * 60);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.container-background {
  @include container-background;

  .container-content {
    @include container-content;
    padding: 45px 0;
  }
}
</style>
