import { store } from "../../plugins/index";

class CodingInterviewUtils {
    constructor(store) {
        this.store = store;
    }

    fill(interview) {
        this.interview = interview;
    }

    fetch() {
        return this.interview;
    }
    
    fillId(id) {
        this.id = id;
    }

    fetchId() {
        return this.id;
    }
}

const codingInterviewUtils = new CodingInterviewUtils(store);

export { codingInterviewUtils };