const jobStatusEnum = Object.freeze({
    DISABLE: 0,
    OPEN: 1,
    FILLED: 2,
});

const jobPositionStruct = {
    status: 0,
    title: null,
    description: null,
    business: null,
    createTime: null,
    createdBy: null,
    updateTime: null,
    updatedBy: null,
}

export { jobPositionStruct,jobStatusEnum }