import { backendAxios } from './axios'
import { userRoleEnum } from "../../types/index";
import { userUtils } from '../store/index'
class PersonalUserApi {
    static sendCodeToEmail(email) {
        const request = backendAxios.post(
            '/api/common/send-code-to-email',
            {
                email: email,
                role: userRoleEnum.PERSONAL,
            },
            {
            }
        );
        return request;
    }

    static signinWithEmailAndCode(email, code) {
        const request = backendAxios.post(
            '/api/personal/signin-by-email-code',
            {
                code: code,
                email: email,
                role: userRoleEnum.PERSONAL,
            },
            {
            }
        );
        return request;
    }

    static SignOut() {
        let jwt = userUtils.getJwtToken();
        const request = backendAxios.post(
            '/api/personal/signout',
            {
            },
            {
                headers: { Authorization: `Bearer ${jwt}` }
            }
        );
        return request;
    }


    static updateEmail(email) {
        let jwt = userUtils.getJwtToken();
        const request = backendAxios.post(
            '/api/personal/update-email',
            {
                email: email,
                role: userRoleEnum.PERSONAL,
            },
            {
                headers: { Authorization: `Bearer ${jwt}` }
            }
        );
        return request;
    }

    static updateMobile(mobile) {
        let jwt = userUtils.getJwtToken();
        const request = backendAxios.post(
            '/api/personal/update-mobile',
            {
                mobile: mobile,
                role: userRoleEnum.PERSONAL,
            },
            {
                headers: { Authorization: `Bearer ${jwt}` }
            }
        );
        return request;
    }

    static updateFullname(firstname, lastname) {
        let jwt = userUtils.getJwtToken();
        const request = backendAxios.post(
            '/api/personal/update-fullname',
            {
                firstname: firstname,
                lastname: lastname,
                role: userRoleEnum.PERSONAL,
            },
            {
                headers: { Authorization: `Bearer ${jwt}` }
            }
        );
        return request;
    }

    static updateResume(resume, resumeFilename) {
        let jwt = userUtils.getJwtToken();
        const request = backendAxios.post(
            '/api/personal/update-resume',
            {
                resume: resume,
                resumeFilename: resumeFilename,
                role: userRoleEnum.PERSONAL,
            },
            {
                headers: { Authorization: `Bearer ${jwt}` }
            }
        );
        return request;
    }

    static fetchProfile() {
        let jwt = userUtils.getJwtToken();
        const request = backendAxios.post(
            '/api/personal/fetch-profile',
            {
                role: userRoleEnum.PERSONAL,
            },
            {
                headers: { Authorization: `Bearer ${jwt}` }
            }
        );
        return request;
    }

    static updateProfile(profile) {
        let jwt = userUtils.getJwtToken();
        const request = backendAxios.post(
            '/api/personal/update-profile',
            {
                profile: profile,
                role: userRoleEnum.PERSONAL,
            },
            {
                headers: { Authorization: `Bearer ${jwt}` }
            }
        );
        return request;
    }

    static queryCodingInterview(status, keyword = '') {
        let jwt = userUtils.getJwtToken();
        const request = backendAxios.post(
            '/api/personal/query-coding-interview',
            {
                status: status,
                role: userRoleEnum.PERSONAL,
                keyword
            },
            {
                headers: { Authorization: `Bearer ${jwt}` }
            }
        );
        return request;
    }

    static fetchCodingInterview(interviewId) {
        let jwt = userUtils.getJwtToken();
        const request = backendAxios.post(
            '/api/personal/fetch-coding-interview',
            {
                interviewId: interviewId,
                role: userRoleEnum.PERSONAL,
            },
            {
                headers: { Authorization: `Bearer ${jwt}` }
            }
        );
        return request;
    }

    static fetchCodingProblemAndStartInterview(interviewId) {
        let jwt = userUtils.getJwtToken();
        const request = backendAxios.post(
            '/api/personal/fetch-coding-problem-and-start-interview',
            {
                interviewId: interviewId,
                role: userRoleEnum.PERSONAL,
            },
            {
                headers: { Authorization: `Bearer ${jwt}` }
            }
        );
        return request;
    }

    static submitCodingSolution(interviewId, problemId, solution, filename, programingLanguage) {
        let jwt = userUtils.getJwtToken();
        const request = backendAxios.post(
            '/api/personal/submit-coding-solution',
            {
                interviewId: interviewId,
                problemId: problemId,
                programingLanguage: programingLanguage,
                solution: solution,
                filename: filename,
                role: userRoleEnum.PERSONAL,
            },
            {
                headers: { Authorization: `Bearer ${jwt}` }
            }
        );
        return request;
    }

    static queryCoddingSubmissions() {
        let jwt = userUtils.getJwtToken();
        const request = backendAxios.post(
            '/api/personal/query-coding-submissions',
            {
                role: userRoleEnum.PERSONAL,
            },
            {
                headers: { Authorization: `Bearer ${jwt}` }
            }
        );
        return request;
    }

    static fetchCoddingSubmission(submissionId) {
        let jwt = userUtils.getJwtToken();
        const request = backendAxios.post(
            '/api/personal/fetch-coding-submission',
            {
                submissionId: submissionId,
                role: userRoleEnum.PERSONAL,
            },
            {
                headers: { Authorization: `Bearer ${jwt}` }
            }
        );
        return request;
    }

    static fetchCoddingSubmissionResult(submissionId) {
        let jwt = userUtils.getJwtToken();
        const request = backendAxios.post(
            '/api/personal/fetch-coding-submission-result',
            {
                submissionId: submissionId,
                role: userRoleEnum.PERSONAL,
            },
            {
                headers: { Authorization: `Bearer ${jwt}` }
            }
        );
        return request;
    }

    static fetchCoddingSubmissionResultByProblem(interviewId, problemId) {
        let jwt = userUtils.getJwtToken();
        const request = backendAxios.post(
            '/api/personal/fetch-coding-submission-result-by-problem',
            {
                interviewId: interviewId,
                problemId: problemId,
                role: userRoleEnum.PERSONAL,
            },
            {
                headers: { Authorization: `Bearer ${jwt}` }
            }
        );
        return request;
    }

    static fetchJob(id) {
        let jwt = userUtils.getJwtToken();
        const request = backendAxios.post(
            '/api/personal/fetch-job',
            {
                jobId: id,
                role: userRoleEnum.PERSONAL,
            },
            {
                headers: { Authorization: `Bearer ${jwt}` }
            }
        );
        return request;
    }
    static fetchBusinessProfile(id) {
        let jwt = userUtils.getJwtToken();
        const request = backendAxios.post(
            '/api/personal/fetch-business-profile',
            {
                businessId: id,
                role: userRoleEnum.PERSONAL,
            },
            {
                headers: { Authorization: `Bearer ${jwt}` }
            }
        );
        return request;
    }
}

export { PersonalUserApi }