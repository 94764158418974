
const businessUserStruct = {
    identity: null,
    password: null,
    email: null,
    firstname: null,
    lastname: null,
    startTime: null,
    business: null,
    applicant: null,
}

export { businessUserStruct }