<template>
  <div class="navigator-container-background isBusiness">
    <div class="navigator-container-content">
      <img class="logo" alt="freeleaps logo" src="@/assets/logo_for_white.png" @click="gotoHome" />
      <div class="flex-space">
        <div class="btn-group">
          <button class="btn btn-secondary btn-sm btn-main" type="button" @click="gotoCodingInterviewHome">Interview</button>
          <button type="button" class="btn btn-sm btn-secondary dropdown-toggle dropdown-toggle-split btn-toggle" data-bs-toggle="dropdown" aria-expanded="false" data-bs-reference="parent" data-bs-offset="0,18">
            <span class="visually-hidden">Toggle Dropdown</span>
          </button>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" href="#" @click="gotoNewCodingInterview">Create</a></li>
            <li><a class="dropdown-item" href="#" @click="gotoCodingInterviewHome">Scheduled</a></li>
            <li><a class="dropdown-item" href="#" @click="gotoOngoingCodingInterivew">In progress</a></li>
            <li><a class="dropdown-item" href="#" @click="gotoCompleteCodingInterivew">Completed</a></li>
            <li><a class="dropdown-item" href="#" @click="gotoCancelledCodingInterivew">Cancelled</a></li>
          </ul>
        </div>
        <div class="btn-group">
          <button class="btn btn-secondary btn-sm btn-main" type="button" @click="gotoApplicants">Candidates</button>
          <button type="button" class="btn btn-sm btn-secondary dropdown-toggle dropdown-toggle-split btn-toggle" data-bs-toggle="dropdown" aria-expanded="false" data-bs-reference="parent" data-bs-offset="0,18">
            <span class="visually-hidden">Toggle Dropdown</span>
          </button>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" href="#" @click="gotoAddApplicant">Add</a></li>
          </ul>
        </div>
        <div class="btn-group">
          <button class="btn btn-secondary btn-sm btn-main" type="button" @click="gotoJobs">Jobs</button>
          <button type="button" class="btn btn-sm btn-secondary dropdown-toggle dropdown-toggle-split btn-toggle" data-bs-toggle="dropdown" aria-expanded="false" data-bs-reference="parent" data-bs-offset="0,18">
            <span class="visually-hidden">Toggle Dropdown</span>
          </button>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" href="#" @click="gotoAddJob">Add</a></li>
          </ul>
        </div>
        <div class="btn-group">
          <button class="btn btn-secondary btn-sm btn-main" type="button" @click="gotoResources">Resources</button>
        </div>
      </div>
      <div class="dropdown">
        <a class="btn btn-secondary btn-user" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-offset="0,18">
          <span>{{userIdentityNote}}</span>
          <img src="@/assets/account.png" alt="magic leaps">
        </a>
        <!-- @/assets/account.png -->
        <ul class="dropdown-menu">
          <li><a class="dropdown-item" href="#" @click="gotoPersonalAccount">Personal</a></li>
          <li><a class="dropdown-item" href="#" @click="gotoBusisnessProfile">Business</a></li>
          <li><a class="dropdown-item" href="#" @click="gotoAccountManagement">Co-worker</a></li>
          <li><a class="dropdown-item" href="#" @click="gotoSubscription">Subscripton</a></li>
          <li><a class="dropdown-item" href="#" @click="signout">Log out ({{ userIdentityNote }})</a></li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import {codingInterviewUtils, BusinessUserApi, applicantUtils, jobPositionUtils} from "../utils/index";

export default {
  name: "HeaderBusiness",
  components: {},
  computed: {},
  created() {
    if (this.userIdentityNote.length > 8) {
      this.userIdentityNote = this.userIdentityNote.slice(0, 5) + "...";
    }
  },
  data() {
    return {
      userIdentityNote: this.mnx_getUserIdentity(),
    };
  },
  methods: {
    gotoHome() {
      // this.mnx_navToBusinessHomePage();
      this.mnx_navToBusinessCodingInterviewHome();
    },
    gotoCodingInterviewHome() {
      this.mnx_navToBusinessCodingInterviewHome();
    },
    gotoOngoingCodingInterivew() {
      this.mnx_navToBusinessOngoingCodingInterview();
    },
    gotoCompleteCodingInterivew() {
      this.mnx_navToBusinessCompletedCodingInterview();
    },
    gotoCancelledCodingInterivew() {
      this.mnx_navToBusinessCancelledCodingInterview();
    },
    gotoNewCodingInterview() {
      codingInterviewUtils.fill(null);
      codingInterviewUtils.fillId(null);
      this.mnx_navToBusinessEditCodingInterview();
    },
    gotoAccountManagement() {
      this.mnx_navToBusinessAccountManage();
    },
    gotoBusinessHome() {
      this.mnx_navToBusinessBusinessHome();
    },

    gotoApplicants() {
      this.mnx_navToBusinessApplicants();
    },
    gotoAddApplicant() {
      applicantUtils.fill(null);
      applicantUtils.fillApplicantId(null);
      this.mnx_navToBusinessNewApplicant();
    },
    gotoJobs() {
      this.mnx_navToBusinessJobPositions();
    },
    gotoAddJob() {
      jobPositionUtils.fill(null);
      jobPositionUtils.fillJobPositionId(null);
      this.mnx_navToBusinessNewJobPosition();
    },

    gotoResources() {
      this.mnx_navToBusinessResources();
    },

    gotoPersonalAccount() {
      this.mnx_navToBusinessPersonalProfile();
    },


    gotoBusisnessProfile() {
      this.mnx_navToBusinessProfile();
    },

    gotoSubscription() {
      this.mnx_navToBusinessSubscription();
    },

    logout(response, error) {
      response;
      error;
      this.mnx_unauthenticatedUser();
      this.mnx_logoutRole();
      this.mnx_navToFrontDoor();
    },

    signout() {
      BusinessUserApi.SignOut(
      )
        .then((response) => {
          this.logout(response, null);
        })
        .catch((error) => {
          // continue to logout with errors
          this.logout(null, error);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.navigator-container-background {
    @include container-background;
    background-color: #0032FD;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);

    .navigator-container-content {
        @include container-content;
        display: flex;
        padding: 15px 0;
        align-items: center;
        color: white;
        height: 77px;
        border-bottom: 0;

        .logo { height: 32px; cursor: pointer;
          &:hover {opacity: .78;}
        }
        .btn {margin-left: 32px;}
        .btn-space {width: 1px; height: 32px; border-left: 1px solid #AFAFAF; margin-left: 32px; display: none;}
        .btn-link {color: white;}
        .btn-primary {background-color: white; color: black; border-color: white;}
        .btn-group {
          .btn {background-color: transparent; color: #050505; margin-left: 0; border: 0; box-shadow: none; outline: 0; font-size: 20px;
            &:hover {opacity: .78;}
          }
          .btn-main {padding: 0 5px;}
          .btn-toggle {margin-top: 5px;}
        }
        .flex-space {display: flex; justify-content: space-around;}
        .btn-user {background-color: transparent; font-size: 16px; font-weight: 700; color: #050505; outline: 0; box-shadow: none; display: flex; align-items: center; border: 0; padding: 0;
          &:hover {opacity: .78;}
          img {width: 32px; height: 32px; margin-left: 16px; border-radius: 50%; overflow: hidden;}
        }
    }

    &.isBusiness { background-color: white;
      .navigator-container-content { color: black;
        .btn-space {display: block;}
        .btn-link {color: black;}
        .sign-in {color: #0032FD;}
        .btn-primary {background-color: #0032FD; color: white; border-color: #0032FD;}
      }
    }
}
.container-div {
  width: 100%;
  height: 100%;
  @extend .d-flex;
  @extend .justify-content-between;
}

.account-logo {
  max-width: 100%;
  max-height: 100%;
}

.company-logo {
  max-width: 100%;
  max-height: 100%;
}

.nav-bar-container {
  @extend .nav;
  @extend .nav-fill;
  @extend .mx-auto;
  @extend .mt-0;
  @extend .h-100;
  @extend .w-90;
  @extend .w-sm-70;
  @extend .w-lg-50;
}

.nav-bar-item {
  @include media-breakpoint-up(xs) {
    font-size: rfs-fluid-value(0.5rem);
  }

  @include media-breakpoint-up(sm) {
    font-size: rfs-fluid-value(1rem);
  }

  @include media-breakpoint-up(md) {
    font-size: rfs-fluid-value(1rem);
  }

  @include media-breakpoint-up(lg) {
    font-size: rfs-fluid-value(1rem);
  }

  @include media-breakpoint-up(xl) {
    font-size: rfs-fluid-value(1rem);
  }

  @include media-breakpoint-up(xxl) {
    font-size: rfs-fluid-value(1rem);
  }

  @extend .align-middle;
  @extend .text-wrap;
  @extend .border-0;
}

.nav-bar-button {
  @extend .nav-bar-item;
  @extend .nav-link;
  @extend .btn;
  @extend .btn-outline-info;
  color: #fae4ab;
}

.nav-bar-dropdown {
  @extend .dropdown;
  @extend .nav-bar-item;
}

.nav-bar-dropdown-menu-button {
  @extend .btn;
  @extend .btn-link;
  @extend .dropdown-item;
}

.nav-bar-dropdown-button {
  @extend .btn;
  @extend .btn-outline-success;
  @extend .dropdown-toggle;
  color: #fae4ab;
  @extend .h-100;
}

.account-button {
  @extend .btn;
  @extend .btn-outline-success;
  @extend .dropdown-toggle;
  @extend .h-90;
}

.account-menu-button {
  @extend .btn;
  @extend .btn-link;
  @extend .dropdown-item;
}

.account-menu-item {
  @extend .dropdown-item;
}

.account-note {
  max-width: 100%;
  @include font-size(0.5rem);
  color: #fae4ab;
}
</style>