import { TextValidator } from './textValidator'
const formatMessage = "less then 1M characters";
const pattern = /^[ -~|\n]{2,}$/;
const minLength = 2;
const maxLength = 1024 * 1024;

class TextFileValidator extends TextValidator {
    constructor() {
        super(
            "file content",
            formatMessage,
            [
                {
                    regex: pattern,
                    message: " contains invalid charactors."
                }
            ],
            minLength,
            maxLength
        );
    }

}

export { TextFileValidator }
