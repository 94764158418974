import { userRoleEnum } from "../types/index"
export default {
    methods: {
        //business
        mnx_navToBusinessFrontpage() {
            this.$router.push("/business-front-door/");
        },
        mnx_navToBusinessSigninWithEmaiCode(email) {
            this.$router.push("/business-signin-with-email-code/" + email);
        },
        mnx_navToBusinessCompletedCodingInterview() {
            this.$router.push("/business-completed-coding-interview/");
        },
        mnx_navToBusinessCancelledCodingInterview() {
            this.$router.push("/business-cancelled-coding-interview/");
        },
        mnx_navToBusinessOngoingCodingInterview() {
            this.$router.push("/business-ongoing-coding-interview/");
        },
        mnx_navToBusinessHomePage() {
            this.$router.push("/business-home-page/");
        },
        mnx_navToBusinessEditCodingInterview() {
            this.$router.push("/business-edit-coding-interview/");
        },
        mnx_navToBusinessCodingInterviewScheduled() {
            this.$router.push("/business-coding-interview-scheduled/");
        },
        mnx_navToBusinessAccountManage() {
            this.$router.push("/business-account-manage/");
        },
        mnx_navToBusinessProfile() {
            this.$router.push("/business-profile/");
        },
        mnx_navToBusinessProfileUpdated() {
            this.$router.push("/business-profile-updated/");
        },

        mnx_navToBusinessSubscription() {
            this.$router.push("/business-subscription/");
        },
        mnx_navToBusinessPersonalProfile() {
            this.$router.push("/business-personal-profile/");
        },
        mnx_navToBusinessPersonalProfileUpdated() {
            this.$router.push("/business-personal-profile-updated/");
        },
        mnx_navToBusinessPersonalUpdateEmail() {
            this.$router.push("/business-personal-update-email/");
        },
        mnx_navToBusinessPersonalEmailUpdateRequireCode(email) {
            this.$router.push("/business-personal-email-update-require-code/" + email);
        },
        mnx_navToBusinessPersonalEmailUpdated(email) {
            this.$router.push("/business-personal-email-updated/" + email);
        },
        mnx_navToBusinessPersonalUpdateUsername() {
            this.$router.push("/business-personal-update-username/");
        },
        mnx_navToBusinessPersonalUsernameUpdated(username) {
            this.$router.push("/business-personal-username-updated/" + username);
        },
        mnx_navToBusinessPersonalUpdateFullname() {
            this.$router.push("/business-personal-update-fullname/");
        },
        mnx_navToBusinessPersonalFullnameUpdated() {
            this.$router.push("/business-personal-fullname-updated/");
        },
        mnx_navToBusinessPersonalUpdatePassword() {
            this.$router.push("/business-personal-update-password/");
        },
        mnx_navToBusinessPersonalPasswordUpdated() {
            this.$router.push("/business-personal-password-updated/");
        },
        mnx_navToBusinessCodingInterviewHome() {
            this.$router.push("/business-coding-interview-home/");
        },
        mnx_navToBusinessCodingInterviewReport(interviewId) {
            this.$router.push("/business-coding-interview-report/" + interviewId);
        },
        mnx_navToBusinessViewCodingProblem(problemId) {
            this.$router.push("/business-view-coding-problem/" + problemId);
        },
        mnx_navToBusinessViewScoreDetails() {
            this.$router.push("/business-view-submission-score-details/");
        },
        mnx_navToBusinessApplicants() {
            this.$router.push("/business-applicants/");
        },
        mnx_navToBusinessNewApplicant() {
            this.$router.push("/business-edit-applicant/");
        },
        mnx_navToBusinessEditApplicant() {
            this.$router.push("/business-edit-applicant/");
        },
        mnx_navToBusinessApplicantAdded() {
            this.$router.push("/business-applicant-added/");
        },
        mnx_navToBusinessJobPositions() {
            this.$router.push("/business-job-positions/");
        },
        mnx_navToBusinessJobPositionAdded() {
            this.$router.push("/business-job-position-added/");
        },
        mnx_navToBusinessNewJobPosition() {
            this.$router.push("/business-edit-job-position/");
        },
        mnx_navToBusinessEditJobPosition() {
            this.$router.push("/business-edit-job-position/");
        },
        mnx_navToBusinessResources() {
            this.$router.push("/business-resources/");
        },

        //personal
        mnx_navToPersonalFrontpage() {
            this.$router.push("/personal-front-door/");
        },

        mnx_navToPersonalSigninByEmailAndCode(email) {
            this.$router.push("/personal-signin-by-email-and-code/" + email);
        },

        mnx_navToPersonalHomePage() {
            this.$router.push("/personal-home/");
        },
        mnx_navToPersonalProfile() {
            this.$router.push("/personal-profile/");
        },

        mnx_navToPersonalProfileUpdated() {
            this.$router.push("/personal-profile-updated/");
        },

        mnx_navToPersonalCompletedInterviews() {
            this.$router.push("/personal-completed-interviews/");
        },

        mnx_navToPersonalViewCodingInterviewReport(interviewId) {
            this.$router.push("/personal-view-coding-interview-report/" + interviewId);
        },

        mnx_navToPersonalResolveProblem(interviewId) {
            this.$router.push("/personal-resolve-problem/" + interviewId);
        },

        mnx_navToPersonalSolutionSubmitted(submissionId) {
            this.$router.push("/personal-solution-submitted/" + submissionId);
        },

        mnx_navToPersonalViewSubmissionScoreDetails() {
            this.$router.push("/personal-view-submission-score-details");
        },

        mnx_navToPersonalViewPosition(jobId) {
            this.$router.push("/personal-view-position/" + jobId)
        },

        mnx_navToPersonalViewBusinessProfile(businessId) {
            this.$router.push("/personal-view-business/" + businessId)
        },

        mnx_navToPersonalStartInterview(interviewId) {
            this.$router.push("/personal-start-interview/" + interviewId)
        },

        //designer
        mnx_navToDesignerFrontpage() {
            this.$router.push("/designer-front-door/");
        },

        mnx_navToDesignerHomePage() {
            this.$router.push("/designer-home/");
        },

        mnx_navToDesignerEditProblem() {
            this.$router.push("/designer-edit-problem/");
        },

        mnx_navToDesignerProblemSubmitted() {
            this.$router.push("/designer-problem-submitted/");
        },

        mnx_navToDesignerProfile() {
            this.$router.push("/designer-profile/");
        },

        mnx_navToDesignerProfileUpdated() {
            this.$router.push("/designer-profile-updated/");
        },


        //admin
        mnx_navToAdminFrontdoor() {
            this.$router.push("/admin-front-door/");
        },
        mnx_navToAdminSigninByEmailAndCode(email) {
            this.$router.push("/admin-signin-by-email-and-code/" + email);
        },
        mnx_navToAdminHomePage() {
            this.$router.push("/admin-home-page/");
        },
        mnx_navToAdminManage() {
            this.$router.push("/admin-manage-admin/");
        },

        mnx_navToBusinessManage() {
            this.$router.push("/admin-manage-business/");
        },
        mnx_navToPersonalManage() {
            this.$router.push("/admin-manage-personal/");
        },

        mnx_navToDesignerManage() {
            this.$router.push("/admin-manage-designer/");
        },

        mnx_navToEveluationManage() {
            this.$router.push("/admin-manage-eveluation/");
        },

        //common
        mnx_navToFrontDoor() {
            this.$router.push("/front-door");
        },
        mnx_navToContactUs() {
            this.$router.push("/contact-us");
        },
        mnx_navToAboutFreeleapsCompany() {
            this.$router.push("/about-freeleaps-company");
        },
        mnx_navToAboutMagicleapsPlatform() {
            this.$router.push("/about-magicleaps-platform");
        },

        mnx_navAfterSignedin() {
            if (this.mnx_isUserAuthenticated()) {
                switch (this.mnx_getUserRole()) {
                    case userRoleEnum.DESIGNER:
                        this.mnx_navToDesignerHomePage();
                        break;
                    case userRoleEnum.BUSINESS:
                        // this.mnx_navToBusinessHomePage();
                        this.mnx_navToBusinessCodingInterviewHome();
                        break;
                    case userRoleEnum.PERSONAL:
                        if (this.$router.currentRoute.value.name == 'front-door' && this.$route.query.id) {
                            this.mnx_navToPersonalStartInterview(this.$route.query.id)
                        } else {
                            this.mnx_navToPersonalHomePage()
                        }
                        break;
                    case userRoleEnum.ADMIN:
                        this.mnx_navToAdminHomePage();
                        break;
                    case userRoleEnum.NONE:
                        break;
                    default:
                        break;
                }
            }
            else {
                console.log("user is not signed in");
            }
        },

        mnx_goBack() {
            this.$router.go(-1);
        },

    },
};