<template>
  <div class="container-background">
    <div class="container-content">
      <div class="desginer-container">
        <img src="@/assets/designer-font-door.jpeg" alt="">
        <div class="desginer-right-container">
          <p class="desginer-title">Designer portal</p>
          <p class="desginer-subtitle">Quick test for candidates</p>
          <button v-if="!authed" class="desginer-login" @click="signinAction">Add Problem</button>
        </div>
      </div>
    </div>
    <CustomModal v-model:visible="signinModalVisible">
      <div class="signin-container" v-if="!hadSendCode">
        <p class="signin-title">Sign in by Email</p>
        <form @submit.prevent="sendCodeToEmail">
          <input class="signin-input big" type="text" placeholder="Enter your Email" v-model="email">
          <p class="signin-error" v-if="emailErrors">{{emailErrors}}</p>
          <br>
          <button class="signin-btn" type="submit" ref="sendCodeButton" v-bind:disabled="sending">
            <span v-if="sending" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span>{{sending?'&nbsp;&nbsp;&nbsp;&nbsp;Sending':'Send Code'}}</span>
          </button>
        </form>
        <!-- <p class="signin-tips">Find a trustworthy partner<br>simplify the work<br>secure your return</p> -->
      </div>
      <div class="signin-container" v-else>
        <p class="signin-title">Enter your Security Code</p>
        <p class="signin-subTitle">The security code will be sent to your Email</p>
        <CodeInput class="signin-code-input" :code="code" @complete="codeComplete" />
        <p class="signin-error code-error" v-if="codeErrors">{{codeErrors}}</p>
        <button class="signin-btn" type="submit" @click="submitAction">Submit</button>
        <br>
        <button class="signin-resend">Resend security code</button>
      </div>
    </CustomModal>
  </div>
</template>

<script>
import CustomModal from '@/components/common/CustomModal.vue'
import CodeInput from '@/components/common/CodeInput.vue'
import {DesignerApi, userProfileValidator} from "../../utils/index";
export default {
  name: "DesignerFrontDoor",
  props: {},

  data() {
    return {
      signinModalVisible: false,
      email: null,
      emailErrors: null,
      hadSendCode: false,
      code: '',
      codeErrors: null,
      sending: false,
      authed: false
    };
  },
  components: {CustomModal, CodeInput},
  created() { },
  mounted() { 
    this.authed = this.mnx_isUserAuthenticated()
  },
  methods: {
    signinAction() {
      this.email = null
      this.emailErrors = null
      this.hadSendCode = false
      this.code = ''
      this.codeErrors = null
      this.signinModalVisible = true
    },
    codeComplete (e) {
      if (e) {
        this.code = e?.join('')
      }
    },
    sendCodeToEmail() {
      this.emailErrors = null;
      if (this.email === null || this.email.length < 1) {
        this.emailErrors = "Please type in your email";
        return;
      }

      this.emailErrors = userProfileValidator.emailValidator.validate(this.email);

      if (this.emailErrors != null) return;

      this.sending = true;

      DesignerApi.sendCodeToEmail(this.email).then((response) => {
        response;
        this.sending = false;
        this.hadSendCode = true
      }).catch((error) => {
        this.sending = false;
        if (error.response)
          this.emailErrors = error.response.data.text;
        else this.emailErrors = "Failed to proceed";
      })
    },
    submitAction() {
      this.codeErrors = null
      if (!this.code) {
        this.codeErrors = "Please type in your code";
        return;
      }
      this.codeErrors = userProfileValidator.authCodeValidator.validate(this.code);
      if (this.codeErrors != null) return;

      DesignerApi.signinWithEmailAndCode(this.email, this.code).then((response) => {
        this.mnx_userSignedin(response.data, true);
      }).catch((error) => {
        if (error.response && error.response.status == 401) {
          this.codeErrors = "invalid code";
        } else {
          console.log(error);
          this.codeErrors = "something is wrong";
        }
      })
      
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.container-background {
  @include container-background;
  background-color: white;

  .container-content {
    @include container-content;
    padding: 130px 120px 180px 120px;

    .desginer-container {display: flex; align-items: center;
      img {width: 440px; height: 266px}
      .desginer-right-container {flex: 1; padding: 0 50px; text-align: left;
        .desginer-title {font-size: 64px; font-weight: 700; line-height: 84px; color: black; margin-bottom: 0;}
        .desginer-subtitle {font-size: 36px; font-weight: 700; line-height: 46px; color: #585858; margin-bottom: 35px;}
        .desginer-login {padding: 10px 20px; border: 0; box-shadow: none; background-color: #E52D6F; font-size: 16px; font-weight: 600;  color: white; border-radius: 5px;}
      }
    }
  }
}
.signin-container {padding: 57px 20px 20px 20px; text-align: center;
  .signin-title {font-size: 32px; line-height: 41px; margin-bottom: 30px; font-weight: 500; color: black;}
  .signin-input {background-color: white; border: none; box-shadow: 0px 4px 4px rgba(208, 208, 208, 0.25); border-radius: 5px; height: 37px; color: black; font-size: 16px; margin-bottom: 22px; padding: 9px 12px;
    &::placeholder {color: #585858;}
    &.big {width: 380px;}
  }
  .signin-btn {color: white; background-color: #1946FC; border: none; padding: 9px 48px; font-size: 14px; cursor: pointer; box-shadow: none; border-radius: 8px;
    &:hover {opacity: .78;}
    &:disabled {opacity: .78; cursor: not-allowed;}
  }
  .signin-resend {color: #1946FC; background-color: transparent; border: none; padding: 0; box-shadow: none; font-size: 14px; cursor: pointer; margin-top: 13px;
    &:hover {opacity: .78;}
  }
  .signin-subTitle {color: #000; font-size: 12px; margin-top: -20px 0 24px 0; line-height: 15px;}
  .signin-tips {color: #585858; font-size: 12px; margin-top: 59px; line-height: 15px;}
  .signin-error {color: #E52D6F; font-size: 12px; margin: 0; line-height: 1;
    &.code-error {margin: -20px 0 29px 0;}
  }
  .signin-code-input {margin-bottom: 29px;}
}
</style>
