const applicantStatusEnum = Object.freeze({
    DISABLE: 0,
    OPEN: 1,
    INPROGRESS: 2,
    COMPLETE: 3,
});
const applicantStruct = {
    email: null,
    mobile: null,
    firstname: null,
    lastname: null,
    business: null,
    jobPosition: null,
    resume: null,
    resumeFilename:null,
    createTime: null,
    createdBy: null,
    updateTime: null,
    updatedBy: null,
}

export { applicantStruct,applicantStatusEnum }