import { store } from "../../plugins/index";

class JobPositionUtils {
    constructor(store) {
        this.store = store;
    }

    fill(jobPosition) {
        this.jobPosition = jobPosition;
    }

    fetch() {
        return this.jobPosition;
    }
    
    fillJobPositionId(id) {
        this.job_id = id;
    }

    fetchJobPositionId() {
        return this.job_id;
    }
}

const jobPositionUtils = new JobPositionUtils(store);

export { jobPositionUtils };