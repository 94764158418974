<template>
    <div class="report-board-body">
        <div class="report-card-container">
            <p class="report-card-title">Summary</p>
            <div class="report-item-container">
                <span class="report-item-name">Title:</span>
                <span v-if="job" class="report-item-value">{{
                        job.title
                }}</span>
            </div>
            <div class="report-item-container">
                <span class="report-item-name">Status:</span>
                <span v-if="job" class="report-item-value">{{
                        FormlizeStatus(job.status)
                }}</span>
            </div>
            <div class="report-item-container">
                <span class="report-item-name">create date:</span>
                <span v-if="job" class="report-item-value">{{
                        FormlizeDate(job.createTime)
                }}</span>
            </div>
        </div>
        <div class="report-card-container">
            <p class="report-card-title">Description</p>
            <textarea class="report-item-display-text" v-if="job" v-model.trim="job.description" readonly></textarea>
        </div>
    </div>
</template>

<script>
import {
    DateUtils,
} from "../../utils/index";
export default {
    name: "JobPositionViewer",
    props: {
        modelValue: null,
    },

    data() {
        return {
            job: null,
        };
    },

    watch: {
        modelValue: {
            immediate: true,
            handler: function (val) {
                this.job = val;
            },
        },
        submission: function (val) {
            this.$emit("update:modelValue", val);
        },
    },
    components: {},

    created() { },
    mounted() {
    },
    updated() {
        this.$el.querySelectorAll('textarea').forEach(element => {
            this.textAreaAdjust(element)
        });
    },

    methods: {
        FormlizeDate(startTime) {
            return DateUtils.FromJsonToDateString(startTime);
        },

        FormlizeStatus(status) {
            switch (status) {
                case 0: return 'DISABLED';
                case 1: return 'OPEN';
                case 2: return 'FILLED';
                default: return 'UNKOWN';
            }
        },
        textAreaAdjust(element) {
            if (element) {
                element.style.height = "1px";
                element.style.height = (25 + element.scrollHeight) + "px";
            }
        },

    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>