<template>
  <div class="main-body">
    <div class="story-board">
      <div class="focus-area">
        <p class="callout text-center">Subscription</p>
        <p>The current subscription plan is 'Free'</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BusinessSubscription",
  props: {},

  data() {
    return {};
  },

  components: {},

  created() {},
  mounted() {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
