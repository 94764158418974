<template>
  <div class="container-background">
      <div class="container-content">
        <div class="content-header-container">
          <p class="content-header-title">
            <span v-if="total">{{pagination.total}}</span>
            &nbsp; Job opportunities
          </p>
          <div class="flex-space"></div>
          <div class="content-search">
            <input type="text" placeholder="search..." v-model="pagination.keyword">
            <img src="@/assets/search.png" alt="search" class="search-icon" @click="searchAction">
          </div>
          <button class="content-btn" @click="gotoAddJob">Add Jobs</button>
        </div>
        <div class="list-container">
          <template v-if="jobList.length > 0">
            <div class="content-item-container" v-for="(job, index) in jobList" :key="index">
              <p class="item-title item-bottom">{{ job.title }}</p>
              <p class="item-light">{{formatDate(job?.createTime?.$date)}}</p>
              <p class="item-content item-bottom">{{ job.description }}</p>
              <div class="item-operation-container item-top">
                <span @click="editJob(job)">Edit</span>
              </div>
            </div>
          </template>
          <div class="empty-container" v-if="jobList.length == 0">
            <img src="@/assets/logo-pic.png" alt="magic leaps logo">
            <p class="opps">Oops !</p>
            <p class="opps-sub">There is nothing here.</p>
          </div>
          <div class="spinner-container" v-if="loading">
            <div class="spinner-grow text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
        <CustomPagination :total="pagination.total" v-model:current="pagination.current" @pageChange="pageChange" />
      </div>
  </div>
</template>

<script>
import { BusinessUserApi, jobPositionUtils, OIDUtils, DateUtils } from "../../utils/index";
import { jobStatusEnum } from "../../types/index";

export default {
  name: "BusinessJobPositions",
  props: {},

  data() {
    return {
      pagination: {
        total: 0,
        current: 1,
        keyword: ''
      },
      loading: false,
      jobList: [],
    };
  },

  components: {},

  created() {},
  mounted() {
    this.queryJobs();
  },
  methods: {
    queryJobs() {
      this.loading = true;
      BusinessUserApi.QueryJobs(jobStatusEnum.OPEN, this.pagination.keyword)
        .then((response) => {
          this.jobList = response.data;
          this.loading = false;
        })
        .catch((error) => {
          this.mnx_backendErrorHandler(error);
          this.loading = false;
        });
    },
    searchAction() {
      this.pagination.total = 0
      this.pagination.current = 1
      this.queryJobs()
    },
    formatDate(time) {
      return DateUtils.FromJsonToDateString(time)
    },
    gotoAddJob() {
      jobPositionUtils.fill(null);
      jobPositionUtils.fillJobPositionId(null);
      this.mnx_navToBusinessNewJobPosition();
    },
    editJob(job) {
      jobPositionUtils.fill(job);
      jobPositionUtils.fillJobPositionId(OIDUtils.FromJson(job));
      this.mnx_navToBusinessNewJobPosition();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.container-background {
  @include container-background;

  .container-content {
    @include container-content;
    padding: 45px 0;
  }
}
</style>
