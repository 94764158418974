import { TextValidator } from './textValidator'
const highlightFormatMessage = "less then 4096 characters";
const highlightPattern = /\p{L}/u;
const highlightMinLength = 8;
const highlightMaxLength = 4096;

class ProblemOutputFormatValidator extends TextValidator {
    constructor() {
        super(
            "OutputFormat",
            highlightFormatMessage,
            [
                {
                    regex: highlightPattern,
                    message: " contains invalid charactors."
                }
            ],
            highlightMinLength,
            highlightMaxLength
        );
    }

}

export { ProblemOutputFormatValidator }
