<template>
  <div class="comp-body">
    <div class="lf-head-container"></div>
    <div class="lf-body-container">
      <div class="lf-body-content">
        <coding-interview-question-viewer v-model="interviewQuestion">
        </coding-interview-question-viewer>
      </div>
    </div>
    <button @click="goBack">Back</button>
  </div>
</template>
<script>
import CodingInterviewQuestionViewer from "../../components/viewers/CodingInterviewQuestionViewer";
import {
  interviewQuestionStruct,
} from "../../types/index";

import {
  BusinessUserApi,
} from "../../utils/index";
export default {
  name: "BusinessViewCodingProblem",
  props: {
    problemId: {
      required: true,
      type: String,
    },
  },

  data() {
    return {
      interviewQuestion: interviewQuestionStruct,
    };
  },

  components: {
    CodingInterviewQuestionViewer,
  },

  created() { },
  mounted() {
    this.fetchProblem();
  },
  methods: {
    fetchProblem() {
      BusinessUserApi.FetchQuestion(this.problemId).then(response => {
        this.interviewQuestion = response.data.question;
      }).catch((error) => {
        this.mnx_backendErrorHandler(error);
      })
    },
    goBack() {
      this.mnx_goBack();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
