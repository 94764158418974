<template>
    <div class="report-board-container">
        <div class="report-board-header">
            <p class="report-board-title">Business Profile</p>
        </div>
        <business-profile-viewer v-model="businessProfile" />
        <button @click="goBack()">Back</button>
    </div>
</template>

<script>
import BusinessProfileViewer from "../../components/viewers/BusinessProfileViewer"
import {
    PersonalUserApi,
} from "../../utils/index";
export default {
    name: "PersonalViewBusinessProfile",
    props: {
        businessId: {
            required: true,
            type: String,
        },
    },

    data() {
        return {
            businessProfile: null,
        };
    },

    components: { BusinessProfileViewer },

    created() { },
    mounted() {
        this.fetchBusinessProfile();
    },
    methods: {
        goBack() {
            this.mnx_goBack();
        },
        fetchBusinessProfile() {
            PersonalUserApi.fetchBusinessProfile(this.businessId)
                .then((response) => {
                    this.businessProfile = response.data.businessProfile;
                })
                .catch((error) => {
                    this.mnx_backendErrorHandler(error);
                });
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
