<template>
    <div class="report-board-body">
        <div class="report-card-container">
            <p class="report-card-title">Summary</p>
            <div class="report-item-container">
                <span class="report-item-name">Name:</span>
                <span v-if="businessProfile" class="report-item-value">{{
                        businessProfile.name
                }}</span>
            </div>
            <div class="report-item-container">
                <span class="report-item-name">Email:</span>
                <span v-if="businessProfile" class="report-item-value">{{
                        businessProfile.email
                }}</span>
            </div>
            <div class="report-item-container">
                <span class="report-item-name">Website:</span>
                <span v-if="businessProfile" class="report-item-value">{{
                        businessProfile.website
                }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "BusinessProfileViewer",
    props: {
        modelValue: null,
    },

    data() {
        return {
            businessProfile: null,
        };
    },

    watch: {
        modelValue: {
            immediate: true,
            handler: function (val) {
                this.businessProfile = val;
            },
        },
        submission: function (val) {
            this.$emit("update:modelValue", val);
        },
    },
    components: {},

    created() { },
    mounted() {
    },
    updated() {
    },

    methods: {

    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>