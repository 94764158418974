<template>
  <div class="container-background">
      <div class="container-content">
          <div class="success-container">
              <img src="@/assets/success.png" alt="">
              <p class="success-title">Your interview question has been submitted</p>
              <p class="success-subtitle">The test score is: {{ submissionTestScore }}</p>
              <button class="success-result" @click="fetchAndShowResult">Show result</button>
              <button class="success-goto" @click="goBack">Back</button>
          </div>
      </div>
  </div>
</template>

<script>
import { PersonalUserApi } from "../../utils/index";
export default {
  name: "PersonalSolutionSubmitted",
  props: {
    submissionId: {
      required: true,
      type: String,
    },
  },

  data() {
    return {
      submissionTestResults: null,
      submissionTestScore: null,
    };
  },

  components: {},

  created() {},
  mounted() {},
  methods: {
    fetchAndShowResult() {
      PersonalUserApi.fetchCoddingSubmissionResult(this.submissionId).then((response) => {
        this.submissionTestResults = response.data.result;
        this.submissionTestScore = response.data.score;
      }).catch((error) => {
        this.mnx_backendErrorHandler(error);
      });
      return;
    },

    goBack() {
      // this.mnx_goBack();
      this.mnx_navToPersonalCompletedInterviews();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.container-background {
  @include container-background;

  .container-content {
    @include container-content;
    padding: 120px 0;
  }
}
.success-result {height: 36px; background-color: #E52D6F; color: white; border-radius: 8px; border: none; box-shadow: none; font-size: 14px; font-weight: 700; width: 175px; margin-bottom: 20px;
  &:hover {opacity: .78;}
}
.success-goto {background-color: transparent; color: black; font-size: 16px; font-weight: 700; border: none; box-shadow: none;
  &:hover {opacity: .78;}
}
</style>
