import { TextValidator } from './textValidator'
const formatMessage = "less then 1024 characters";
const pattern = /^[ -~|\n]{16,1024}$/;
const minLength = 16;
const maxLength = 1024;

class CodingInterviewNoteToCandidateValidator extends TextValidator {
    constructor() {
        super(
            "Note to candidate",
            formatMessage,
            [
                {
                    regex: pattern,
                    message: " contains invalid charactors."
                }
            ],
            minLength,
            maxLength
        );
    }

}

export { CodingInterviewNoteToCandidateValidator }
