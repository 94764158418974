
const personalUserStruct = {
    email: null,
    mobile:null,
    firstname: null,
    lastname: null,
    resume:null,
    resumeFilename:null,
}

export { personalUserStruct }