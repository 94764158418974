import { backendAxios } from './axios'
import { userRoleEnum } from "../../types/index";
import { userUtils } from '../store/index'
class DesignerApi {
    static sendCodeToEmail(email) {
        const request = backendAxios.post(
            '/api/common/send-code-to-email',
            {
                email: email,
                role: userRoleEnum.DESIGNER,
            },
            {
            }
        );
        return request;
    }

    static signinWithEmailAndCode(email, code) {
        const request = backendAxios.post(
            '/api/designer/signin-by-email-code',
            {
                code: code,
                email: email,
                role: userRoleEnum.DESIGNER,
            },
            {
            }
        );
        return request;
    }

    static SignOut() {
        let jwt = userUtils.getJwtToken();
        const request = backendAxios.post(
            '/api/designer/signout',
            {
            },
            {
                headers: { Authorization: `Bearer ${jwt}` }
            }
        );
        return request;
    }


    static queryProblems(keyword = '') {
        let jwt = userUtils.getJwtToken();
        const request = backendAxios.post(
            '/api/designer/query-problems',
            {
                role: userRoleEnum.DESIGNER,
                keyword
            },
            {
                headers: { Authorization: `Bearer ${jwt}` }
            }
        );
        return request;
    }

    static submitProblem(id, problem) {
        let jwt = userUtils.getJwtToken();
        const request = backendAxios.post(
            '/api/designer/submit-problem',
            {
                problemId: id ? id : 'null',
                problem: problem,
                role: userRoleEnum.DESIGNER,
            },
            {
                headers: { Authorization: `Bearer ${jwt}` }
            }
        );
        return request;
    }

    static fetchProfile() {
        let jwt = userUtils.getJwtToken();
        const request = backendAxios.post(
            '/api/designer/fetch-profile',
            {
            },
            {
                headers: { Authorization: `Bearer ${jwt}` }
            }
        );
        return request;

    }

    static updateFullname(first, last) {
        let jwt = userUtils.getJwtToken();
        const request = backendAxios.post(
            '/api/designer/update-fullname',
            {
                firstname: first,
                lastname: last
            },
            {
                headers: { Authorization: `Bearer ${jwt}` }
            }
        );
        return request;

    }

    static updateEmail(email,code) {
        let jwt = userUtils.getJwtToken();
        const request = backendAxios.post(
            '/api/designer/update-email',
            {
                email: email,
                code:code,

            },
            {
                headers: { Authorization: `Bearer ${jwt}` }
            }
        );
        return request;

    }
}

export { DesignerApi }