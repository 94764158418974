import { Validator } from './validator'

class FileLengthValidator extends Validator {
    constructor(valueName, requirementText, rules, minLength, maxLength) {
        super(valueName, requirementText, rules);
        this.minLength = minLength;
        this.maxLength = maxLength;
    }
    getMinLength() {
        return this.minLengh;
    }

    getMaxLength() {
        return this.maxLengh;
    }

    validate(fileSize) {
        let message = super.validate(fileSize);
        if (message) return message;
        if (fileSize < this.minLength) {
            message = this.valueName + " need be greater than " + (this.minLength) + " .";
            return message;
        }
        if (fileSize > this.maxLength) {
            message = this.valueName + " need to be smaller then " + (this.maxLength) + " .";
            return message;
        }
        return message;
    }
}

export { FileLengthValidator }