const solutionSubmissionStatusEnum = Object.freeze({
    DISABLE: 0,
    SUBMITTED: 1,
    EVELUATING: 2,
    EVELUATED: 3,
});

const solutionSubmissionStruct = {
    codeInterview: null,
    problem: null,
    problemTitle:null,
    submissionTime: null,
    eveluatedTime: null,
    submission: null,
    submissionFilename: null,
    programingLanguage: null,
    result: null,
    status: null,
    submittedBy: null,
    eveluationResult: null,
    eveluationScore: null,
}
class SolutionSubmissionStructUtils {
    getStatusText(status) {
        switch (status) {
            case 0:
                return "DISABLED";
            case 1:
                return "SUBMITTED";
            case 2:
                return "EVELUATING";
            case 3:
                return "EVELUATED";
            default:
                return "UNKNOWN";
        }
    }
}

const solutionSubmissionStructUtils = new SolutionSubmissionStructUtils();

export { solutionSubmissionStatusEnum, solutionSubmissionStruct, solutionSubmissionStructUtils }