<template>
  <div class="container-background">
    <div class="container-content">
        <div class="content-container">
            <img class="logo-pic" alt="freeleaps" src="@/assets/logo-pic.png" />
            <br>
            <img class="logo-text" alt="freeleaps" src="@/assets/logo-text.png" />
            <br>
            <span>Freeleaps was found in May 2021 in Sammamish. a beautiful and peaceful suburb of Seattle US.Its mission is to help both job poster and seeker over the whole world to maximize their opportunity to find the best match with minimized cost.</span>
        </div>
    </div>
  </div>
</template>
<script>
export default {
    name: "AboutMagicLeapsPlatform",
    components: {},
    computed: {},
    methods: {}
};
</script>

<style scoped lang="scss">
.container-background {
  @include container-background;

  .container-content {
    @include container-content;

    .content-container {text-align: center; font-size: 20px; line-height: 26px; color: black; padding: 140px 0;
        .logo-pic {width: 203px; height: 203px; margin-bottom: 50px;}
        .logo-text {width: 462px;}
        span {padding: 82px 130px; display: block; text-align: left;}
    }
  }
}
</style>