<template>
  <div class="report-board-container">
    <div class="report-board-header">
      <p class="report-board-title">Coding Interview Score Details</p>
    </div>
    <div class="report-board-body">
      <submission-score-details-viewer v-model="submission"></submission-score-details-viewer>
      <button class="btn btn-primary my-5" @click="goBack">Done</button>
    </div>
  </div>
</template>

<script>
import SubmissionScoreDetailsViewer from "../../components/viewers/SubmissionScoreDetailsViewer.vue"
import { codingSubmissionUtils } from "../../utils/index";
import { solutionSubmissionStruct } from "../../types/index";
export default {
  name: "BusinessViewSubmissionScoreDetails",
  props: {},

  data() {
    return {
      submission: codingSubmissionUtils.fetch()
        ? codingSubmissionUtils.fetch()
        : solutionSubmissionStruct,
    };
  },

  components: { SubmissionScoreDetailsViewer },

  created() { },
  mounted() { },
  methods: {
    goBack() {
      this.mnx_goBack();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
