<!-- eslint-disable vue/multi-word-component-names -->
<template>
   <!-- 全局提示框 -->
   <transition name="slide-fade">
      <div v-if="visible" class="dialog-tips">
          <div>{{message}}</div>
      </div>
  </transition>
</template>
<script>
export default {
  // props: {
  //   message: {
  //     type: String,
  //     required: true
  //   },
  //   duration: {
  //     type: Number,
  //     default: 1000
  //   }
  // },
  data() {
    return {
      visible: false,
      message: '',
      duration: 1000
    };
  },
  watch: {
      visible: {
          immediate: true,
          handler: function (value) {
              if (value) {
                setTimeout(() => {
                  this.visible = false
                }, this.duration)
              }
          },
      }
  }
};
</script>
<style scoped>
.dialog-tips{
    width: 240px;
    box-sizing: border-box;
    border-radius: 4px;
    position: fixed;
    right: 20px;
    top: 20px;
    white-space: normal;
    word-break: break-all;
    background-color: #E52D6F;
    overflow: hidden;
    padding: 10px;
    display: flex;
    align-items: center;
    color: white;
}

.slide-fade-enter, .slide-fade-leave-to {
  margin-top:-30px;
  opacity: 0;

}

.slide-fade-enter-active,.slide-fade-leave-active {
  transition: all .3s ease;
}

.slide-fade-enter-to,.slide-fade-leave {
  margin-top:0px;
  opacity:1;
}
</style>