<template id="page">
  <div class="pagination-container" v-if="pages===0||pages===1?false:true">
    <ul class="pagination">
      <li class="page-item" v-on:click.stop.prevent="pageChange(current==1?1:current-1)" v-bind:class="{disabled:current===1}"><a class="page-link" href="javascript:void(0);">Previous</a></li>
      <li class="page-item" @click.stop.prevent="pageChange(1)" v-bind:class="{active:current===1}" v-if="{false:current===1}"><a class="page-link">1</a></li>
      <li class="page-item" @click.stop.prevent="pageChange(current - display)" v-if="showJumpPrev"><a class="page-link">&laquo;</a></li>
      <li class="page-item" v-for="page in pagingCounts" @click.stop.prevent="pageChange(page)" v-bind:class="{active:current===page}" :key="page"><a class="page-link">{{page}}</a></li>
      <li class="page-item" @click.stop.prevent="pageChange(current + display)" v-if="showJumpNext"><a class="page-link">&raquo;</a></li>
      <li class="page-item" @click.stop.prevent="pageChange(pages)" v-bind:class="{active:current===pages}" v-if="pages===0||pages===1?false:true"><a class="page-link">{{pages}}</a></li>
      <li class="page-item" v-on:click.stop.prevent="pageChange(current==pages?pages:current+1)" v-bind:class="{disabled:current===pages}"><a class="page-link" href="javascript:void(0);">Next</a></li>
    </ul>
  </div>
</template>
 
<script>
  export default {
    data: function () {
      return {
        pages: 0
      }
    },
    props: {
      display: {
        type: Number,
        default: 5,
        required: false
      },
      total: {
        type: Number,
        default: 1
      },
      current: {
        type: Number,
        default: 1
      },
      pageSize: {
        type: Number,
        default: 10,
        required: false
      }
    },
    created: function () {
      let that = this
      this.pages = Math.ceil(that.total / that.pageSize)
    },
    computed: {
      numOffset() {
        return Math.floor((this.display + 2) / 2) - 1;
      },
      showJumpPrev() {
        if (this.total > this.display + 2) {
          if (this.current > this.display) {
            return true
          }
        }
        return false
      },
      showJumpNext() {
        if (this.pages > this.display + 2) {
          if (this.current <= this.pages - this.display) {
            return true
          }
        }
        return false
      },
      pagingCounts() {
        let that = this,
          startNum,
          result = [],
          showJumpPrev = that.showJumpPrev,
          showJumpNext = that.showJumpNext;
        if (showJumpPrev && !showJumpNext) {
          startNum = that.pages - that.display;
          for (let i = startNum; i < that.pages; i++) {
            result.push(i);
          }
        } else if (!showJumpPrev && showJumpNext) {
          for (let i = 2; i < that.display + 2; i++) {
            result.push(i);
          }
        } else if (showJumpPrev && showJumpNext) {
          for (let i = that.current - that.numOffset; i <= that.current + that.numOffset; i++) {
            result.push(i);
          }
        } else {
          for (let i = 2; i < that.pages; i++) {
            result.push(i);
          }
        }
        return result
      }
    },
    methods: {
      pageChange: function (page) {
        if (this.current === page) {
          return;
        }
        this.$emit('update:current', page)
        this.$emit('pageChange', page);
      }
    },
    watch: {
      total:{
        handler: function(){
          let that = this
          this.pages = Math.ceil(that.total / that.pageSize)
        }
      }
    }
  }
</script>
 
<style scoped lang="scss">
.pagination-container {
  .pagination {justify-content: center;
    .page-link {border: none; background-color: transparent; color: #585858; cursor: pointer; box-shadow: none; outline: 0;
      &:hover {opacity: .78;}
    }
    .page-item.disabled .page-link {border: none; background-color: transparent; color: #585858; opacity: .6;}
    .page-item.active .page-link {border: none; background-color: transparent; color: #E52D6F;}
  }
}
</style>