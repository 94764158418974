<template>
  <div class="container-background">
    <div class="container-content">
      <div class="content-header-container">
        <p class="content-header-title">My Profile:</p>
      </div>
      <form @submit.prevent="submitForm">
        <div class="lf-body-container">
          <div class="lf-body-content">
            <div class="lf-body-item">
              <h5 class="lf-body-item-label" for="Firstname-input">Firstname</h5>
              <input type="text" class="lf-body-item-text-input max700" aria-label="Firstname" aria-describedby="business-Firstname-input" id="Firstname" placeholder="Enter your firstname" v-model.trim="businessUser.firstname" />
            </div>
            <div class="lf-body-item">
              <h5 class="lf-body-item-label" for="Lastname-input">Lastname</h5>
              <input type="text" class="lf-body-item-text-input max700" aria-label="Lastname" aria-describedby="business-Lastname-input" id="Lastname" placeholder="Enter your lastname" v-model.trim="businessUser.lastname" />
            </div>
            <div class="lf-body-item">
              <h5 class="lf-body-item-label" for="Email-input">Email</h5>
              <input type="text" class="lf-body-item-text-input max700" aria-label="Email" aria-describedby="business-Email-input" id="Email" placeholder="Enter your email" v-model.trim="businessUser.email" />
            </div>
            <div class="lf-body-container" v-if="oldEmail !== businessUser.email">
              <div class="lf-body-item">
                <h5 class="lf-body-item-label" for="Code">Code</h5>
                <div class="lf-body-item-file">
                  <input type="text" class="lf-body-item-text-input me-5" aria-label="code" aria-describedby="business-code-input" id="code" placeholder="Enter your email code" v-model.trim="code" />
                  <button class="if-cancel" type="button" @click="sendCode">Send code</button>
                </div>
              </div>
            </div>
            <p v-show="errorMessage" class="if-errorInput">{{ errorMessage }}</p>
            <div class="lf-submit-container">
              <button class="if-cancel" type="button" @click="goBack">Cancel</button>
              <button class="if-submit" type="submit">Submit</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { BusinessUserApi, userProfileValidator, businessUserUtils, UserAuthApi } from "../../utils/index";
import { businessUserStruct } from "../../types/index";
export default {
  name: "BusinessPersonalProfile",
  props: {},

  data() {
    return {
      businessUser: businessUserStruct,
      errorMessage: null,
      oldEmail: null,
      code: '',
      repeat_pasword: null,
      emailError: null,
      fullnameError: null,
      usernameError: null,
      passwordError: null,
    };
  },

  components: {},

  created() { },
  mounted() {
    this.fetchBusinessUser();
  },
  methods: {
    gotoUpdateUsername() {
      this.mnx_navToBusinessPersonalUpdateUsername();
    },
    gotoUpdatePassword() {
      this.mnx_navToBusinessPersonalUpdatePassword();
    },

    gotoUpdateFullname() { 
      this.mnx_navToBusinessPersonalUpdateFullname();
    },

    gotoUpdateEmail() { 
      this.mnx_navToBusinessPersonalUpdateEmail();
    },
    goBack() {
      this.mnx_goBack();
    },

    editEmail() {
      this.emailError = userProfileValidator.emailValidator.validate(
        this.businessUser.email
      );
      if (this.emailError) return;

      BusinessUserApi.UpdateEmail(this.businessUser.email)
        .then((response) => {
          response;
        })
        .catch((error) => {
          this.mnx_backendErrorHandler(error);
        });
    },

    editFullname() {
      this.fullnameError = userProfileValidator.nameValidator.validate(
        this.businessUser.firstname
      );
      if (this.fullnameError) return;

      this.fullnameError = userProfileValidator.nameValidator.validate(
        this.businessUser.lastname
      );
      if (this.fullnameError) return;

      BusinessUserApi.UpdateFullname(
        this.businessUser.firstname,
        this.businessUser.lastname
      )
        .then((response) => {
          response;
        })
        .catch((error) => {
          this.mnx_backendErrorHandler(error);
        });
    },

    editUsername() {
      this.usernameError = userProfileValidator.usernameValidator.validate(
        this.businessUser.identity
      );
      if (this.usernameError) return;

      BusinessUserApi.UpdateUsername(this.businessUser.identity)
        .then((response) => {
          response;
        })
        .catch((error) => {
          this.mnx_backendErrorHandler(error);
        });
    },

    editPassword() {
      this.passwordError = userProfileValidator.passwordValidator.validate(
        this.businessUser.password
      );
      if (this.passwordError) return;

      if (this.businessUser.password != this.repeat_pasword) {
        this.passwordError = "please type in same password twice";
        return;
      }
      BusinessUserApi.UpdatePassword(this.businessUser.pasword)
        .then((response) => {
          response;
        })
        .catch((error) => {
          this.mnx_backendErrorHandler(error);
        });
    },

    sendCode() {
      this.errorMessage = userProfileValidator.emailValidator.validate(this.businessUser.email);
      if (this.errorMessage) return;
      UserAuthApi.sendAuthCodeToEmail(this.businessUser.email).then(() => {}).catch((error) => this.mnx_backendErrorHandler(error))
    },

    submitForm() {
      this.errorMessage = null;
      this.errorMessage = userProfileValidator.nameValidator.validate(this.businessUser.firstname);
      if (this.errorMessage) return;
      this.errorMessage = userProfileValidator.nameValidator.validate(this.businessUser.lastname);
      if (this.errorMessage) return;

      if (this.oldEmail !== this.businessUser.email) {
        this.errorMessage = userProfileValidator.emailValidator.validate(this.businessUser.email);
        if (this.errorMessage) return;
        this.errorMessage = userProfileValidator.authCodeValidator.validate(this.code);
        if (this.errorMessage) return;
      }

      BusinessUserApi.UpdateFullname(this.businessUser.firstname, this.businessUser.lastname).then(() => {
        if (this.oldEmail !== this.businessUser.email) {
          BusinessUserApi.UpdateEmail(this.businessUser.email, this.code).then(() => {
            this.mnx_navToBusinessPersonalProfileUpdated()
          }).catch((error) => {
            this.mnx_backendErrorHandler(error);
          });
        } else {
          this.mnx_navToBusinessPersonalProfileUpdated()
        }
      }).catch((error) => {
        this.mnx_backendErrorHandler(error);
      });
    },

    fetchBusinessUser() {
      BusinessUserApi.FetchUserProfile()
        .then((response) => {
          this.businessUser = response.data;
          businessUserUtils.fill(this.businessUser);
          this.oldEmail = this.businessUser.email;
          this.repeat_pasword = this.businessUser.pasword;
        })
        .catch((error) => {
          this.mnx_backendErrorHandler(error);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.container-background {
  @include container-background;

  .container-content {
    @include container-content;
    padding: 45px 0;
  }
}
</style>
