<template>
  <div class="row-flow-container">
    <div class="row-flow-header">
      <p class="callout">Business Management</p>
      <button class="form-control btn btn-primary ms-1" @click="goback()">
        Back to home
      </button>
    </div>
    <div
      class="row-flow-item-container"
      v-for="(business, index) in businessList"
      :key="index"
    >
      <div class="row-flow-item-subject-area">
        <p
          class="row-flow-item-subject-text"
          style="cursor: pointer"
          @click="viewBusiness(business)"
        >
          {{ business.identity }}
        </p>
      </div>
      <!-- <div class="row-flow-item-status-area">
        <p class="row-flow-item-status-text">{{ problem.category }}</p>
      </div>
      <div class="row-flow-item-notes-area">
        <p class="row-flow-item-notes-text">{{ getFormalizedDate(message) }}</p>
      </div> -->
    </div>
  </div>
</template>

<script>
import { AdminApi /*OIDUtils*/ } from "../../utils/index";
export default {
  name: "AdminManageBusiness",
  props: {},

  data() {
    return {
      businessList: null,
    };
  },

  components: {},

  created() {},
  mounted() {
    this.queryBusinesses();
  },
  methods: {
    goback() {
      this.mnx_goBack();
    },

    viewBusiness(business) {
      business;
    },
    queryBusinesses() {
      AdminApi.queryBusinesses()
        .then((response) => {
          this.businessList = response.data;
        })
        .catch((error) => {
          this.mnx_backendErrorHandler(error);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
