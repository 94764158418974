<template>
    <div>
        <div class="lf-body-item">
            <h5 class="lf-body-item-label" for="title">Title:{{ interviewQuestion.title }}</h5>
            <textarea class="lf-body-item-display-text minHeight" id="description" placeholder="Provide description." v-model.trim="interviewQuestion.description" readonly ref="textarea-descrisption" />
        </div>
        <div class="lf-body-item">
            <h5 class="lf-body-item-label" for="inputFormat">INPUT FORMAT(input arrives from the terminal / stdin):</h5>
            <textarea class="lf-body-item-display-text minHeight" id="inputFormat" placeholder="Provide input format." v-model.trim="interviewQuestion.inputFormat" readonly />
        </div>
        <div class="lf-body-item">
            <h5 class="lf-body-item-label" for="outputFormat">OUTPUT FORMAT(print output to the terminal / stdout):</h5>
            <textarea class="lf-body-item-display-text minHeight" id="outputFormat" placeholder="Provide output format." v-model.trim="interviewQuestion.outputFormat" readonly style="overflow:hidden" />
        </div>
        <div class="lf-body-item">
            <h5 class="lf-body-item-label" for="sampleInput">SAMPLE INPUT:</h5>
            <textarea class="lf-body-item-display-text minHeight" id="sampleInput" placeholder="Provide input sample." v-model.trim="interviewQuestion.sampleInput" readonly />
        </div>
        <div class="lf-body-item">
            <h5 class="lf-body-item-label" for="sampleOutput">SAMPLE OUTPUT:</h5>
            <textarea class="lf-body-item-display-text minHeight" id="sampleOutput" placeholder="Provide output sample." v-model.trim="interviewQuestion.sampleOutput" readonly />
        </div>
        <div class="lf-body-item">
            <h5 class="lf-body-item-label" for="scoring">SCORING:</h5>
            <textarea class="lf-body-item-display-text minHeight" id="scoring" placeholder="Provide scoring philosophy." v-model.trim="interviewQuestion.scoring" readonly />
        </div>
    </div>
</template>

<script>
import {
    interviewQuestionStruct,
} from "../../types/index";

export default {
    name: "CodingInterviewQuestionViewer",
    props: {
        modelValue: null,
    },

    data() {
        return {
            interviewQuestion: interviewQuestionStruct,
        };
    },

    watch: {
        modelValue: {
            immediate: true,
            handler: function (val) {
                this.interviewQuestion = val;
            },
        },
        interviewQuestion: function (val) {
            this.$emit("update:modelValue", val);
        },
    },
    created() { },
    components: {},
    mounted() {

    },
    updated() {
        this.$el.querySelectorAll('textarea').forEach(element => {
            this.textAreaAdjust(element)
        });
    },
    methods: {
        textAreaAdjust(element) {
            if (element) {
                element.style.height = "1px";
                element.style.height = (25 + element.scrollHeight) + "px";
            }
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.minHeight {min-height: 125px;}
</style>
