<template>
  <div class="container-background">
      <div class="container-content">
        <div class="content-header-container">
          <p class="content-header-title">
            <span v-if="total">{{pagination.total}}</span>
            &nbsp;Candidates
          </p>
          <div class="flex-space"></div>
          <div class="content-search">
            <input type="text" placeholder="search..." v-model="pagination.keyword">
            <img src="@/assets/search.png" alt="search" class="search-icon" @click="searchAction">
          </div>
          <button class="content-btn" @click="gotoAddApplicant">Add Candidates</button>
        </div>
        <div class="list-container">
          <template v-if="applicantList.length > 0">
            <div class="content-item-container" v-for="(applicant, index) in applicantList" :key="index">
              <p class="item-title item-bottom">{{ applicant.firstname }} {{ applicant.lastname }}</p>
              <p class="item-light">{{ applicant.mobile }}</p>
              <p class="item-light">{{ applicant.email }}</p>
              <div class="item-operation-container item-top">
                <span @click="editApplicant(applicant)">Edit</span>
              </div>
            </div>
          </template>
          <div class="empty-container" v-if="applicantList.length == 0">
            <img src="@/assets/logo-pic.png" alt="magic leaps logo">
            <p class="opps">Oops !</p>
            <p class="opps-sub">There is nothing here.</p>
          </div>
          <div class="spinner-container" v-if="loading">
            <div class="spinner-grow text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
        <CustomPagination :total="pagination.total" v-model:current="pagination.current" @pageChange="pageChange" />
      </div>
  </div>
</template>

<script>
import { BusinessUserApi, applicantUtils, OIDUtils } from "../../utils/index";
import { applicantStatusEnum } from "../../types/index";

export default {
  name: "BusinessApplicants",
  props: {},

  data() {
    return {
      pagination: {
        total: 0,
        current: 1,
        keyword: ''
      },
      loading: false,
      applicantList: [],
    };
  },

  components: {},

  created() {},
  mounted() {
    this.queryApplicants();
  },
  methods: {
    queryApplicants() {
      this.loading = true;
      BusinessUserApi.QueryApplicants(applicantStatusEnum.OPEN, this.pagination.keyword)
        .then((response) => {
          this.applicantList = response.data;
          this.loading = false;
        })
        .catch((error) => {
          this.mnx_backendErrorHandler(error);
          this.loading = false;
        });
    },
    searchAction() {
      this.pagination.total = 0
      this.pagination.current = 1
      this.queryApplicants()
    },
    gotoAddApplicant() {
      applicantUtils.fill(null);
      applicantUtils.fillApplicantId(null);
      this.mnx_navToBusinessNewApplicant();
    },
    editApplicant(applicant) {
      applicantUtils.fill(applicant);
      applicantUtils.fillApplicantId(OIDUtils.FromJson(applicant));
      this.mnx_navToBusinessNewApplicant();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.container-background {
  @include container-background;

  .container-content {
    @include container-content;
    padding: 45px 0;
  }
}
</style>
