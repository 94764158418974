import { ProblemTitleValidator } from "./problemTitleValidator"
import { ProblemDescriptionValidator } from "./problemDescriptionValidator"
import {ProblemInputFormatValidator} from "./problemInputFormatValidator"
import {ProblemOutputFormatValidator} from "./problemOutputFormatValidator"
import {ProblemSampleInputValidator} from "./problemSampleInputValidator"
import {ProblemSampleOutputValidator} from "./problemSampleOutputValidator"
import {ProblemScoringValidator} from "./problemScoringValidator"

class ProblemValidator {
    constructor() {
        this.problemTitleValidator = new ProblemTitleValidator();
        this.problemDescriptionValidator = new ProblemDescriptionValidator();
        this.problemInputFormatValidator = new ProblemInputFormatValidator();
        this.problemOutputFormatValidator = new ProblemOutputFormatValidator();
        this.problemSampleInputValidator = new ProblemSampleInputValidator();
        this.problemSampleOutputValidator = new ProblemSampleOutputValidator();
        this.problemScoringValidator = new ProblemScoringValidator();
    }
}

const problemValidator = new ProblemValidator();

export { problemValidator }
