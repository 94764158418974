import { FileLengthValidator } from './fileLengthValidator'
const formatMessage = "less then 16M bytes";
const minLength = 2;
const maxLength = 10 * 1024 * 1024;

class UploadFileLengthValidator extends FileLengthValidator {
    constructor() {
        super(
            "Upload file",
            formatMessage,
            null,
            minLength,
            maxLength
        );
    }

}

const uploadFileLengthValidator = new UploadFileLengthValidator();
export { uploadFileLengthValidator }
