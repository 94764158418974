<template>
  <div class="container-background">
    <div class="container-content">
      <div class="content-header-container">
        <p class="content-header-title">Personal Profile</p>
      </div>
      <form @submit.prevent="updateProfile">
        <div class="lf-body-container">
          <div class="lf-body-content">
            <div class="lf-body-item">
              <h5 class="lf-body-item-label" for="jobPosition">* Firstname:</h5>
              <input class="lf-body-item-text-input max700" type="text" id="firstname" v-model.trim="peronalUser.firstname" placeholder="firstname" />
            </div>
            <div class="lf-body-item">
              <h5 class="lf-body-item-label" for="lastname">Lastname:</h5>
              <input class="lf-body-item-text-input max700" type="text" id="lastname" v-model.trim="peronalUser.lastname" placeholder="lastname" />
            </div>
            <div class="lf-body-item">
              <h5 class="lf-body-item-label" for="email">Email:</h5>
              <input class="lf-body-item-text-input max700" type="text" id="email" v-model.trim="peronalUser.email" placeholder="email" />
            </div>
            <div class="lf-body-item">
              <h5 class="lf-body-item-label" for="mobile">Mobile:</h5>
              <input class="lf-body-item-text-input max700" type="text" id="mobile" v-model.trim="peronalUser.mobile" placeholder="mobile" />
            </div>
          </div>
        </div>
        <div class="lf-body-container">
        <div class="lf-body-item">
          <h5 class="lf-body-item-label" for="scoring">* Resume:</h5>
          <div class="lf-body-item-file">
            <p>{{resumeDisplay}}</p>
            <button class="if-cancel" type="button" aria-label="resume" aria-describedby="resume-file" @click="selectResumeFile">resume</button>
            <input ref="resumeFileInput" type="file" :accept="acceptableResumeFileType" style="display: none" @change="onChange($event)" />
          </div>
        </div>
      </div>
        <p v-show="hasError()" class="if-errorInput">{{ errorMessage }}</p>
        <div class="lf-submit-container">
          <button class="if-cancel" type="button" @click="goBack">Cancel</button>
          <button class="if-submit" type="submit">Submit</button>
        </div>
      </form>
    </div>
  </div>
  <!-- <div class="report-board-container">
    <div class="report-board-header">
      <p class="report-board-title">Personal Profile</p>
    </div>
    <div class="report-board-body">
      <div class="report-card-container">
        <p class="report-card-title">Basic</p>
        <div class="report-item-container">
          <span class="report-item-name">Firstname:</span>
          <input class="report-item-value" type="text" id="firstname" v-model.trim="peronalUser.firstname"
            placeholder="firstname" />
        </div>
        <div class="report-item-container">
          <span class="report-item-name">Lastname:</span>
          <input class="report-item-value" type="text" id="lastname" v-model.trim="peronalUser.lastname"
            placeholder="lastname" />
        </div>
        <div class="report-item-container">
          <span class="report-item-name">Email:</span>
          <input class="report-item-value" type="text" id="email" v-model.trim="peronalUser.email"
            placeholder="email" />
        </div>
        <div class="report-item-container">
          <span class="report-item-name">Mobile:</span>
          <input class="report-item-value" type="text" id="mobile" v-model.trim="peronalUser.mobile"
            placeholder="mobile" />
        </div>
      </div>
    </div>

    <div class="report-card-container">
      <p class="report-card-title">Basic</p>
      <div class="report-item-container">
        <span class="report-item-name">Resume:</span>
        <span class="report-item-value_clickable" @click="selectResumeFile">
          {{ resumeDisplay }}
        </span>
        <input ref="fileInput" type="file" :accept="acceptableResumeFileType" style="display: none"
          @change="onChange($event)" />
      </div>
    </div>
    <div class="lf-submit-container">
      <button class="if-cancel" type="button" @click="goBack">Back</button>
      <button class="if-submit" type="submit" @click="updateProfile">Update</button>
    </div>
    <p v-show="hasError()" class="if-errorInput">{{ errorMessage }}</p>
  </div> -->
</template>

<script>
import { PersonalUserApi, userProfileValidator } from "../../utils/index";
import { personalUserStruct } from "../../types/index";
export default {
  name: "PersonalProfile",
  props: {},

  data() {
    return {
      peronalUser: personalUserStruct,
      errorMessage: null,
      resumeDisplay: 'Upload resume (Only Accept PDF File)',
      acceptableResumeFileType: "*.pdf",
      resumeLink: null,
      resumeLinkname: null,
    };
  },

  components: {},

  created() { },
  mounted() {
    this.fetchPersonalUser();
  },
   watch: {
    peronalUser(newVaule/*, oldQuestion*/) {
      if(newVaule){
        this.resumeDisplay = newVaule.resumeFilename;
      }
    }
  },
  methods: {
    goBack() {
      this.mnx_goBack();
    },
    hasError() {
      return this.errorMessage;
    },
    selectResumeFile() {
      this.$refs.resumeFileInput.click();
    },

    onChange(event) {
      let file = event.target.files[0];
      let reader = new FileReader();
      reader.addEventListener(
        "load",
        function () {
          this.peronalUser.resume = reader.result;
          this.peronalUser.resumeFilename = file.name;
          this.resumeDisplay = file.name;
          this.resumeLink = this.peronalUser.resume;
          this.resumeLinkname = this.peronalUser.resumeFilename;
        }.bind(this),
        false
      );
      reader.readAsDataURL(file);
    },

    updateProfile() {
      this.errorMessage = userProfileValidator.nameValidator.validate(this.peronalUser.firstname);
      if (this.hasError()) return;

      this.errorMessage = userProfileValidator.nameValidator.validate(this.peronalUser.lastname);
      if (this.hasError()) return;

      this.errorMessage = userProfileValidator.emailValidator.validate(this.peronalUser.email);
      if (this.hasError()) return;

      // this.errorMessage = userProfileValidator.mobileValidator.validate(this.peronalUser.mobile);
      // if (this.hasError()) return;

      this.errorMessage = userProfileValidator.resumenameValidator.validate(this.peronalUser.resumeFilename);
      if (this.hasError()) return;

      this.errorMessage = userProfileValidator.resumeFileValidator.validate(this.peronalUser.resume);
      if (this.hasError()) return;

      PersonalUserApi.updateProfile(this.peronalUser).then(response => {
        response;
        this.mnx_navToPersonalProfileUpdated();
      }).catch((error) => {
        this.mnx_backendErrorHandler(error);
      });

    },

    fetchPersonalUser() {
      PersonalUserApi.fetchProfile()
        .then((response) => {
          this.peronalUser = response.data.personal;
          this.resumeLink = this.peronalUser.resume;
          this.resumeLinkname = this.peronalUser.resumeFilename;
        })
        .catch((error) => {
          this.mnx_backendErrorHandler(error);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.container-background {
  @include container-background;

  .container-content {
    @include container-content;
    padding: 45px 0;
  }
}
</style>
