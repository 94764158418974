import { TextValidator } from './textValidator'
const formatMessage = "less then 256 characters";
const pattern = /^[ -~|\n]{8,256}$/;
const minLength = 8;
const maxLength = 256;

class CodingInterviewJobPositionValidator extends TextValidator {
    constructor() {
        super(
            "Job Position",
            formatMessage,
            [
                {
                    regex: pattern,
                    message: " contains invalid charactors."
                }
            ],
            minLength,
            maxLength
        );
    }

}

export { CodingInterviewJobPositionValidator }
