import { store } from "../../plugins/index";

class ApplicantUtils {
    constructor(store) {
        this.store = store;
    }

    fill(applicant) {
        this.applicant = applicant;
    }

    fetch() {
        return this.applicant;
    }
    
    fillApplicantId(id) {
        this.applicant_id = id;
    }

    fetchApplicantId() {
        return this.applicant_id;
    }
}

const applicantUtils = new ApplicantUtils(store);

export { applicantUtils };