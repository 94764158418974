<template>
  <div class="footer-container-backgroud">
    <div class="footer-container-content">
      <ul>
        <li>
            <img class="logo" alt="freeleaps logo" src="@/assets/logo_for_blue.png" />
        </li>
        <!-- <li>
            <img class="logo" alt="freeleaps logo" src="@/assets/facebook.png" />
            <img class="logo" alt="freeleaps logo" src="@/assets/instagram.png" />
        </li> -->
        <li>
            <span class="copyright">Copyright © 2022 Magicleaps. All rights reserved.</span>
        </li>
      </ul>
      <div class="flex-space"></div>
      <ul class="to-left">
        <!-- <li>
            <button type="button" class="btn btn-link text-bold" @click="gotoMagicleaps">About Magicleaps</button>
        </li>
        <li>
            <button type="button" class="btn btn-link text-bold" @click="gotoFreeleaps">About Freeleaps</button>
        </li> -->
        <li>
            <button type="button" class="btn btn-link text-bold" @click="gotoDesginerFronDoor">Designer Portal</button>
        </li>
        <li>
            <button type="button" class="btn btn-link text-bold" @click="gotoPricing">Contact Us</button>
        </li>
      </ul>
      <!-- <ul class="to-left">
        <li>
            <button type="button" class="btn btn-link text-bold">Help</button>
        </li>
        <li>
            <button type="button" class="btn btn-link text-bold">Terms & Conditions</button>
        </li>
        <li>
            <button type="button" class="btn btn-link text-bold">Privacy Policy</button>
        </li>
      </ul> -->
    </div>
  </div>
</template>
<script>
export default {
  name: "FooterComponent",
  components: {},
  computed: {},
  methods: {
    gotoMagicleaps() {
      this.mnx_navToAboutMagicleapsPlatform();
    },
    gotoFreeleaps() {
      this.mnx_navToAboutFreeleapsCompany();
    },
    gotoPricing() {
      this.mnx_navToContactUs();
    },
    gotoDesginerFronDoor() {
      this.mnx_navToDesignerFrontpage();
    }
  },
};
</script>

<style scoped lang="scss">
.footer-container-backgroud {
    @include container-background;
    background-color: #222222;

    .footer-container-content {
        @include container-content;
        padding: 88px 0;
        color: white;
        display: flex;

        ul {list-style: none;
            li { margin-bottom: 20px; text-align: left;
                img {height: 32px; margin-right: 20px;}
                .copyright {font-size: 15px; color: #777777;}
                .btn-link {color: white;}
                &:last-child {margin-bottom: 0;}
            }
            &.to-left {margin-left: 68px;}
        }
    }
}
</style>