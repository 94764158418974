<template>
  <div class="container-background">
    <div class="container-content">
      <div class="content-header-container">
        <p class="content-header-title">Business Profile</p>
      </div>
      <form @submit.prevent="submitForm">
        <div class="lf-body-container">
          <div class="lf-body-content">
            <div class="lf-body-item">
              <h5 class="lf-body-item-label" for="businessName">Company name</h5>
              <input type="text" class="lf-body-item-text-input max700" aria-label="businessName" aria-describedby="business-name-input" id="businessName" placeholder="Enter your company name" v-model.trim="businessProfile.name" />
            </div>
            <div class="lf-body-item">
              <h5 class="lf-body-item-label" for="businessWebsite">Company website</h5>
              <input type="text" class="lf-body-item-text-input max700" aria-label="businessWebsite" aria-describedby="business-website-input" id="businessWebsite" placeholder="Enter your company website" v-model.trim="businessProfile.website" />
            </div>
            <div class="lf-body-item">
              <h5 class="lf-body-item-label" for="businessEmail">Company Email</h5>
              <input type="text" class="lf-body-item-text-input max700" aria-label="businessEmail" aria-describedby="business-Email-input" id="businessEmail" placeholder="Enter your company Email" v-model.trim="businessProfile.email" />
            </div>
          </div>
        </div>
        <p v-show="hasError()" class="if-errorInput">{{ errorMessage }}</p>
        <div class="lf-submit-container">
          <button class="if-cancel" type="button" @click="goBack">Cancel</button>
          <button class="if-submit" type="submit">Submit</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { BusinessUserApi, businessProfileValidator } from "../../utils/index";
import { businessProfileStruct } from "../../types/index";

export default {
  name: "BusinessBusinessProfile",
  props: {},

  data() {
    return {
      businessProfile: businessProfileStruct,
      errorMessage: null
    };
  },

  created() { },
  components: {},
  mounted() {
    this.queryBusiness()
  },
  methods: {
    goBack() {
      this.mnx_goBack();
    },
    hasError() {
      return this.errorMessage;
    },

    queryBusiness() {
      BusinessUserApi.FetchBusinessProfile(
      )
        .then((response) => {
          this.businessProfile = response.data;
        })
        .catch((error) => {
          this.mnx_backendErrorHandler(error);
        });

    },

    async submitForm() {
      this.errorMessage = businessProfileValidator.nameValidator.validate(this.businessProfile.name);
      if (this.hasError()) return;
      this.errorMessage = businessProfileValidator.emailValidator.validate(this.businessProfile.email);
      if (this.hasError()) return;
      console.log('businessProfileValidator.websiteValidator:', businessProfileValidator.websiteValidator)
      this.errorMessage = businessProfileValidator.websiteValidator.validate(this.businessProfile.website);
      if (this.hasError()) return;
      BusinessUserApi.UpdateBusinessProfile(this.businessProfile).then((response) => {
        response;
        this.mnx_navToBusinessProfileUpdated();
      }).catch((error) => {
        this.mnx_backendErrorHandler(error);
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.container-background {
  @include container-background;

  .container-content {
    @include container-content;
    padding: 45px 0;
  }
}
</style>
