<template>
    <div class="main-body">
        <div class="story-board">
            <div class="focus-area">
                <p class="callout">Update username</p>
                <div class="form-group border-0">
                    <form @submit.prevent="updateUsername">
                        <div class="input-group-div">
                            <span class="input-label" id="current-username">Current</span>
                            <span class="input-username-text" aria-label="current-username"
                                aria-describedby="current-username">{{ currentUsername }}</span>
                        </div>
                        <div class="input-group-div">
                            <span class="input-label" id="username-input">New</span>
                            <input class="input-username-input" type="text" aria-label="Username-input"
                                aria-describedby="username input" v-model="newUsername" />
                        </div>
                        <div class="input-group-div">
                            <button class="input-username-cancel" type="button" @click="goBack">Cancel</button>
                            <button class="input-username-submit" type="submit">Submit</button>
                        </div>
                        <p v-if="hasError()" class="errorInput">{{ errorMessage }}</p>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { userProfileValidator, BusinessUserApi, businessUserUtils } from "../../utils/index";

export default {
    name: "BusinessPersonalUpdateUsername",
    props: {
    },

    data() {
        return {
            businessUser: null,
            currentUsername: null,
            newUsername: null,
            errorMessage: null,
        };
    },

    created() { },
    mounted() {
        this.businessUser = businessUserUtils.fetch();
        this.currentUsername = this.businessUser.identity;
    },
    methods: {
        hasError() {
            return this.errorMessage !== null;
        },
        async updateUsername() {
            this.errorMessage = userProfileValidator.usernameValidator.validate(
                this.newUsername
            );
            if (this.hasError()) {
                return;
            }
            BusinessUserApi.CheckUsernameAvailability(this.newUsername)
                .then((response) => {
                    if (response.data && response.data.available == true) {
                        BusinessUserApi.UpdateUsername(this.newUsername)
                            .then((response) => {
                                response;
                                this.mnx_navToBusinessPersonalUsernameUpdated(this.newUsername);
                            })
                            .catch((error) => {
                                this.mnx_backendErrorHandler(error);
                            });
                    } else {
                        this.errorMessage = "The username has been taken.";
                    }
                })
                .catch((error) => {
                    this.mnx_backendErrorHandler(error);
                });
        },

        goBack() {
            this.mnx_goBack();
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.input-group-div {
    @extend .input-group;
    @extend .my-3;
}

.input-label {
    @extend .h-90;
    @extend .w-25;
    @extend .text-start;
}

.input-username-text {
    @extend .form-control;
    @extend .my-0;
    @extend .py-0;
    @extend .text-center;
}

.input-username-input {
    @extend .form-control;
    @extend .my-0;
    @extend .py-0;
    @extend .text-center;
}

.input-username-submit {
    @extend .form-submit;
}

.input-username-cancel {
    @extend .form-cancel;
}
</style>
