import { UsernameValidator } from "./usernameValidator"
import {PasswordValidator} from "./passwordValidator"
import {NameValidator} from "./nameValidator"
import {MobileValidator} from "./mobileValidator"
import {EmailValidator} from "./emailValidator"
import {ImageValidator} from "./imageValidator"
import {AuthCodeValidator} from "./authCodeValidator"
import {CountryNameValidator} from "./countryNameValidator"
import { FilenameValidator } from "./filenameValidator"
import {TextFileValidator} from "./textFileValidator"

class ProfileValidator {
    constructor() {
        this.usernameValidator = new UsernameValidator();
        this.passwordValidator = new PasswordValidator();
        this.nameValidator = new NameValidator();
        this.mobileValidator = new MobileValidator();
        this.emailValidator = new EmailValidator();
        this.imageValidator = new ImageValidator();
        this.authCodeValidator = new AuthCodeValidator();
        this.countryNameValidator = new CountryNameValidator();
        this.resumenameValidator = new FilenameValidator();
        this.resumeFileValidator = new TextFileValidator();
    }
}

const userProfileValidator = new ProfileValidator();

export { userProfileValidator }