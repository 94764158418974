<template>
  <div class="story-board">
    <div class="focus-area">
      <p class="callout">Find a trustworthy partner, simplify the work, secure your return.</p>
      <div class="form-group">
        <form @submit.prevent="sendCodeToEmail">
          <div class="input-group">
            <input class="form-control w-70 me-1" type="text" :placeholder="'Email'" v-model="email" />
            <button ref="submitButton" type="submit" class="form-control btn btn-primary ms-1">Start</button>
          </div>
        </form>
        <p class="errorInput" v-if="message != null">{{ message }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import {
  AdminApi,
  userProfileValidator,
} from "../../utils/index";
export default {
  name: "AdminFrontDoor",
  props: {},

  data() {
    return {
      email: null,
      message: null,
    };
  },

  components: {},

  created() { },
  mounted() {
    if (this.mnx_IsKeepUserSignedIn() && this.mnx_isUserAuthenticated()) {
      this.mnx_navAfterSignedin();
    }
  },
  methods: {
    sendCodeToEmail() {
      this.message = null;
      if (this.email === null || this.email.length < 1) {
        this.message = "Please type in your email";
        return;
      }

      this.message = userProfileValidator.emailValidator.validate(
        this.email
      );

      if (this.message != null) return;

      this.$refs.submitButton.disabled = true;
      AdminApi.sendCodeToEmail(this.email).then(
        (response) => {
          response;

          this.$refs.submitButton.disabled = false;
          this.mnx_navToAdminSigninByEmailAndCode(this.email);
        }
      ).catch(
        (error) => {

          this.$refs.submitButton.disabled = false;
          console.log(error);
        }
      )

    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
