import { TextValidator } from './textValidator'
const nameFormatMessage = "less than 256 letters";
const namePattern = /^([ -~|\n]{3,256}$)/;
const nameMinLength = 3;
const nameMaxLength = 256;

class LoosedNameValidator extends TextValidator {
    constructor() {
        super(
            "Name",
            nameFormatMessage,
            [
                {
                    regex: namePattern,
                    message: " can only contain printable letters."
                }
            ],
            nameMinLength,
            nameMaxLength
        );
    }

}

export { LoosedNameValidator }