<template>
  <div class="container-background">
    <div class="container-content">
      <div class="viewer-container">
        <p class="viewer-title viewer-main-title">Coding Interview Report</p>
        <p class="viewer-title">Job</p>
        <div class="viewer-job-container row">
          <div class="col viewer-content-container">
            <span class="viewer-content viewer-content-title">Candidate</span>
            <span class="viewer-content" @click="viewApplicant()">{{codingInterview?.applicantFullname}}</span>
          </div>
          <div class="col viewer-content-container">
            <span class="viewer-content viewer-content-title">Position</span>
            <span class="viewer-content" @click="viewPosition()">{{codingInterview?.jobTitle}}</span>
          </div>
        </div>
        <p class="viewer-title">Interview</p>
        <div class="viewer-interview-container row">
          <div class="col viewer-content-container column">
            <span class="viewer-content">Completion time</span>
            <span class="viewer-title">{{FormlizeDatetime(codingInterview?.deadline?.$date)?.[1]}}</span>
            <span class="viewer-content viewer-content-title">{{FormlizeDatetime(codingInterview?.deadline?.$date)?.[0]}}</span>
          </div>
        </div>
        <p class="viewer-title">Report</p>
        <div class="viewer-report-container row">
          <div class="col viewer-content-container">
            <span class="viewer-content viewer-content-title">Question</span>
            <span class="viewer-content" @click="viewQuestion()">{{solutionSubmission?.problemTitle}}</span>
          </div>
          <div class="col viewer-content-container">
            <span class="viewer-content viewer-content-title">Score</span>
            <span class="viewer-content" @click="viewScoreDetails()">{{solutionSubmission?.eveluationScore}}</span>
          </div>
          <div class="col viewer-content-container">
            <span class="viewer-content viewer-content-title">Submission</span>
            <!-- <span class="viewer-content">Java Engineer</span> -->
            <a class="viewer-content" v-if="solutionSubmission" :href="solutionSubmission.submission" :download="solutionSubmission.submissionFilename">{{ solutionSubmission.submissionFilename }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {BusinessUserApi,codingInterviewUtils,applicantUtils,OIDUtils,jobPositionUtils,codingSubmissionUtils} from "../../utils/index";
import {codingInterviewStruct,solutionSubmissionStruct,solutionSubmissionStructUtils} from "../../types/index";
import moment from 'moment'
export default {
  name: "BusinessViewSubmissionScoreDetails",
  props: {
    id: {
      required: true,
      type: String,
    },
  },

  data() {
    return {
      codingInterview: codingInterviewStruct,
      solutionSubmission: solutionSubmissionStruct,
    };
  },

  created() { },
  components: {},
  mounted() {
    // this.codingInterview = codingInterviewUtils.fetch();
    // if (
    //   this.codingInterview &&
    //   OIDUtils.FromJson(this.codingInterview) != this.id
    // ) {
    //   this.codingInterview = null;
    // }
    // if (!this.codingInterview) {
      this.fetchInterview();
    // } else {
    //   this.fetchSolutionSubmission();
    // }
  },
  methods: {
    goBack() {
      this.mnx_goBack();
    },
    FormlizeDatetime(datetime) {
      if (!datetime) return [];
      return moment(datetime).utc().format('YYYY-MM-DD HH:mm').split(' ');
    },
    GetSubmissionStatus(submission) {
      return solutionSubmissionStructUtils.getStatusText(submission.status);
    },
    fetchInterview() {
      BusinessUserApi.FetchCodingInterview(this.id).then((response) => {
        this.codingInterview = response.data.interview;
        codingInterviewUtils.fill(this.codingInterview);
        codingInterviewUtils.fillId(OIDUtils.FromJson(this.codingInterview));
        this.solutionSubmission = response.data.submission;
        if (this.solutionSubmission) {
          codingSubmissionUtils.fill(this.solutionSubmission);
          codingSubmissionUtils.fillId(OIDUtils.FromJson(this.solutionSubmission));
        } else {
          this.solutionSubmission = {}
        }
      }).catch((error) => {
        this.mnx_backendErrorHandler(error);
      });
    },

    viewApplicant() {
      BusinessUserApi.FetchApplicant(this.codingInterview.applicant)
        .then((response) => {
          applicantUtils.fill(response.data);
          this.mnx_navToBusinessEditApplicant();
        })
        .catch((error) => {
          this.mnx_backendErrorHandler(error);
        });
    },
    viewPosition() {
      BusinessUserApi.FetchJob(this.codingInterview.jobPosition)
        .then((response) => {
          jobPositionUtils.fill(response.data);
          jobPositionUtils.fillJobPositionId(this.codingInterview.jobPosition);
          this.mnx_navToBusinessEditJobPosition();
        })
        .catch((error) => {
          this.mnx_backendErrorHandler(error);
        });
    },
    viewQuestion() {
      this.mnx_navToBusinessViewCodingProblem(this.solutionSubmission.problem);
    },
    viewScoreDetails() { 
      this.mnx_navToBusinessViewScoreDetails();
    },
    fetchSolutionSubmission() {
      BusinessUserApi.FetchSolutionSubmission(
        this.codingInterview.solutionSubmission
      )
        .then((response) => {
          this.solutionSubmission = response.data.submission;
          codingSubmissionUtils.fill(this.solutionSubmission);
          codingSubmissionUtils.fillId(OIDUtils.FromJson(this.solutionSubmission));
        })
        .catch((error) => {
          this.mnx_backendErrorHandler(error);
        });
    },
  },
};
</script>
<style scoped lang="scss">
.container-background {
  @include container-background;

  .container-content {
    @include container-content;
    padding: 50px 0;
    display: flex;
    justify-content: center;
  }
}
.viewer-container {max-width: 922px; width: 100%;
  .viewer-main-title {margin-bottom: 35px;}
  .viewer-title {font-size: 28px; line-height: 36px; font-weight: 700; margin-bottom: 26px; color: #050505; text-align: center;}
  .viewer-content-container {display: flex; align-items: center; justify-content: center;
    .viewer-content {font-size: 16px; line-height: 21px; color: #585858;}
    .viewer-content-title {font-weight: 700; color: #050505; margin-right: 20px;}
    &.column {flex-direction: column;
      .viewer-content {margin-bottom: 18px;}
      .viewer-title {margin-bottom: 5px;}
      .viewer-content-title {margin-bottom: 0; margin-right: 0;}
    }
  }
  .viewer-job-container {min-height: 70px; padding: 10px; margin-bottom: 35px; background-color: white;}
  .viewer-interview-container {min-height: 150px; padding: 10px; margin-bottom: 35px; background-color: white;}
  .viewer-report-container {min-height: 112px; padding: 10px; margin-bottom: 35px; background-color: white;}
}
</style>
