<template>
  <div class="container-background">
    <div class="container-content">
        <div class="success-container">
            <img src="@/assets/success.png" alt="">
            <p class="success-title">Business Profile</p>
            <p class="success-subtitle">has been updated</p>
            <button class="success-back" @click="goBack">Back</button>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BusinessProfileUpdated",
  props: {},
  data() {
    return {};
  },
  components: {},
  created() {},
  mounted() {},
  methods: {
    goBack() {
      this.mnx_goBack();
    },},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.container-background {
  @include container-background;

  .container-content {
    @include container-content;
    padding: 120px 0;
  }
}
</style>
