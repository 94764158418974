<template>
  <div class="container-background">
      <div class="container-content">
        <div class="content-header-container">
          <p class="content-header-title">Completed Interviews</p>
          <div class="flex-space"></div>
          <div class="content-search">
            <input type="text" placeholder="search..." v-model="pagination.keyword">
            <img src="@/assets/search.png" alt="search" class="search-icon" @click="searchAction">
          </div>
        </div>
        <div class="list-container">
          <template v-if="codingInterviewList.length > 0">
            <div class="content-item-container" v-for="(interview, index) in codingInterviewList" :key="index">
              <p class="item-title item-bottom">{{ interview.jobTitle }}</p>
              <p class="item-content item-bottom">{{ interview.applicantFullname }}</p>
              <p class="item-light">{{ FormlizeDate(interview) }}</p>
              <div class="item-operation-container item-top">
                <span @click="viewReport(interview)">View Report</span>
              </div>
            </div>
          </template>
          <div class="empty-container" v-if="codingInterviewList.length == 0">
            <img src="@/assets/logo-pic.png" alt="magic leaps logo">
            <p class="opps">Oops !</p>
            <p class="opps-sub">There is nothing here.</p>
          </div>
          <div class="spinner-container" v-if="loading">
            <div class="spinner-grow text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
        <CustomPagination :total="pagination.total" v-model:current="pagination.current" @pageChange="pageChange" />
      </div>
  </div>
  <!-- <div class="row-flow-container">
    <div class="row-flow-header">
      <p class="callout">Completed Interviews</p>
    </div>
    <div class="row-flow-item-container" style="cursor: pointer" v-for="(interview, index) in codingInterviewList"
      :key="index" @click="viewReport(interview)">
      <div class="row-flow-item-subject-area">

        <p class="row-flow-item-status-text">{{ interview.businessName }}</p>
        <p class="row-flow-item-subject-text">
          {{ interview.jobTitle }}
        </p>
      </div>
      <div class="row-flow-item-status-area">
      </div>
      <div class="row-flow-item-notes-area">{{ FormlizeDate(interview.startTime) }}
      </div>
    </div>
  </div> -->
</template>

<script>
import {PersonalUserApi, OIDUtils, DateUtils, codingInterviewUtils} from "../../utils/index";
import {interviewStatusStatusEnum} from "../../types/index";
export default {
  name: "PersonalCompletedInterviews",
  props: {},
  data() {
    return {
      codingInterviewList: [],
      pagination: {
        total: 0,
        current: 1,
        keyword: ''
      },
      loading: false
    };
  },
  components: {},
  created() { },
  mounted() {
    this.queryCodingInterview();
  },
  methods: {
    FormlizeDate(startTime) {
      return DateUtils.FromJsonToDateString(startTime);
    },
    viewReport(interview) {
      codingInterviewUtils.fill(interview);
      codingInterviewUtils.fillId(OIDUtils.FromJson(interview));
      this.mnx_navToPersonalViewCodingInterviewReport(OIDUtils.FromJson(interview));
    },
    pageChange() {},
    queryCodingInterview() {
      this.loading  = true
      PersonalUserApi.queryCodingInterview([interviewStatusStatusEnum.COMPLETED], this.pagination.keyword).then((response) => {
        this.codingInterviewList = response.data;
      this.loading  = false
      }).catch((error) => {
        this.mnx_backendErrorHandler(error);
        this.loading  = false
      });
    },
    searchAction() {
      this.pagination.total = 0
      this.pagination.current = 1
      this.queryCodingInterview()
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.container-background {
  @include container-background;

  .container-content {
    @include container-content;
    padding: 45px 0;
  }
}
</style>
