<template>
    <div class="input-box">
        <div class="input-content" @keydown="keydown" @keyup="keyup" @paste="paste" @mousewheel="mousewheel" @input="inputEvent">
            <input max="9" min="0" maxlength="1" data-index="0" v-model.trim.number="input[0]" type="number" ref="firstinput"/>
            <input max="9" min="0" maxlength="1" data-index="1" v-model.trim.number="input[1]" type="number"/>
            <input max="9" min="0" maxlength="1" data-index="2" v-model.trim.number="input[2]" type="number"/>
            <input max="9" min="0" maxlength="1" data-index="3" v-model.trim.number="input[3]" type="number"/>
            <input max="9" min="0" maxlength="1" data-index="4" v-model.trim.number="input[4]" type="number"/>
            <input max="9" min="0" maxlength="1" data-index="5" v-model.trim.number="input[5]" type="number"/>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                pasteResult: [],
            };
        },
        props: ['code'],
        computed: {
            input() {
                // console.log('computed input this.code:', this.code)
                // console.log('computed input this.pasteResult:', this.pasteResult)
                if (this.code) {
                    const codeArray = Array.isArray(this.code) ? this.code : this.code.toString().split('')
                    const pr = codeArray.slice(0,6);
                    for (let i = pr.length; i < 6; i++) {
                        // console.log('add at', i)
                        pr.push('')
                    }
                    return pr;
                } else if (this.pasteResult.length === 6) {
                    return this.pasteResult;
                } else {
                    return ['', '', '', '', '', ''];
                }
            }
        },
        methods: {
            inputEvent(e) {
                var index = e.target.dataset.index * 1;
                var el = e.target;
                // el.value = el.value.replace(/Digit|Numpad/i, '').replace(/1/g, '').slice(0, 1);
                el.value = el.value.replace(/Digit|Numpad/i, '').slice(0, 1);
                this.input[index] = el.value
            },
            keydown(e) {
                var index = e.target.dataset.index * 1;
                var el = e.target;
                if (e.key === 'Backspace') {
                    if (this.input[index].length > 0) {
                            this.input[index] = ''
                    } else {
                        if (el.previousElementSibling) {
                            el.previousElementSibling.focus()
                            this.input[index - 1] = ''
                        }
                    }
                } else if (e.key === 'Delete') {
                    if (this.input[index].length > 0) {
                            this.input[index] = ''
                    } else {
                        if (el.nextElementSibling) {
                            this.input[1] = ''
                        }
                    }
                    if (el.nextElementSibling) {
                        el.nextElementSibling.focus()
                    }
                } else if (e.key === 'Home') {
                    el.parentElement.children[0] && el.parentElement.children[0].focus()
                } else if (e.key === 'End') {
                    el.parentElement.children[this.input.length - 1] && el.parentElement.children[this.input.length - 1].focus()
                } else if (e.key === 'ArrowLeft') {
                    if (el.previousElementSibling) {
                        el.previousElementSibling.focus()
                    }
                } else if (e.key === 'ArrowRight') {
                    if (el.nextElementSibling) {
                        el.nextElementSibling.focus()
                    }
                } else if (e.key === 'ArrowUp') {
                    if (this.input[index] * 1 < 9) {
                        this.input[index] = (this.input[index] * 1 + 1).toString()
                    }
                } else if (e.key === 'ArrowDown') {
                    if (this.input[index] * 1 > 0) {
                        this.input[index] = (this.input[index] * 1 - 1).toString()
                    }
                }
            },
            keyup(e) {
                var index = e.target.dataset.index * 1;
                var el = e.target;
                // el.value = el.value.replace(/Digit|Numpad/i, '').replace(/1/g, '').slice(0, 1);
                el.value = el.value.replace(/Digit|Numpad/i, '').slice(0, 1);
                if (/Digit|Numpad/i.test(e.code)) {
                    this.input[index] = e.code.replace(/Digit|Numpad/i, '')
                    el.nextElementSibling && el.nextElementSibling.focus();
                    if (index === 5) {
                        if (this.input.join('').length === 6) {
                            document.activeElement.blur();
                            this.$emit('complete', this.input);
                        }
                    }
                } else {
                    if (this.input[index] === '') {
                        this.input[index] = ''
                    }
                }
            },
            mousewheel(e) {
                var index = e.target.dataset.index;
                if (e.wheelDelta > 0) {
                    if (this.input[index] * 1 < 9) {
                        this.input[index] = (this.input[index] * 1 + 1).toString()
                    }
                } else if (e.wheelDelta < 0) {
                    if (this.input[index] * 1 > 0) {
                        this.input[index] = (this.input[index] * 1 - 1).toString()
                    }
                } else if (e.key === 'Enter') {
                    if (this.input.join('').length === 6) {
                        document.activeElement.blur();
                        this.$emit('complete', this.input);
                    }
                }
            },
            paste(e) {
                e.clipboardData.items[0].getAsString(str => {
                    const pr = str.split('').slice(0,6);
                    for (let i = pr.length; i < 6; i++) {
                        // console.log('add at', i)
                        pr.push('')
                    }
                    this.pasteResult = pr;
                    document.activeElement.blur();
                    this.$emit('complete', pr);
                    this.pasteResult = [];
                    // if (str.toString().length === 6) {
                    //     this.pasteResult = str.split('');
                    //     document.activeElement.blur();
                    //     this.$emit('complete', this.input);
                    //     this.pasteResult = [];
                    // } else {
                    //     this.input[0] = ['', '', '', '', '', '']
                    // }
                })
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.$refs.firstinput.focus()
            })
        },
    }
</script>
<style scoped lang="scss">
    .input-box {
        .input-content {width: 229px; height: 37px; display: inline-flex; align-items: center; justify-content: space-between;
            input {color: inherit; font-family: inherit; border: 0; height: 37px; width: 29px; font-size: 18px; font-weight: 500; text-align: center; box-shadow: 0px 4px 4px rgba(208, 208, 208, 0.25); border-radius: 5px}
        }
        input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {appearance: none; margin: 0;}
    }
</style>