import {LoosedNameValidator} from "./loosedNameValidator"
import {EmailValidator} from "./emailValidator"
import {WebsiteValidator} from "./websiteValidator"

class BusinessProfileValidator {
    constructor() {
        this.nameValidator = new LoosedNameValidator();
        this.emailValidator = new EmailValidator();
        this.websiteValidator = new WebsiteValidator();
    }
}

const businessProfileValidator = new BusinessProfileValidator();

export { businessProfileValidator }