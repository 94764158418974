<template>
    <div class="report-card-container">
        <p class="report-card-title">Score:{{ submission.eveluationScore }}</p>
        <div class="report-item-container" v-for="(result, index) in submission.eveluationResult" :Key="index">
            <span class="report-item-name">Case: {{ result['case'] }}:</span>
            <span class="report-item-value">{{
                    getResultString(result)
            }}</span>
        </div>
    </div>
</template>

<script>
import {
} from "../../types/index";

export default {
    name: "SubmissionScoreDetailsViewer",
    props: {
        modelValue: null,
    },

    data() {
        return {
            submission: null,
        };
    },

    watch: {
        modelValue: {
            immediate: true,
            handler: function (val) {
                this.submission = val;
            },
        },
        submission: function (val) {
            this.$emit("update:modelValue", val);
        },
    },
    created() { },
    components: {},
    mounted() {

    },
    updated() {
    },
    methods: {
        getResultString(result) {
            switch (result['result']) {
                case 0: return 'PASSED';
                case 1: return 'FAILED';
                case 2: return 'ERORR';
                default: return 'UNKNOWN';
            }
        }
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.comp-body {
    @extend .lf-board-container;
}

.headline-text {
    @extend .lf-body-item-text-input;
}
</style>
