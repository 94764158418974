<template>
<div class="container-background">
  <div class="container-content">
    <div class="content-header-container">
      <p class="content-header-title">Candidate</p>
    </div>
    <form @submit.prevent="submitForm">
      <!-- <div class="lf-head-container">
        <div class="lf-head-left">
          <h3 class="lf-head-title">Applicant</h3>
        </div>
      </div> -->
      <div class="lf-body-container">
        <div class="lf-body-block-container max716">
          <p class="lf-body-block-container-title">Summary</p>
          <div class="lf-body-block-container-body">
            <div class="lf-body-item-block">
              <label class="lf-body-item-block-label" id="first-name-input" for="firstName">First Name</label>
              <input type="text" class="lf-body-item-block-input" aria-label="firstName" aria-describedby="first-name-input" id="firstName" placeholder="First Name" v-model.trim="applicant.firstname" />
            </div>
            <div class="lf-body-item-block">
              <label class="lf-body-item-block-label" id="last-name-input" for="lastName">Last Name</label>
              <input type="text" class="lf-body-item-block-input" id="lastName" placeholder="Last Name" v-model.trim="applicant.lastname" />
            </div>
          </div>
          <p class="lf-body-block-container-title">Contact</p>
          <div class="lf-body-block-container-body">
            <div class="lf-body-item-block">
              <label class="lf-body-item-block-label" id="email-input">Email</label>
              <input type="text" class="lf-body-item-block-input" id="email" placeholder="Email" v-model.trim="applicant.email" />
            </div>
            <div class="lf-body-item-block">
              <label class="lf-body-item-block-label" id="mobile-input">Mobile</label>
              <input type="text" class="lf-body-item-block-input" id="mobile" placeholder="Mobile" v-model.trim="applicant.mobile" />
            </div>
          </div>
        </div>
      </div>
      <div class="lf-body-container">
        <div class="lf-body-item">
          <h5 class="lf-body-item-label" for="scoring">Details</h5>
          <p class="lf-body-item-label-note">Position</p>
          <select class="lf-body-item-select-input" v-model="applicant.jobPosition">
            <option v-for="option in jobList" :key="getJobId(option)" :value="getJobId(option)">
              {{ option.title }}
            </option>
          </select>
        </div>
      </div>
      <div class="lf-body-container">
        <div class="lf-body-item">
          <h5 class="lf-body-item-label" for="scoring">More</h5>
          <p class="lf-body-item-label-note">Resume:</p>
          <div class="lf-body-item-file">
            <p>{{ resumeFilename }}</p>
            <button class="if-cancel" type="button" aria-label="resume" aria-describedby="resume-file" @click="selectResumeFile">resume</button>
            <input ref="resumeFileInput" type="file" :accept="acceptableResumeFileType" style="display: none" @change="onChange($event)" />
          </div>
          <!-- <select class="lf-body-item-select-input" v-model="applicant.jobPosition">
            <option v-for="option in jobList" :key="getJobId(option)" :value="getJobId(option)">
              {{ option.title }}
            </option>
          </select> -->
        </div>
      </div>

      <!-- <div class="lf-body-block-container">
        <p class="lf-body-block-container-title">Details</p>
        <div class="lf-body-block-container-body">
          <div class="lf-body-item-block">
            <label class="lf-body-item-block-label" id="position-input">Position</label> 
            <select class="lf-body-item-select-input" v-model="applicant.jobPosition">
              <option v-for="option in jobList" :key="getJobId(option)" :value="getJobId(option)">
                {{ option.title }}
              </option>
            </select>
          </div>
        </div>
        <p class="lf-body-block-container-title">More</p>
        <div class="lf-body-block-container-body">
          <div class="lf-body-item-block">
            <label class="lf-body-item-block-label" for="resume">Resume:</label>
            <p class="lf-body-item-block-text-clickable" aria-label="resume" aria-describedby="resume-file" @click="selectResumeFile">
              {{ resumeFilename }}
            </p>
            <input ref="resumeFileInput" type="file" :accept="acceptableResumeFileType" style="display: none" @change="onChange($event)" />
          </div>
        </div>
      </div> -->
      <p v-show="hasError()" class="if-errorInput">{{ errorMessage }}</p>
      <div class="lf-submit-container">
        <button class="if-cancel" type="button" @click="goBack">Cancel</button>
        <button class="if-submit" type="submit" v-bind:disabled="submitting">
          <span v-if="submitting" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span>{{submitting?'&nbsp;&nbsp;&nbsp;&nbsp;Submitting':'Submit'}}</span>
        </button>
      </div>
    </form>
  </div>
</div>
</template>

<script>
import {BusinessUserApi, applicantUtils, applicantValidator, OIDUtils,} from "../../utils/index";
import { applicantStruct, jobStatusEnum } from "../../types/index";

export default {
  name: "BusinessNewApplicant",
  props: {},

  data() {
    return {
      applicant: applicantUtils.fetch() ? applicantUtils.fetch() : applicantStruct,
      errorMessage: null,
      applicantId: applicantUtils.fetchApplicantId() || null,
      acceptableResumeFileType: "application/pdf",
      jobList: null,
      resumeFilename: "Upload resume (Only Accept PDF File)",
      submitting: false
    };
  },

  components: {},

  created() { },
  mounted() {
    this.queryJobs();
    if((this.applicant != null) && (this.applicant.resumeFilename != null)){
      this.resumeFilename = this.applicant.resumeFilename;
    }
  },
  methods: {
    getJobId(option) {
      return OIDUtils.FromJson(option);
    },
    goBack() {
      this.mnx_goBack();
    },
    hasError() {
      return this.errorMessage;
    },

    selectResumeFile() {
      this.$refs.resumeFileInput.click();
    },

    onChange(event) {
      this.errorMessage = null;
      let resumeFile = event.target.files[0];
      let resumeReader = new FileReader();
      resumeReader.addEventListener(
        "load",
        function () {
          this.applicant.resume = resumeReader.result;
          this.applicant.resumeFilename = resumeFile.name;
          this.resumeFilename = resumeFile.name;
        }.bind(this),
        false
      );
      resumeReader.addEventListener(
        "error",
        function (event) {
          this.errorMessage = event.target.error;
        }.bind(this),
        false
      );
      resumeReader.readAsDataURL(resumeFile);
    },

    async submitForm() {
      this.errorMessage = applicantValidator.nameValidator.validate(
        this.applicant.firstname
      );
      if (this.hasError()) return;
      this.errorMessage = applicantValidator.nameValidator.validate(
        this.applicant.lastname
      );
      if (this.hasError()) return;
      this.errorMessage = applicantValidator.emailValidator.validate(
        this.applicant.email
      );
      if (this.hasError()) return;
      // this.errorMessage = applicantValidator.mobileValidator.validate(
      //   this.applicant.mobile
      // );
      // if (this.hasError()) return;

      this.submitting = true;

      if (this.applicantId) {
        BusinessUserApi.UpdateApplicant(this.applicantId, this.applicant)
          .then((response) => {
            response;
            this.mnx_navToBusinessApplicantAdded();
            this.submitting = false;
          })
          .catch((error) => {
            this.submitting = false;
            this.mnx_backendErrorHandler(error);
          });
      } else {
        BusinessUserApi.AddApplicant(this.applicant)
          .then((response) => {
            response;
            this.mnx_navToBusinessApplicantAdded();
            this.submitting = false;
          })
          .catch((error) => {
            this.submitting = false;
            this.mnx_backendErrorHandler(error);
          });
      }
    },
    queryJobs() {
      BusinessUserApi.QueryJobs(jobStatusEnum.OPEN)
        .then((response) => {
          this.jobList = response.data;
        })
        .catch((error) => {
          this.mnx_backendErrorHandler(error);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.container-background {
  @include container-background;

  .container-content {
    @include container-content;
    padding: 45px 0;
  }
}
.max716 {max-width: 716px}
.lf-body-container {
  .lf-body-item {margin-bottom: 30px !important;}
}
::v-deep .if-submit:disabled {
  background-color: #E52D6F;
  border-color: #E52D6F
}
::v-deep .if-submit.disabled {
  background-color: #E52D6F;
  border-color: #E52D6F
}
// .select-position {
//   @extend .form-group-item;
//   @extend .form-select;
// }
</style>
