<template>
  <div class="container-background">
    <div class="container-content">
      <div class="content-header-container">
        <p class="content-header-title">Setup coding interview</p>
      </div>
      <form @submit.prevent="submitForm">
        <!-- <div class="lf-head-container">
          <div class="lf-head-left">
            <h3 class="lf-head-title">Coding Interview</h3>
            <h6 class="lf-head-subtitle"></h6>
          </div>
          <div class="lf-head-right">
            <p class="lf-head-notes">
            </p>

          </div>
        </div> -->
        <div class="lf-body-container">
          <div class="lf-body-content">
            <div class="lf-body-item">
              <h5 class="lf-body-item-label" for="position">Job opportunities:</h5>
              <p class="lf-body-item-label-note">please select the job position</p>
              <CardSelect :cards="jobList" v-model:sel="codingInterview.jobPosition" @add="AddNewJob" />
              <!-- <div class="d-flex justify-content-between">
                <select class="select-position" v-model="codingInterview.jobPosition">
                  <option v-for="option in jobList" :key="getOId(option)" :value="getOId(option)">
                    {{ option.title }}
                  </option>
                </select>
                <button class="btn btn-primary" @click="AddNewJob()">+</button>
              </div> -->
            </div>
            <div class="lf-body-item">
              <h5 class="lf-body-item-label" for="applicant">Candidate:</h5>
              <p class="lf-body-item-label-note">please select the candidate</p>
              <CardSelect :cards="applicantList" v-model:sel="codingInterview.applicant" @add="AddNewApplicant" />
              <!-- <div class="d-flex justify-content-between">
                <select class="select-applicant" v-model="codingInterview.applicant">
                  <option v-for="option in applicantList" :key="getOId(option)" :value="getOId(option)">
                    {{ option.email }}
                  </option>
                </select>
                <button class="btn btn-primary" @click="AddNewApplicant()">+</button>
              </div> -->
            </div>
            <div class="lf-body-item">
              <h5 class="lf-body-item-label" for="noteToCandidate">Note:</h5>
              <p class="lf-body-item-label-note">A short note to the candidat. (16~1024 words)</p>
              <textarea class="lf-body-item-rich-text" id="noteToCandidate" placeholder="A short note to the candidate" v-model.trim="codingInterview.noteToInterviewee" @keyup="textAreaAdjust($event)" />
            </div>
            <div class="lf-body-item">
              <h5 class="lf-body-item-label" for="scheduledToStart">Due time:</h5>
              <!-- <p class="lf-body-item-label-note">The time the interview is scheduled to complete.</p> -->
              <input type="datetime-local" class="lf-body-item-date-input" id="scheduledToStart" v-model.trim="codingInterview.deadline" />
            </div>
            <div class="lf-body-item">
              <h5 class="lf-body-item-label" for="scoring">Difficulty:</h5>
              <!-- <p class="lf-body-item-label-note">The difficulty level of the problem.</p> -->
              <select name="difficulty" class="lf-body-item-select-input" id="difficulty" v-model="codingInterview.difficultyLevel">
                <option value="0">Easy</option>
                <option value="1">Medium</option>
                <option value="2">Hard</option>
                <option value="3">Champion</option>
              </select>
            </div>
            <div class="lf-body-item">
              <h5 class="lf-body-item-label" for="scoring">Programming Language:</h5>
              <!-- <p class="lf-body-item-label-note">The programming language desired to use in the interview</p> -->
              <select name="language" class="lf-body-item-select-input" id="language" v-model="codingInterview.desiredProgramingLanguage">
                <option value="CPP">C++</option>
                <option value="JAVA">Java</option>
                <option value="PY">Python</option>
                <option value="ANY">Any</option>
              </select>
            </div>
          </div>
        </div>
        <p v-show="hasError()" class="if-errorInput">{{ errorMessage }}</p>
        <div class="lf-submit-container">
          <button class="if-cancel" type="button" @click="goBack">Cancel</button>
          <button class="if-submit" type="submit" v-bind:disabled="submitting">
            <span v-if="submitting" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span>{{submitting?'&nbsp;&nbsp;&nbsp;&nbsp;Submitting':'Submit'}}</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {BusinessUserApi, codingInterviewUtils, OIDUtils} from "../../utils/index";
import {codingInterviewStruct, applicantStatusEnum, jobStatusEnum} from "../../types/index";
import CardSelect from '../../components/common/CardSelect.vue'
import moment from 'moment'
export default {
  name: "BusinessEditCodingInterview",
  props: {},
  data() {
    return {
      codingInterview: codingInterviewUtils.fetch() ? codingInterviewUtils.fetch() : codingInterviewStruct,
      errorMessage: null,
      placeholderDescription: "",
      codingInterviewId: codingInterviewUtils.fetchId() ? codingInterviewUtils.fetchId() : null,
      applicantList: [],
      jobList: [],
      submitting: false
    };
  },
  created() { },
  components: {CardSelect},
  mounted() {
    if (this.codingInterview.deadline && this.codingInterview.deadline.$date) {
      this.codingInterview.deadline = moment(this.codingInterview.deadline.$date).utc().format('YYYY-MM-DDTHH:mm')
    } else {
      this.codingInterview.deadline = null
    }
    this.queryApplicants();
    this.queryJobs();
  },
  methods: {
    textAreaAdjust(event) {
      let element = event.target;
      if (element) {
        element.style.height = "1px";
        element.style.height = (25 + element.scrollHeight) + "px";
      }
    },

    getOId(option) {
      return OIDUtils.FromJson(option);
    },
    goBack() {
      this.mnx_goBack();
    },
    hasError() {
      return this.errorMessage;
    },
    queryJobs() {
      BusinessUserApi.QueryJobs(jobStatusEnum.OPEN).then((response) => {
        this.jobList = response.data || [];
        this.jobList = this.jobList.map((job, index) => {
          const id = job._id.$oid;
          if (index == 0 && !this.codingInterview.jobPosition) {
            this.codingInterview.jobPosition = id
          }
          return {
            id,
            title: job.title,
            content: job.description
          }
        })
      }).catch((error) => {
        this.mnx_backendErrorHandler(error);
      });
    },
    AddNewJob() {
      this.mnx_navToBusinessNewJobPosition();
    },
    AddNewApplicant() {
      this.mnx_navToBusinessNewApplicant();
    },
    queryApplicants() {
      BusinessUserApi.QueryApplicants(applicantStatusEnum.OPEN).then((response) => {
        this.applicantList = response.data || [];
        this.applicantList = this.applicantList.map((applicant, index) => {
          const id = applicant._id.$oid;
          if (index == 0 && !this.codingInterview.applicant) {
            this.codingInterview.applicant = id
          }
          return {
            id,
            title: (applicant.firstname || '') + ' ' + (applicant.lastname || ''),
            content: applicant.business
          }
        })
      }).catch((error) => {
        this.mnx_backendErrorHandler(error);
      });
    },

    async submitForm() {
      if (!this.codingInterview.jobPosition) {
        this.errorMessage =
          "Plesse select the job position assocated to this interview";
      }

      if (this.hasError()) {
        return;
      }
      if (!this.codingInterview.applicant)
        this.errorMessage = "Please select the applicant taking this interview";
      if (this.hasError()) {
        return;
      }

      if (!this.codingInterview.deadline) {
        this.errorMessage = "Please specify deadline time";
      }

      if (this.hasError()) {
        return;
      }

      codingInterviewUtils.fill(this.interviewQuestion);

      this.submitting = true

      if (this.codingInterview.due) {
        delete this.codingInterview.due
      }

      if (this.codingInterviewId) {
        BusinessUserApi.UpdateCodingInterview(
          this.codingInterviewId,
          this.codingInterview
        )
          .then((response) => {
            this.codingInterviewId = response.data.interviewId;
            codingInterviewUtils.fillId(this.interviewQuestionId);
            this.mnx_navToBusinessCodingInterviewScheduled();
            this.submitting = false
          })
          .catch((error) => {
            this.submitting = false
            this.mnx_backendErrorHandler(error);
          });
      } else {
        BusinessUserApi.ScheduleCodingInterview(this.codingInterview)
          .then((response) => {
            this.codingInterviewId = response.data.interviewId;
            codingInterviewUtils.fillId(this.interviewQuestionId);
            this.mnx_navToBusinessCodingInterviewScheduled();
            this.submitting = false
          })
          .catch((error) => {
            this.submitting = false
            this.mnx_backendErrorHandler(error);
          });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.container-background {
  @include container-background;

  .container-content {
    @include container-content;
    padding: 45px 0;
  }
}
::v-deep .if-submit:disabled {
  background-color: #E52D6F;
  border-color: #E52D6F
}
::v-deep .if-submit.disabled {
  background-color: #E52D6F;
  border-color: #E52D6F
}
.comp-body {
  @extend .lf-board-container;
}

.headline-text {
  @extend .lf-body-item-text-input;
}

.select-position {
  @extend .form-group-item;
  @extend .form-select;
}

.select-applicant {
  @extend .form-group-item;
  @extend .form-select;
}
</style>
