<template>
  <!-- <div class="story-board">
    <div class="focus-area">
      <p class="callout">Find a trustworthy partner, simplify the work, secure your return.</p>
      <div class="form-group">
        <form @submit.prevent="sendCodeToEmail">
          <div class="input-group">
            <input class="form-control w-70 me-1" type="text" :placeholder="'Email'" v-model="email" />
            <button type="submit" ref="submitButton" class="form-control btn btn-primary ms-1">Start</button>
          </div>
        </form>
        <p class="errorInput" v-if="message != null">{{ message }}</p>
      </div>
    </div>
  </div> -->
  <div class="container-background">
    <div class="container-content">
      <div class="intro-container">
        <p class="intro-title text-bold">Leading platform for <span>arrange</span><br><span>and conduct</span> coding interviews.</p>
        <p class="intro-subtitle">Minimize business costs and maximize the efficiency of developer<br>recruitment across the boundaries of geolocation.</p>
        <button type="button" class="btn btn-primary text-bold">Schedule Interview For Free</button>
      </div>
      <div class="card-container">
        <div class="row row-cols-md-3 row-cols-3 row-cols-sm-1 g-6">
          <div class="col">
            <div class="card-item-container">
              <img class="logo" alt="freeleaps EasyArrangement" src="@/assets/EasyArrangement.png" />
              <p class="card-title text-bold">Easy Arrangement</p>
              <p class="card-subtitle">All you need is a a few clicks.</p>
            </div>
          </div>
          <div class="col">
            <div class="card-item-container">
              <img class="logo" alt="freeleaps EffectiveEvaluation" src="@/assets/EffectiveEvaluation.png" />
              <p class="card-title text-bold">Effective Evaluation</p>
              <p class="card-subtitle">The report is easy to read and reflects well candidate's coding skills.</p>
            </div>
          </div>
          <div class="col">
            <div class="card-item-container">
              <img class="logo" alt="freeleaps SimpleandReliable" src="@/assets/SimpleandReliable.png" />
              <p class="card-title text-bold">Unique Questions</p>
              <p class="card-subtitle">It is a true test of candidate's coding capabilities.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  PersonalUserApi,
  userProfileValidator,
} from "../../utils/index";
export default {
  name: "PersonalFrontDoor",
  props: {},

  data() {
    return {
      email: null,
      message: null,
    };
  },

  components: {},

  created() { },
  mounted() {
    if (this.mnx_IsKeepUserSignedIn() && this.mnx_isUserAuthenticated()) {
      this.mnx_navAfterSignedin();
    }
  },
  methods: {
    sendCodeToEmail() {
      this.message = null;
      if (this.email === null || this.email.length < 1) {
        this.message = "Please type in your email";
        return;
      }

      this.message = userProfileValidator.emailValidator.validate(
        this.email
      );

      if (this.message != null) return;

      this.$refs.submitButton.disabled = true;
      PersonalUserApi.sendCodeToEmail(this.email).then(
        (response) => {
          response;
          this.$refs.submitButton.disabled = false;
          this.mnx_navToPersonalSigninByEmailAndCode(this.email);
        }
      ).catch(
        (error) => {

          this.$refs.submitButton.disabled = false;
          console.log(error);
        }
      )

    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.container-background {
  @include container-background;

  .container-content {
    @include container-content;

    .intro-container {padding: 90px 0; border-bottom: 1px solid rgba(0,0,0,.25);
      .intro-title {font-size: 48px; color: black; line-height: 56px; margin-bottom: 16px;
        span {color: #0032FD;}
      }
      .intro-subtitle {font-size: 20px; line-height: 26px; color: #777; margin-bottom: 40px;}
    }
    .card-container {padding: 58px 0;
      .card-item-container {background-color: #F1F4FE; border-radius: 8px; padding: 54px 66px; text-align: center; min-height: 270px;
        img {width: 48px; margin-bottom: 32px;}
        .card-title {font-size: 20px; color: black; margin-bottom: 8px}
        .card-subtitle {font-size: 16px; color: #777; margin-bottom: 0;}
      }
    }
  }
}
</style>
