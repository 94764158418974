import {NameValidator} from "./nameValidator"
import {EmailValidator} from "./emailValidator"
import {MobileValidator} from "./mobileValidator"

class ApplicantValidator {
    constructor() {
        this.nameValidator = new NameValidator();
        this.emailValidator = new EmailValidator();
        this.mobileValidator = new MobileValidator();
    }
}

const applicantValidator = new ApplicantValidator();

export { applicantValidator }